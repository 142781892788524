const texts = {
  contact_us: "contact_us",
  logout: "logout",
  delete_account: "delete_account",
  pick_an_image: "pick_an_image",
  upload_image: "upload_image",
  newspapers: "newspapers",
  have_questions_or_comments: "have_questions_or_comments",
  settings: "settings",
  fetching_articles: "fetching_articles",
  select_app_language: "select_app_language",
  type_language_here: "type_language_here",
  search: "search",
  free_trial_expired: "free_trial_expired",
  prompt_to_search: "prompt_to_search",
  community_posts: "community_posts",
  image_upload_prompt: "image_upload_prompt",
  camera: "camera",
  gallery: "gallery",
  cancel: "cancel",
  alert: "alert",
  view: "view",
  select_goodnews_preferences: "Select GoodNews Preferences",
  goodnews_preferences_prompt: "goodnews_preferences_prompt",
  welcome_good_news: "welcome_good_news",
  welcome_good_news_msg: "welcome_good_news_msg",
  search_here: "search_here",
  read_and_share_uplifting_news: "Read and share uplifting and economy news",
  email: "Email",
  password: "Password",
  login: "login",
  sign_in_as_guest: "sign_in_as_guest",
  dont_have_an_account: "dont_have_an_account",
  signup: "sign_up",
  by_signing_in: "by_signing_in",
  terms: "terms",
  interested_in_becoming_a_goodnews_reporter:
    "interested_in_becoming_a_goodnews_reporter",
  goodnews_terms_information: "goodnews_terms_information",
  username: "username",
  confirm_password: "confirm_password",
  register: "register",
  big_wins: "big_wins",
  all_news_articles: "all_news_articles",
  block: "block",
  report: "report",
  share: "share",
  comment: "comment",
  submit: "submit",
  like: "like",
  likes: "likes",
  translate: "translate",
  title: "Title",
  content: "Content",
  url: "URL",
  reporter: "Reporter",
  source: "Source",
  enter_a_valid_value: "enter_a_valid_value",
  create_an_account_to_create_post: "create_an_account_to_create_post",
  post_added_successfully: "post_added_successfully",
  there_was_an_error: "there_was_an_error",
  failed_to_translate: "failed_to_translate",
  show_more: "show_more",
  show_less: "show_less",
  successful: "successful",
  unlike: "unlike",
  no_goodnews_available: "no_goodnews_available",
  feedback: "feedback",
  latest_news: "lastest_news",
};

export default texts;
