import Sentiment from "sentiment";
import { negativeWords } from "utils/negativeWords";

const { AppContext } = require("contexts/AppProvider");
const { useContext } = require("react");

const useSentiment = () => {
  const appContext = useContext(AppContext);

  function containsBadWords(content) {
    // Remove special characters and split the content into words
    const words = content
      .replace(/[^\w\s]/g, " ")
      .toLowerCase()
      .split(/\s+/);
    // Filter the words to count negative ones
    const negativeWordCount = words.filter((word) =>
      negativeWords.includes(word)
    ).length;
    // Return true if there are any negative words, false otherwise
    return !!negativeWordCount;
  }

  const isBadNews = (item) => {
    const isBad =
      containsBadWords(item.title) ||
      containsBadWords(item.description ?? item.content) ||
      appContext.getSentiment(item.title) < 0.1 ||
      appContext.getSentiment(item.description ?? item.content) < 0.1 ||
      new Sentiment().analyze(item.title).score < 0.5 ||
      new Sentiment().analyze(item.description) < 0.5;
    return isBad;
  };

  const filterOutBadNews = (news) => {
    const goodNews = (news || []).filter((item) => !isBadNews(item));
    return goodNews;
  };

  return {
    filterOutBadNews,
  };
};

export default useSentiment;
