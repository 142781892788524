import { useTranslation } from "hooks";
import React from "react";
import texts from "utils/languages/texts";
import isEmail from "validator/lib/isEmail";

export const inputErrorCls = "!border-red";

export const isNonEmptyString = (string) => {
    if (typeof string !== "string") {
        return false
    }
    return string.trim().length > 0
}
const urlRegex = /^(https?:\/\/)([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/


export const validators = {
    isEmail: { validate: (v) => isEmail(v) },
    isRequiredEmail: { validate: (v) => isEmail(v), required: true },
    isStringWithSpacesAllowed: { validate: v => v?.length > 0 },
    isRequiredString: { validate: (v) => isNonEmptyString(v) },
    isRequiredNonZeroNumber: { validate: (v) => v > 0, required: true },
    isURL: { validate: (v) => urlRegex.test(v) },
    isRequiredFile: {
        validate: (v) => {
            if (!v) {
                return false
            }
            if (v instanceof File) {
                return true
            }
            return false
        }
    },
    isValidOption: { validate: (v) => !!v?.value },
    isValidMultipleOption: { validate: (v) => !!v?.length },
}

const FormInputError = ({ error, errorText }) => {
    const { renderText: _ } = useTranslation()
    if (error) {
        return <span className="text-sm text-[red]">{errorText || _(texts.enter_a_valid_value)}</span>;
    }
};

export default FormInputError;
