export const storageKeys = {
  PREFERRED_LANGUAGE: "PREFERRED_LANGUAGE",
  IS_DEFAULT_LANGUAGE: "IS_DEFAULT_LANGUAGE",
  ARTICLE_GPT_DATA: "GPT_DATA",
  GOODNEWS_PREFERENCES: "GOODNEWS_PREFERENCES",
  ECONOMY_PREFERENCES: "ECONOMY_PREFERENCES",
  IS_WELCOMED: "IS_WELCOMED",
  IS_FEEDBACK_STORED: "IS_FEEDBACK_STORED",
};

export const languages = {
  en: { code: "en", name: "English" },
  fr: { code: "fr", name: "French" },
  es: { code: "es", name: "Spanish" },
  ar: { code: "ar", name: "Arabic" },
  de: { code: "de", name: "German" },
  zh: { code: "zh", name: "Chinese" },
  pt: { code: "pt", name: "Portuguese" },
  it: { code: "it", name: "Italian" },
  sv: { code: "sv", name: "Swedish" },
  ru: { code: "ru", name: "Russian" },
  hi: { code: "hi", name: "Hindi" },
  ja: { code: "ja", name: "Japanese" },
  ko: { code: "ko", name: "Korean" },
  nl: { code: "nl", name: "Dutch" },
  tr: { code: "tr", name: "Turkish" },
  fi: { code: "fi", name: "Finnish" },
  po: { code: "po", name: "Polish" },
  da: { code: "da", name: "Dannish" },
};

export const categories = [
  { name: "General", category_type: "general", use_sentiment: true },
  { name: "World", category_type: "world", use_sentiment: true },
  { name: "Nation", category_type: "nation", use_sentiment: true },
  { name: "Business", category_type: "business", use_sentiment: true },
  { name: "Technology", category_type: "technology", use_sentiment: true },
  {
    name: "Entertainment",
    category_type: "entertainment",
    use_sentiment: true,
  },
  { name: "Sports", category_type: "sports", use_sentiment: true },
  { name: "Health", category_type: "health", use_sentiment: true },
  { name: "Science", category_type: "science", use_sentiment: true },
];

export const topics = [
  {
    name: "General",
    category_type: "general",
    key: "general",
    use_sentiment: true,
  },
  {
    name: "Real Estate",
    category_type: "real estate",
    key: "real_estate",
    use_sentiment: true,
  },
  {
    name: "Energy",
    category_type: "energy",
    key: "energy",
    use_sentiment: true,
  },
  {
    name: "Climate",
    category_type: "climate",
    key: "climate",
    use_sentiment: true,
  },
  {
    name: "Retail",
    category_type: "retail",
    key: "retail",
    use_sentiment: true,
  },
  {
    name: "Small Business",
    category_type: "small business",
    key: "small_business",
    use_sentiment: true,
  },
  {
    name: "Healthcare",
    category_type: "healthcare",
    key: "healthcare",
    use_sentiment: true,
  },
  {
    name: "Aerospace",
    category_type: "aerospace",
    key: "aerospace",
    use_sentiment: true,
  },
  {
    name: "Defense",
    category_type: "defense",
    key: "defense",
    use_sentiment: true,
  },
  {
    name: "Crypto",
    category_type: "crypto",
    key: "crypto",
    use_sentiment: true,
  },
  {
    name: "Startups",
    category_type: "startups",
    key: "startups",
    use_sentiment: true,
  },
];
