import clsx from "clsx";
import { useTranslation } from "hooks";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import texts from "utils/languages/texts";
import appRoutes from "utils/routing";

const GoodNewsSelect = ({ className }) => {
  const location = useLocation();
  const { renderText: _ } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    { label: _(texts.community_posts), id: texts.community_posts },
    { label: _(texts.newspapers), id: texts.newspapers },
  ];

  const activeTab =
    location.pathname === appRoutes.newspapers ? tabs[1].id : tabs[0].id;

  const onTabChange = (tabId) => {
    if (tabId === texts.community_posts) {
      navigate(appRoutes.communityposts);
      return;
    }
    navigate(appRoutes.newspapers);
  };

  return (
    <div className={clsx(className, "relative")}>
      <div className="bg-white py-2 px-4 border border-primary flex space-x-3 items-center rounded-lg text-primary">
        <span>{tabs.find((tab) => tab.id === activeTab)?.label}</span>
        <svg
          width="11"
          height="7"
          viewBox="0 0 11 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75 1.5L5.75 5.5L9.75 1.5"
            stroke="#64748B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <select
        className="opacity-0 absolute top-0 left-0 h-full w-full"
        value={activeTab}
        onChange={(e) => onTabChange(e.target.value)}
      >
        {tabs.map((tab, index) => (
          <option key={index} value={tab.id}>
            {tab.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GoodNewsSelect;
