import { Modal, Button } from "flowbite-react";
import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const generateLabels = (array) => {
  const labels = [];
  for (var i = 0; i < (array || []).length; i++) {
    labels.push(i);
  }
  return labels;
};

const defaultChartData = {
  labels: generateLabels([98, 50, 63, 40, 2]),
  datasets: [
    {
      label: "Demand",
      data: [98, 50, 63, 40, 2],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      fill: "start",
    },
    {
      label: "Supply",
      data: [3, 5, 9, 12, 40],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
      fill: "start",
    },
  ],
};

const DemandAndSupplyModal = ({ visible, onClose, content }) => {
  const parsedData = useMemo(() => {
    if (!content?.title) {
      return {
        chartData: defaultChartData,
      };
    }
    try {
      const parsedInfo = JSON.parse(content?.gptData);
      const chartData = {
        labels: generateLabels(parsedInfo.demand),
        datasets: [
          {
            label: "Demand",
            data: parsedInfo.demand,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            fill: "start",
          },
          {
            label: "Supply",
            data: parsedInfo.supply,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            fill: "start",
          },
        ],
      };
      const insight = parsedInfo.result?.split("\n");
      return {
        insight,
        chartData,
      };
    } catch (e) {
      console.log(e);
      return {
        chartData: defaultChartData,
      };
    }
  }, [content]);

  const options = {
    scales: {
      x: { title: { text: "Time", display: true } },
      y: { title: { text: "Quantity", display: true } },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Insights",
      },
      filler: {
        propagate: false,
      },
    },
  };

  return (
    <Modal show={visible} onClose={onClose}>
      <Modal.Header>Insights</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <h2 className="my-2 text-black text-[20px]">{content?.title}</h2>
          <Line options={options} data={{ ...parsedData.chartData }} />
          {(parsedData.insight || []).map((item, index) => (
            <p
              key={index}
              className="text-base leading-relaxed text-gray-500 dark:text-gray-400"
            >
              {item}
            </p>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DemandAndSupplyModal;
