import { useTranslation } from "hooks"
import texts from "utils/languages/texts"

const terms = ["term1", "term2", "term3", "term4", "term5", "term6", "term7", "term8", "term9", "term10"]

const Terms = () => {
    const { renderText } = useTranslation()
    return (
        <div>
            <main className="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
                <div className="flex justify-between px-4 mx-auto max-w-screen-xl ">
                    <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                        <header className="mb-4 lg:mb-6 not-format">
                            <h1 className="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
                                {renderText(texts.goodnews_terms_information)}
                            </h1>
                        </header>
                        <div className="flex flex-col space-y-3">
                            {terms.map((term, index) => (
                                <p className="lead text-base leading-8" key={index}>
                                    {index + 1}. {renderText(term)}
                                </p>
                            ))}
                        </div>
                    </article>
                </div>
            </main >
        </div >
    )
}

export default Terms

