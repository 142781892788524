import axios from "axios";
import { collection, getDocs, getFirestore } from "firebase/firestore";

export const fetchPosts = async () => {
  const db = getFirestore();
  const postsCollection = collection(db, "posts");
  const postDocs = await getDocs(postsCollection);
  return postDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
// api.js

export const apikey = process.env.REACT_APP_NEWS_API_KEY;

const baseUrl = "https://gnews.io/api/v4/top-headlines";
const searchUrl = "https://gnews.io/api/v4/search";

const API_KEY = process.env.REACT_APP_NEWS_API_KEY;
const BASE_URL = "https://gnews.io/api/v4/top-headlines";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const GOOGLE_BASE_URL =
  "https://translation.googleapis.com/language/translate/v2";

export const getNewsArticles = async (category) => {
  try {
    const response = await axios.get(`${BASE_URL}`, {
      params: {
        token: API_KEY,
        lang: "en",
        max: 10,
        topic: category,
      },
    });

    return response.data.articles;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // If it's a 403 error, throw a custom error for the calling function to handle
      throw new Error("API rate limit exceeded");
    } else {
      // If it's not a 403 error, re-throw the original error
      throw error;
    }
  }
};

export async function getSearchedArticles(q) {
  try {
    const response = await fetch(
      `${searchUrl}?q=${q}&lang=en&country=us&max=5&apikey=${apikey}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch searched articles");
    }

    const data = await response.json();
    return data.articles;
  } catch (error) {
    throw error;
  }
}

// Add functions to fetch articles based on different categories
export async function getBusinessArticles() {
  try {
    const response = await fetch(
      `${baseUrl}?token=${apikey}&lang=en&max=10&topic=business`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Business articles");
    }

    const data = await response.json();
    return data.articles;
  } catch (error) {
    throw error;
  }
}

export async function getTechnologyArticles() {
  try {
    const response = await fetch(
      `${baseUrl}?token=${apikey}&lang=en&max=10&topic=technology`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Technology articles");
    }

    const data = await response.json();
    return data.articles;
  } catch (error) {
    throw error;
  }
}

export async function getHealthArticles() {
  try {
    const response = await fetch(
      `${baseUrl}?token=${apikey}&lang=en&max=10&topic=health`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Health articles");
    }

    const data = await response.json();
    return data.articles;
  } catch (error) {
    throw error;
  }
}

export async function getScienceArticles() {
  try {
    const response = await fetch(
      `${baseUrl}?token=${apikey}&lang=en&max=10&topic=science`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch Science articles");
    }

    const data = await response.json();
    return data.articles;
  } catch (error) {
    throw error;
  }
}
// Modify getEconomyArticles to throw the custom error for a 403 status code
export const getEconomyArticles = async (lang = "en") => {
  try {
    const response = await axios.get(
      `${baseUrl}?token=${API_KEY}&lang=${lang}&max=10&topic=economy`
    );

    return response.data.articles;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // If it's a 403 error, throw a custom error for the calling function to handle
      throw new Error("API rate limit exceeded");
    } else {
      // If it's not a 403 error, re-throw the original error
      throw error;
    }
  }
};

export const googleTranslate = async (text, targetLanguage) => {
  const response = await axios.post(
    `${GOOGLE_BASE_URL}?key=${GOOGLE_API_KEY}`,
    {
      q: text,
      target: targetLanguage,
    }
  );
  return response.data.data;
};
