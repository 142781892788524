import "./App.css";

import { Navigate, Route, Routes } from "react-router-dom";
import appRoutes from "./utils/routing";
import { Login, Register, Terms } from "./modules/auth";
import { Toaster } from "react-hot-toast";
import AuthProvider from "contexts/AuthProvider";
import Header from "basicomponents/header";
import Footer from "basicomponents/footer";
import { ScrollToTop } from "modules/shared/components";
import UnPro from "modules/shared/components/UnProtectedOnlyPage";
import {
	GoodNewsPreferenceModal,
	GoodNewsOutlet,
	CommunityPosts,
	NewsPapers,
} from "modules/news/components";
import { Economy, Search, AddPost } from "modules/news/pages";
import { Settings, Privacy } from "modules/account/pages";
import AppProvider from "contexts/AppProvider";
import WelcomeModal from "basicomponents/WelcomeModal";
import FeedbackModal from "basicomponents/FeedbackModal";

function App() {
	return (
		<div>
			<Toaster />
			<AuthProvider>
				<AppProvider>
					<div className="min-h-screen flex flex-col">
						<Header />
						<div className="pt-[80px]">
							<WelcomeModal />
							<GoodNewsPreferenceModal />
							<FeedbackModal />
							<ScrollToTop />
							<Routes>
								<Route
									path={appRoutes.login}
									element={
										<UnPro>
											<Login />
										</UnPro>
									}
								/>
								<Route
									path={appRoutes.register}
									element={
										<UnPro>
											<Register />
										</UnPro>
									}
								/>
								<Route
									path={appRoutes.economy}
									element={
										<UnPro>
											<Economy />
										</UnPro>
									}
								/>
								<Route
									path={appRoutes.search}
									element={
										<UnPro>
											<Search />
										</UnPro>
									}
								/>
								<Route
									path={appRoutes.goodnews}
									element={
										<UnPro>
											<GoodNewsOutlet />
										</UnPro>
									}
								>
									<Route path="newspapers" element={<NewsPapers />} />
									<Route path="community-posts" element={<CommunityPosts />} />
								</Route>
								<Route
									path={appRoutes.addpost}
									element={
										<UnPro>
											<AddPost />
										</UnPro>
									}
								/>
								<Route
									path={appRoutes.setting}
									element={
										<UnPro>
											<Settings />
										</UnPro>
									}
								/>
								<Route path={appRoutes.terms} element={<Terms />} />
								<Route path={appRoutes.privacy} element={<Privacy />} />
								<Route
									path="*"
									element={<Navigate to={appRoutes?.communityposts} replace />}
								/>
							</Routes>
						</div>
						<div className="mt-auto">
							<Footer />
						</div>
					</div>
				</AppProvider>
			</AuthProvider>
		</div>
	);
}

export default App;
