import moment from "moment";
import React, { useMemo } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as Clock } from "../images/clock.svg";
import microphone from "../images/microphone.png";
import NewsImage from "./NewsImage";
import CommunityPost from "modules/news/components/CommunityPost";
import { useTranslation } from "hooks";

const GoodNewsCategoryDisplay = ({
  categoryName,
  newsItems,
  setPosts,
  activeTab,
  onShare,
}) => {
  const { renderText } = useTranslation();
  const spotlightedNewsItem = useMemo(() => {
    if (newsItems.length) {
      return newsItems[0];
    }
  }, [newsItems]);

  const orderedNews = useMemo(() => {
    const sortedNews = [...newsItems].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    return sortedNews;
  }, [newsItems]);

  return (
    <div className="flex flex-col space-y-10">
      <div className="flex flex-col space-y-4">
        <h3 className="text-[23px] md:text-[40px] text-black capitalize font-semibold">
          {categoryName}
        </h3>
        {spotlightedNewsItem && (
          <NewsImage
            imageUrl={spotlightedNewsItem.image}
            alt={spotlightedNewsItem.title}
            containerClassName="h-[400px] flex flex-col relative overflow-hidden rounded-xl"
          >
            <div className="absolute bg-black/[.4] top-0 left-0 h-full w-full" />
            <div className="mt-auto pb-8 pl-8 flex flex-col space-y-3 relative">
              <a
                href={spotlightedNewsItem.url}
                target="_blank"
                rel="noreferrer"
                className="max-w-[80%] md:max-w-[60%]"
              >
                <h1 className="text-white text-[20px] md:text-[28px]">
                  {spotlightedNewsItem.title}
                </h1>
              </a>
              <div className="flex space-x-[50px]">
                <div className="flex items-center space-x-2">
                  <Clock className="h-5 w-5 fill-white" />
                  <span className="text-[12px] text-white">
                    {moment
                      ?.unix(spotlightedNewsItem?.createdAt?.seconds)
                      .format("DD MMM YYYY h:mm A")}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <img src={microphone} alt="Microphone" className="h-5 w-5" />
                  <span className="text-[12px] text-white">
                    {spotlightedNewsItem.newsSource}
                  </span>
                </div>
              </div>
            </div>
          </NewsImage>
        )}
      </div>
      <div className="flex flex-col space-y-3">
        {orderedNews.length > 0 && (
          <h3 className="text-[20px] md:text-[30px] text-black capitalize font-semibold">
            {renderText("latest_news")}
          </h3>
        )}
        <div className="grid grid-cols-1 900px:grid-cols-2 1157px:grid-cols-3 gap-x-6 gap-y-6">
          {orderedNews.map((item, index) => (
            <CommunityPost
              article={item}
              key={`${item.title}_${index}`}
              category={categoryName}
              setPosts={setPosts}
              activeTab={activeTab}
              onShare={() => onShare(item)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoodNewsCategoryDisplay;
