import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Fix the import statement
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "goodnews-app-llc.firebaseapp.com",
  projectId: "goodnews-app-llc",
  storageBucket: "goodnews-app-llc.appspot.com",
  messagingSenderId: "1044758622215",
  appId: "1:1044758622215:web:be72de59ddb987dbd4baa2",
  measurementId: "G-N7PVD2WZYJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const Firestore = getFirestore(app);
export const auth = getAuth(app)
export const storage = getStorage(app)
export default app
