import axios from "axios";
import { languages } from "utils/constants";
import { getData, storeData } from "utils/functions";

class OpenAIService {
  isAValidEconomicArticle = async (article, locale) => {
    let articleGptDataKey;
    if (article.url) {
      articleGptDataKey = `${article.url}`;
      const savedResponse = await getData(articleGptDataKey);
      if (savedResponse) {
        return savedResponse;
      }
    }
    // let prompt = `“state in step by step list the shifts on the supply and demand of goods and services of a news article with this title: ${article.title}, and with this description: ${article.description}. Keep your answer to just one line”`;
    let prompt = `state in step by step list the shifts on the supply and demand of goods and services of a news article with this title: '${article.title}'. Return answer in json with keys ''result", "demand", "supply". Let demand and supply be arrays of random numbers that can be used to draw an area chart. The arrays should be of the length 10. Make sure there are numbers in it. Result should be text. Just return json. Do not explain. Do not make the json a variable`;
    if (locale && locale !== languages.en.code) {
      const preferredLanguage = languages?.[locale]?.name;
      if (preferredLanguage) {
        prompt += `. Reply in ${preferredLanguage}`;
      }
    }
    try {
      const data = {
        model: "gpt-3.5-turbo",

        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
      };

      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer  ${process.env.REACT_APP_OPENAI_KEY}`,
          },
        }
      );
      if (articleGptDataKey) {
        await storeData(
          articleGptDataKey,
          response.data.choices[0].message.content
        );
      }
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error(error);
      return "";
    }
  };
}

const openAiService = new OpenAIService();

export default openAiService;
