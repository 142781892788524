import React from "react";
import logo from "images/logo.jpeg"
import { Link } from "react-router-dom";
import appRoutes from "utils/routing";
import { Button } from "modules/shared/components";
import { useAuth, useTranslation } from "hooks";
import texts from "utils/languages/texts";
import { useForm } from "react-hook-form";
import FormInputError, { validators } from "modules/shared/components/FormInputError";

export default function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const { renderText } = useTranslation()
    const { login, loggingIn, signInAnon } = useAuth()

    const onSubmit = data => {
        login(data.email, data.password)
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900 min-h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Link to={"/"} className="flex items-center mb-6 text-2xl font-semibold text-gray-900 space-x-2 dark:text-white">
                    <img src={logo} className="h-[50px] w-[50px]" alt="GoodNews Logo" />
                    <span>GoodNews</span>
                </Link>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            {renderText(texts.read_and_share_uplifting_news)}
                        </h1>
                        <div className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{renderText(texts.email)}</label>
                                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""
                                    {...register("email", validators.isEmail)}
                                />
                                <FormInputError
                                    error={errors.email}
                                    errorText={"Please input a valid email"}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{renderText(texts.password)}</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                    {...register("password", validators.isStringWithSpacesAllowed)}
                                />
                                <FormInputError
                                    error={errors.password}
                                    errorText={"Please input your password"}
                                />
                            </div>
                            {/* <div className="flex items-center justify-between">
                                <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                            </div> */}
                            <Button
                                text={renderText(texts.login)}
                                type="submit"
                                loading={loggingIn}
                            />

                            <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
                                {renderText(texts.dont_have_an_account)} <Link to={appRoutes.register} className="font-medium text-primary-600 hover:underline dark:text-primary-500">{renderText(texts.signup)}</Link>
                            </p>
                            <div className="my-4 flex flex-col space-y-3">
                                <span className="text-gray-500 font-medium text-sm text-center">OR</span>
                                <Button
                                    text={renderText(texts.sign_in_as_guest)}
                                    type="button"
                                    onClick={signInAnon}
                                    loading={loggingIn}
                                />
                            </div>
                            <div className="mt-5 flex items-center justify-between">
                                <p className="text-[12px] text-gray-500">
                                    {renderText(texts.by_signing_in)}
                                </p>
                                <Link className="underline text-[12px] text-gray-500" to={appRoutes.terms}>Terms</Link>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="flex flex-col space-y-4 my-4">
                    <a href="mailto:dasgoodnews@gmail.com" target="_blank" rel="noreferrer" className="underline text-gray-500 text-sm text-center">{renderText(texts.have_questions_or_comments)}</a>
                    <a href="https://forms.gle/qVH8unG34qbyW8f47" target="_blank" rel="noreferrer" className="underline text-gray-500 text-sm text-center">{renderText(texts.interested_in_becoming_a_goodnews_reporter)}</a>
                </div>
            </div>
        </section>
    );
}