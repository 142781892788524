export const negativeWords = [
  "abysmal",
  "adverse",
  "alarming",
  "appalling",
  "atrocious",
  "awful",
  "bad",
  "baneful",
  "boring",
  "cataclysmic",
  "catastrophic",
  "crummy",
  "damaging",
  "deleterious",
  "deplorable",
  "destructive",
  "dire",
  "disastrous",
  "dreadful",
  "execrable",
  "fatal",
  "forbidding",
  "ghastly",
  "grim",
  "grisly",
  "harsh",
  "horrific",
  "horrible",
  "hostile",
  "hurtful",
  "hateful",
  "ill",
  "injurious",
  "lousy",
  "malevolent",
  "malignant",
  "nasty",
  "negative",
  "noxious",
  "offensive",
  "painful",
  "poor",
  "repugnant",
  "repulsive",
  "revolting",
  "ruinous",
  "sad",
  "savage",
  "severe",
  "shocking",
  "sinister",
  "spiteful",
  "terrible",
  "toxic",
  "tragic",
  "unpleasant",
  "upsetting",
  "vile",
  "villainous",
  "wicked",
  "woeful",
  "worrying",
  "worthless",
  "abominable",
  "acrimonious",
  "agonizing",
  "appalling",
  "atrocious",
  "awful",
  "baleful",
  "belligerent",
  "bitter",
  "cruel",
  "cutthroat",
  "damaging",
  "deadly",
  "demonic",
  "depressing",
  "detestable",
  "disagreeable",
  "disastrous",
  "dreadful",
  "evil",
  "foul",
  "ghastly",
  "gruesome",
  "harmful",
  "heartbreaking",
  "heartrending",
  "horrifying",
  "hostile",
  "hurtful",
  "inclement",
  "injurious",
  "insufferable",
  "intolerable",
  "lethal",
  "loathsome",
  "malignant",
  "monstrous",
  "murderous",
  "noxious",
  "obnoxious",
  "odious",
  "painful",
  "piteous",
  "pungent",
  "rancid",
  "repellent",
  "reprehensible",
  "revolting",
  "sickening",
  "sinister",
  "sordid",
  "sorrowful",
  "tasteless",
  "tormenting",
  "toxic",
  "tragic",
  "unbearable",
  "unpleasant",
  "vexatious",
  "vicious",
  "vile",
  "villainous",
  "vindictive",
  "wicked",
  "woeful",
  "wrathful",
  "rape", // Added "rape"
  "addiction", // Added "addiction"
  "corruption", // Added "corruption"
  "violence", // Added "violence"
  "deceit", // Added "deceit"
  "fraud", // Added "fraud"
  "exploitation", // Added "exploitation"
  "terrorism", // Added "terrorism"
  "abuse", // Added "abuse"
  "betrayal", // Added "betrayal"
  "bullying", // Added "bullying"
  "coercion", // Added "coercion"
  "crime", // Added "crime"
  "deception", // Added "deception"
  "dishonesty", // Added "dishonesty"
  "hatred", // Added "hatred"
  "injustice", // Added "injustice"
  "oppression", // Added "oppression"
  "prejudice", // Added "prejudice"
  "slavery", // Added "slavery"
  "suffering", // Added "suffering"
  "tyranny", // Added "tyranny"
  "war", // Added "war"
  "attack",
  "bomb",
  "explosion",
  "violence",
  "war",
  "conflict",
  "terrorism",
  "killed",
  "injured",
  "casualties",
  "destruction",
  "damage",
  "harm",
  "disaster",
  "tragedy",
  "catastrophe",
  "devastation",
  "pain",
  "suffering",
  "victim",
  "assault",
  "hostility",
  "aggression",
  "massacre",
  "slaughter",
  "atrocity",
  "genocide",
  "brutality",
  "horror",
  "bloodshed",
  "ruin",
  "ruination",
  "annihilation",
  "degradation",
  "death",
  "murder",
  "killing",
  "kill",
  "assassination",
  "execution",
  "torture",
  "beating",
  "combat",
  "battle",
  "fight",
  "struggle",
  "warfare",
  "siege",
  "ambush",
  "raid",
  "invasion",
  "occupation",
  "oppression",
  "repression",
  "displacement",
  "refugee",
  "crisis",
  "emergency",
  "panic",
  "fear",
  "dread",
  "chaos",
  "anarchy",
  "violated",
  "disrupted",
  "wounded",
  "carnage",
  "mayhem",
  "cataclysm",
  "collateral damage",
  "detonation",
  "explosive",
  "firefight",
  "conflagration",
  "grenade",
  "missile",
  "munition",
  "shell",
  "shrapnel",
  "napalm",
  "chemical weapon",
  "biological weapon",
  "radiation",
  "nuclear",
  "toxic",
  "poison",
  "contamination",
  "pollution",
  "russia",
  "feud",
  "arrest",
  "arrested",
  "blew up",
  "blow up",
  "addicted",
  "addiction",
  "furious",
  "shoot",
  "shoot down",
  "ukraine",
  "palestine",
  "isreal",
  "shooter",
  "disease",
  "sued",
  "blast",
  "gun",
  "gun violence",
  "stab",
  "stabbing",
  "injury",
  "injuries",
  "duel",
  "dueling",
  "trafficking",
  "cocaine",
  "marijuana",
  "theft",
  "robbery",
  "violence",
  "terrifying",
  "trauma",
  "stabbing",
  "sex",
  "cancer",
  "diseases",
  "disease",
  "controversial",
  "biased",
  "controversy",
  "court",
  "election",
  "elections",
  "trump",
  "biden",
  "diagnosed",
  "Disease",
  "Illness",
  "Injury",
  "Pain",
  "Sickness",
  "Disability",
  "Disorder",
  "Cancer",
  "Virus",
  "Infection",
  "Therapy",
  "Treatment",
  "Prescription",
  "Medicine",
  "Corruption",
  "Scandal",
  "Partisan",
  "Controversy",
  "Bribery",
  "Cronyism",
  "Nepotism",
  "Graft",
  "Conflict",
  "Incompetence",
  "Manipulation",
  "Propaganda",
  "Divisive",
  "Polarization",
  "Oppression",
  "Disease",
  "Illness",
  "Injury",
  "Pain",
  "Sickness",
  "Disability",
  "Disorder",
  "Cancer",
  "Virus",
  "Infection",
  "Therapy",
  "Treatment",
  "Prescription",
  "Medicine",
  "Healthcare",
  "Terminal",
  "Chronic",
  "Trauma",
  "Fatality",
  "Obesity",
  "Addiction",
  "Epidemic",
  "Pandemic",
  "Outbreak",
  "Quarantine",
  "Hospitalization",
  "Emergency",
  "Ailment",
  "Symptom",
  "Side effects",
  "Fatal",
  "Mortality",
  "Preventable",
  "Terminal",
  "Overdose",
  "Euthanasia",
  "Abuse",
  "Malpractice",
  "Suicide",
  "Corruption",
  "Scandal",
  "Partisan",
  "Controversy",
  "Bribery",
  "Cronyism",
  "Nepotism",
  "Graft",
  "Conflict",
  "Incompetence",
  "Manipulation",
  "Propaganda",
  "Divisive",
  "Polarization",
  "Oppression",
  "Dictatorship",
  "Autocracy",
  "Totalitarianism",
  "Oligarchy",
  "Authoritarianism",
  "Suppression",
  "Tyranny",
  "Anarchy",
  "Repression",
  "Unrest",
  "Revolt",
  "Protest",
  "Riot",
  "Demonstration",
  "Injustice",
  "Discrimination",
  "Racism",
  "Sexism",
  "Homophobia",
  "Xenophobia",
  "Bigotry",
  "Fascism",
  "Extremism",
  "Radicalization",
  "Hypocrisy",
  "Scam",
  "Fraud",
  "Embezzlement",
  "Forgery",
  "Perjury",
  "épouvantable",
  "négatif",
  "alarmant",
  "épouvantable",
  "atroce",
  "affreux",
  "mauvais",
  "funeste",
  "ennuyeux",
  "cataclysmique",
  "catastrophique",
  "minable",
  "préjudiciable",
  "délétère",
  "déplorable",
  "destructeur",
  "terrible",
  "catastrophique",
  "terrible",
  "exécrable",
  "fatal",
  "interdiction",
  "horrible",
  "sombre",
  "macabre",
  "rude",
  "horrible",
  "horrible",
  "hostile",
  "nuisible",
  "odieux",
  "je vais",
  "préjudiciable",
  "pouilleux",
  "malveillant",
  "malin",
  "méchant",
  "négatif",
  "nuisible",
  "offensant",
  "douloureux",
  "pauvre",
  "répugnant",
  "repoussant",
  "révoltant",
  "ruineux",
  "triste",
  "sauvage",
  "grave",
  "choquant",
  "sinistre",
  "malveillant",
  "terrible",
  "toxique",
  "tragique",
  "désagréable",
  "bouleversant",
  "vil",
  "infâme",
  "méchant",
  "triste",
  "inquiétant",
  "sans valeur",
  "abominable",
  "acrimonieux",
  "angoissant",
  "épouvantable",
  "atroce",
  "affreux",
  "funeste",
  "belligérant",
  "amer",
  "cruel",
  "coupe-gorge",
  "préjudiciable",
  "mortel",
  "démoniaque",
  "déprimant",
  "détestable",
  "désagréable",
  "catastrophique",
  "terrible",
  "mal",
  "faute",
  "horrible",
  "horrible",
  "nocif",
  "déchirant",
  "déchirant",
  "effroyable",
  "hostile",
  "nuisible",
  "inclément",
  "préjudiciable",
  "insupportable",
  "intolérable",
  "mortel",
  "détestable",
  "malin",
  "monstrueux",
  "meurtrier",
  "nuisible",
  "odieux",
  "odieux",
  "douloureux",
  "pitoyable",
  "âcre",
  "rance",
  "repoussant",
  "répréhensible",
  "révoltant",
  "écœurant",
  "sinistre",
  "sordide",
  "douloureux",
  "insipide",
  "torturant",
  "toxique",
  "tragique",
  "insupportable",
  "désagréable",
  "vexatoire",
  "vicieux",
  "vil",
  "infâme",
  "vindicatif",
  "méchant",
  "triste",
  "courroucé",
  "râpé",
  "dépendance",
  "la corruption",
  "violence",
  "tromperie",
  "fraude",
  "exploitation",
  "terrorisme",
  "abus",
  "trahison",
  "intimidation",
  "coercition",
  "crime",
  "tromperie",
  "malhonnêteté",
  "haine",
  "injustice",
  "oppression",
  "préjugé",
  "esclavage",
  "souffrance",
  "tyrannie",
  "guerre",
  "attaque",
  "bombe",
  "explosion",
  "violence",
  "guerre",
  "conflit",
  "terrorisme",
  "tué",
  "blessé",
  "victimes",
  "destruction",
  "dommage",
  "nuire",
  "catastrophe",
  "la tragédie",
  "catastrophe",
  "dévastation",
  "douleur",
  "souffrance",
  "victime",
  "agression",
  "hostilité",
  "agression",
  "massacre",
  "abattage",
  "atrocité",
  "génocide",
  "brutalité",
  "horreur",
  "effusion de sang",
  "ruine",
  "ruine",
  "annihilation",
  "dégradation",
  "la mort",
  "meurtre",
  "meurtre",
  "tuer",
  "assassinat",
  "exécution",
  "torture",
  "battement",
  "combat",
  "bataille",
  "lutte",
  "lutte",
  "guerre",
  "siège",
  "embuscade",
  "raid",
  "invasion",
  "profession",
  "oppression",
  "répression",
  "déplacement",
  "réfugié",
  "crise",
  "urgence",
  "panique",
  "peur",
  "redouter",
  "chaos",
  "anarchie",
  "violé",
  "perturbé",
  "blessés",
  "carnage",
  "grabuge",
  "cataclysme",
  "dommage collatéral",
  "détonation",
  "explosif",
  "échange de tirs",
  "incendie",
  "grenade",
  "missile",
  "munition",
  "coquille",
  "shrapnel",
  "napalm",
  "arme chimique",
  "arme biologique",
  "radiation",
  "nucléaire",
  "toxique",
  "poison",
  "contamination",
  "pollution",
  "Russie",
  "querelle",
  "arrêter",
  "arrêté",
  "explosé",
  "exploser",
  "accro",
  "dépendance",
  "furieux",
  "tirer",
  "abattre",
  "Ukraine",
  "Palestine",
  "est vrai",
  "tireur",
  "maladie",
  "poursuivi",
  "explosion",
  "pistolet",
  "Violence par armes à feu",
  "poignarder",
  "poignarder",
  "blessure",
  "blessures",
  "duel",
  "duel",
  "trafic",
  "cocaïne",
  "marijuana",
  "vol",
  "vol",
  "violence",
  "terrifiant",
  "traumatisme",
  "poignarder",
  "sexe",
  "cancer",
  "maladies",
  "maladie",
  "controversé",
  "biaisé",
  "controverse",
  "tribunal",
  "élection",
  "élections",
  "atout",
  "biden",
  "diagnostiqué",
  "Maladie",
  "Maladie",
  "Blessure",
  "Douleur",
  "Maladie",
  "Invalidité",
  "Désordre",
  "Cancer",
  "Virus",
  "Infection",
  "Thérapie",
  "Traitement",
  "Ordonnance",
  "Médecine",
  "Soins de santé",
  "La corruption",
  "Scandale",
  "Partisan",
  "Controverse",
  "Corruption",
  "Copinage",
  "Népotisme",
  "Greffer",
  "Conflit",
  "Incompétence",
  "Manipulation",
  "La propagande",
  "Diviseur",
  "Polarisation",
  "Oppression",
  "Maladie",
  "Maladie",
  "Blessure",
  "Douleur",
  "Maladie",
  "Invalidité",
  "Désordre",
  "Cancer",
  "Virus",
  "Infection",
  "Thérapie",
  "Traitement",
  "Ordonnance",
  "Médecine",
  "Soins de santé",
  "Terminal",
  "Chronique",
  "Traumatisme",
  "Fatalité",
  "Obésité",
  "Dépendance",
  "Épidémie",
  "Pandémie",
  "Épidémie",
  "Quarantaine",
  "Hospitalisation",
  "Urgence",
  "Maladie",
  "Symptôme",
  "Effets secondaires",
  "Fatal",
  "Mortalité",
  "Evitable",
  "Terminal",
  "Surdose",
  "Euthanasie",
  "Abus",
  "Faute professionnelle",
  "Suicide",
  "La corruption",
  "Scandale",
  "Partisan",
  "Controverse",
  "Corruption",
  "Copinage",
  "Népotisme",
  "Greffer",
  "Conflit",
  "Incompétence",
  "Manipulation",
  "La propagande",
  "Diviseur",
  "Polarisation",
  "Oppression",
  "Dictature",
  "Autocratie",
  "Totalitarisme",
  "Oligarchie",
  "Autoritarisme",
  "Suppression",
  "Tyrannie",
  "Anarchie",
  "Répression",
  "Troubles",
  "Révolte",
  "Manifestation",
  "Émeute",
  "Manifestation",
  "Injustice",
  "Discrimination",
  "Racisme",
  "Sexisme",
  "Homophobie",
  "Xénophobie",
  "Bigoterie",
  "Fascisme",
  "Extrémisme",
  "Radicalisation",
  "Hypocrisie",
  "Arnaque",
  "Fraude",
  "Détournement de fonds",
  "Falsification",
  "Parjure",
  "abismal",
  "adverso",
  "alarmante",
  "pésimo",
  "atroz",
  "horrible",
  "malo",
  "funesto",
  "aburrido",
  "cataclísmico",
  "catastrófico",
  "sucio",
  "perjudicial",
  "perjudicial",
  "deplorable",
  "destructivo",
  "terrible",
  "desastroso",
  "terrible",
  "execrable",
  "fatal",
  "amenazante",
  "horrible",
  "severo",
  "espeluznante",
  "duro",
  "horrendo",
  "horrible",
  "hostil",
  "hiriente",
  "odioso",
  "enfermo",
  "perjudicial",
  "malísimo",
  "malévolo",
  "maligno",
  "asqueroso",
  "negativo",
  "nocivo",
  "ofensivo",
  "doloroso",
  "pobre",
  "repugnante",
  "repulsivo",
  "repugnante",
  "ruinoso",
  "triste",
  "salvaje",
  "severo",
  "impactante",
  "siniestro",
  "malévolo",
  "horrible",
  "tóxico",
  "trágico",
  "desagradable",
  "molesto",
  "vil",
  "villano",
  "malvado",
  "lamentable",
  "preocupante",
  "sin valor",
  "abominable",
  "mordaz",
  "agonizante",
  "pésimo",
  "atroz",
  "horrible",
  "funesto",
  "beligerante",
  "amargo",
  "cruel",
  "asesino",
  "perjudicial",
  "mortal",
  "demoníaco",
  "deprimente",
  "detestable",
  "desagradable",
  "desastroso",
  "terrible",
  "demonio",
  "falta",
  "horrible",
  "horrible",
  "dañino",
  "angustioso",
  "desgarrador",
  "horripilante",
  "hostil",
  "hiriente",
  "inclemente",
  "perjudicial",
  "insufrible",
  "intolerable",
  "letal",
  "repugnante",
  "maligno",
  "monstruoso",
  "asesino",
  "nocivo",
  "desagradable",
  "odioso",
  "doloroso",
  "lastimoso",
  "acre",
  "rancio",
  "repelente",
  "reprensible",
  "repugnante",
  "nauseabundo",
  "siniestro",
  "sórdido",
  "triste",
  "insípido",
  "atormentando",
  "tóxico",
  "trágico",
  "inaguantable",
  "desagradable",
  "vejatorio",
  "vicioso",
  "vil",
  "villano",
  "vengativo",
  "malvado",
  "lamentable",
  "colérico",
  "violación",
  "adiccion",
  "corrupción",
  "violencia",
  "engaño",
  "fraude",
  "explotación",
  "terrorismo",
  "abuso",
  "traición",
  "acoso",
  "coerción",
  "delito",
  "engaño",
  "deshonestidad",
  "odio",
  "injusticia",
  "opresión",
  "prejuicio",
  "esclavitud",
  "sufrimiento",
  "tiranía",
  "guerra",
  "ataque",
  "bomba",
  "explosión",
  "violencia",
  "guerra",
  "conflicto",
  "terrorismo",
  "delicado",
  "herido",
  "damnificados",
  "destrucción",
  "daño",
  "dañar",
  "desastre",
  "tragedia",
  "catástrofe",
  "devastación",
  "dolor",
  "sufrimiento",
  "víctima",
  "agresión",
  "hostilidad",
  "agresión",
  "masacre",
  "sacrificio",
  "atrocidad",
  "genocidio",
  "brutalidad",
  "horror",
  "matanza",
  "ruina",
  "ruina",
  "aniquilación",
  "degradación",
  "muerte",
  "asesinato",
  "asesinato",
  "matar",
  "asesinato",
  "ejecución",
  "tortura",
  "golpeando",
  "combate",
  "batalla",
  "luchar",
  "lucha",
  "guerra",
  "cerco",
  "emboscada",
  "Redada",
  "invasión",
  "ocupación",
  "opresión",
  "represión",
  "desplazamiento",
  "refugiado",
  "crisis",
  "emergencia",
  "pánico",
  "miedo",
  "miedo",
  "caos",
  "anarquía",
  "violado",
  "interrumpido",
  "herido",
  "carnicería",
  "violencia",
  "cataclismo",
  "daños colaterales",
  "detonación",
  "explosivo",
  "tiroteo",
  "conflagración",
  "granada",
  "misil",
  "munición",
  "caparazón",
  "metralla",
  "napalm",
  "arma química",
  "arma biológica",
  "radiación",
  "nuclear",
  "tóxico",
  "veneno",
  "contaminación",
  "contaminación",
  "Rusia",
  "feudo",
  "arrestar",
  "detenido",
  "explotó",
  "explotar",
  "adicto",
  "adiccion",
  "furioso",
  "disparar",
  "derribar",
  "Ucrania",
  "Palestina",
  "es real",
  "tirador",
  "enfermedad",
  "demandado",
  "explosión",
  "pistola",
  "la violencia armada",
  "puñalada",
  "puñalada",
  "lesión",
  "lesiones",
  "duelo",
  "duelo",
  "tráfico",
  "cocaína",
  "marijuana",
  "robo",
  "robo",
  "violencia",
  "espantoso",
  "trauma",
  "puñalada",
  "sexo",
  "cáncer",
  "enfermedades",
  "enfermedad",
  "controversial",
  "sesgado",
  "controversia",
  "corte",
  "elección",
  "elecciones",
  "triunfo",
  "biden",
  "diagnosticado",
  "Enfermedad",
  "Enfermedad",
  "Lesión",
  "Dolor",
  "Enfermedad",
  "Discapacidad",
  "Trastorno",
  "Cáncer",
  "Virus",
  "Infección",
  "Terapia",
  "Tratamiento",
  "Prescripción",
  "Medicamento",
  "Cuidado de la salud",
  "Corrupción",
  "Escándalo",
  "Partidista",
  "Controversia",
  "Soborno",
  "Amiguismo",
  "Nepotismo",
  "Injerto",
  "Conflicto",
  "Incompetencia",
  "Manipulación",
  "Propaganda",
  "Divisivo",
  "Polarización",
  "Opresión",
  "Enfermedad",
  "Enfermedad",
  "Lesión",
  "Dolor",
  "Enfermedad",
  "Discapacidad",
  "Trastorno",
  "Cáncer",
  "Virus",
  "Infección",
  "Terapia",
  "Tratamiento",
  "Prescripción",
  "Medicamento",
  "Cuidado de la salud",
  "Terminal",
  "Crónico",
  "Trauma",
  "Fatalidad",
  "Obesidad",
  "Adiccion",
  "Epidemia",
  "Pandemia",
  "Brote",
  "Cuarentena",
  "Hospitalización",
  "Emergencia",
  "Enfermedad",
  "Síntoma",
  "Efectos secundarios",
  "Fatal",
  "Mortalidad",
  "Evitable",
  "Terminal",
  "Sobredosis",
  "Eutanasia",
  "Abuso",
  "Negligencia profesional",
  "Suicidio",
  "Corrupción",
  "Escándalo",
  "Partidista",
  "Controversia",
  "Soborno",
  "Amiguismo",
  "Nepotismo",
  "Injerto",
  "Conflicto",
  "Incompetencia",
  "Manipulación",
  "Propaganda",
  "Divisivo",
  "Polarización",
  "Opresión",
  "Dictadura",
  "Autocracia",
  "Totalitarismo",
  "Oligarquía",
  "Autoritarismo",
  "Supresión",
  "Tiranía",
  "Anarquía",
  "Represión",
  "Agitación",
  "Revuelta",
  "Protesta",
  "Disturbio",
  "Demostración",
  "Injusticia",
  "Discriminación",
  "Racismo",
  "sexismo",
  "Homofobia",
  "Xenofobia",
  "Fanatismo",
  "Fascismo",
  "Extremismo",
  "Radicalización",
  "Hipocresía",
  "Estafa",
  "Fraude",
  "Malversación",
  "Falsificación",
  "Perjurio",
  "السحيقة",
  "سلبية",
  "مثير للقلق",
  "مروعة",
  "فظيعة",
  "مريع",
  "سيء",
  "مهلك",
  "ممل",
  "كارثية",
  "كارثي",
  "تافه",
  "مضرة",
  "ضارة",
  "مؤسف",
  "مدمرة",
  "خطير",
  "كارثي",
  "المروعة",
  "مثير للاشمئزاز",
  "مميت",
  "ممنوع",
  "مروع",
  "متجهم",
  "مروع",
  "صارِم",
  "مروع",
  "فظيع",
  "عدائي",
  "مؤذية",
  "الكراهية",
  "سوف",
  "الضارة",
  "رديء",
  "حاقد",
  "خبيثة",
  "مقرف",
  "سلبي",
  "ضارة",
  "جارح",
  "مؤلم",
  "فقير",
  "بغيضة",
  "تنافرى",
  "اشمئزاز",
  "مدمرة",
  "حزين",
  "متوحش",
  "شديد",
  "مروع",
  "شرير",
  "حاقد",
  "رهيب",
  "سامة",
  "مأساوي",
  "غير سارة",
  "مزعج",
  "حقير",
  "خسيس",
  "شرير",
  "محزن",
  "مقلق",
  "عديم القيمة",
  "البغيض",
  "لاذعة",
  "مبرح",
  "مروعة",
  "فظيعة",
  "مريع",
  "مؤذ",
  "محاربة",
  "مر",
  "فظ",
  "سفاح",
  "مضرة",
  "مميت",
  "شيطاني",
  "محزن",
  "مقيت",
  "غير مقبول",
  "كارثي",
  "المروعة",
  "شر",
  "خطأ",
  "مروع",
  "شنيعة",
  "ضار",
  "مفجع",
  "مفجع القلب",
  "مرعبة",
  "عدائي",
  "مؤذية",
  "القاسية",
  "الضارة",
  "لا يطاق",
  "لا يطاق",
  "قاتلة",
  "كريهة",
  "خبيثة",
  "وحشية",
  "القاتلة",
  "ضارة",
  "بغيض",
  "البغيض",
  "مؤلم",
  "بائس",
  "لاذع",
  "زنخ",
  "طارد",
  "مستهجن",
  "اشمئزاز",
  "مقزز",
  "شرير",
  "الدنيئة",
  "محزن",
  "لا طعم له",
  "عذاب",
  "سامة",
  "مأساوي",
  "لا يطاق",
  "غير سارة",
  "مزعج",
  "وحشي",
  "حقير",
  "خسيس",
  "انتقامية",
  "شرير",
  "محزن",
  "غاضب",
  "اغتصاب",
  "مدمن",
  "فساد",
  "عنف",
  "الخداع",
  "احتيال",
  "استغلال",
  "الإرهاب",
  "إساءة",
  "خيانة",
  "تنمر",
  "الإكراه",
  "جريمة",
  "الخداع",
  "عدم الأمانة",
  "كراهية",
  "ظلم",
  "القمع",
  "إجحاف",
  "عبودية",
  "معاناة",
  "استبداد",
  "حرب",
  "هجوم",
  "قنبلة",
  "انفجار",
  "عنف",
  "حرب",
  "صراع",
  "الإرهاب",
  "قتل",
  "مصاب",
  "اصابات",
  "دمار",
  "ضرر",
  "ضرر",
  "كارثة",
  "مأساة",
  "نكبة",
  "الدمار",
  "ألم",
  "معاناة",
  "ضحية",
  "يتعدى",
  "العداء",
  "عدوان",
  "مذبحة",
  "ذبح",
  "الفظائع",
  "الإبادة الجماعية",
  "الوحشية",
  "رعب",
  "إراقة الدماء",
  "يخرب",
  "الخراب",
  "الإبادة",
  "انحلال",
  "موت",
  "قتل",
  "قتل",
  "قتل",
  "اغتيال",
  "تنفيذ",
  "يعذب",
  "الضرب",
  "قتال",
  "معركة",
  "يعارك",
  "كفاح",
  "الحرب",
  "حصار",
  "كمين",
  "غارة",
  "غزو",
  "إشغال",
  "القمع",
  "قمع",
  "الإزاحة",
  "لاجئ",
  "مصيبة",
  "طارئ",
  "ذعر",
  "يخاف",
  "الرهبة",
  "فوضى",
  "فوضى سياسية",
  "انتهاك",
  "معطل",
  "الجرحى",
  "مذبحة",
  "الفوضى",
  "كارثة",
  "اضرار جانبية",
  "تفجير",
  "مادة متفجرة",
  "إطلاق النار",
  "الحريق",
  "قنبلة يدوية",
  "صاروخ",
  "الذخيرة",
  "صدَفَة",
  "شظايا",
  "النابالم",
  "سلاح كيميائي",
  "سلاح بيولوجي",
  "إشعاع",
  "النووية",
  "سامة",
  "سم",
  "تلوث اشعاعى",
  "تلوث",
  "روسيا",
  "عداء",
  "يقبض على",
  "القى القبض",
  "انفجر",
  "ينفجر",
  "مدمن",
  "مدمن",
  "حانِق",
  "أطلق النار",
  "اسقاط",
  "أوكرانيا",
  "فلسطين",
  "انه حقيقي",
  "الرامي",
  "مرض",
  "رفع دعوى قضائية",
  "انفجار",
  "بندقية",
  "العنف المسلح",
  "طعنة",
  "طعن",
  "إصابة",
  "الإصابات",
  "مبارزة",
  "المبارزة",
  "الاتجار",
  "الكوكايين",
  "قنب هندي",
  "سرقة",
  "سرقة",
  "عنف",
  "مرعب",
  "صدمة",
  "طعن",
  "الجنس",
  "سرطان",
  "الأمراض",
  "مرض",
  "جدلي",
  "متحيزة",
  "الجدل",
  "محكمة",
  "انتخاب",
  "انتخابات",
  "ورقة رابحة",
  "بايدن",
  "تم تشخيصه",
  "مرض",
  "مرض",
  "إصابة",
  "ألم",
  "المرض",
  "عجز",
  "اضطراب",
  "سرطان",
  "فايروس",
  "عدوى",
  "مُعَالَجَة",
  "علاج",
  "روشتة",
  "الدواء",
  "الرعاىة الصحية",
  "فساد",
  "فضيحة",
  "الحزبية",
  "الجدل",
  "الرشوة",
  "المحسوبية",
  "المحسوبيات",
  "الكسب غير المشروع",
  "صراع",
  "عدم الكفاءة",
  "تلاعب",
  "الدعاية",
  "خلافي",
  "الاستقطاب",
  "القمع",
  "مرض",
  "مرض",
  "إصابة",
  "ألم",
  "المرض",
  "عجز",
  "اضطراب",
  "سرطان",
  "فايروس",
  "عدوى",
  "مُعَالَجَة",
  "علاج",
  "روشتة",
  "الدواء",
  "الرعاىة الصحية",
  "صالة",
  "مزمن",
  "صدمة",
  "الوفاة",
  "بدانة",
  "مدمن",
  "وباء",
  "جائحة",
  "التفشي",
  "الحجر الصحي",
  "العلاج في المستشفيات",
  "طارئ",
  "وعكة",
  "علامة مرض",
  "آثار جانبية",
  "مميت",
  "معدل الوفيات",
  "يمكن الوقاية منه",
  "صالة",
  "جرعة مفرطة",
  "القتل الرحيم",
  "إساءة",
  "سوء التصرف",
  "الانتحار",
  "فساد",
  "فضيحة",
  "الحزبية",
  "الجدل",
  "الرشوة",
  "المحسوبية",
  "المحسوبيات",
  "الكسب غير المشروع",
  "صراع",
  "عدم الكفاءة",
  "تلاعب",
  "الدعاية",
  "خلافي",
  "الاستقطاب",
  "القمع",
  "الدكتاتورية",
  "حكم الفرد المطلق",
  "الشمولية",
  "حكم الاقلية",
  "الاستبداد",
  "إخماد",
  "استبداد",
  "فوضى سياسية",
  "قمع",
  "الاضطرابات",
  "تمرد",
  "يعترض",
  "الشغب",
  "توضيح",
  "ظلم",
  "تمييز",
  "عنصرية",
  "التحيز الجنسي",
  "رهاب المثلية",
  "كراهية الأجانب",
  "التعصب",
  "الفاشية",
  "التطرف",
  "التطرف",
  "النفاق",
  "غش",
  "احتيال",
  "الاختلاس",
  "تزوير",
  "شهادة زور",
  "miserabel",
  "nachteilig",
  "alarmierend",
  "entsetzlich",
  "scheußlich",
  "schrecklich",
  "schlecht",
  "unheilvoll",
  "langweilig",
  "katastrophal",
  "katastrophal",
  "mies",
  "schädigend",
  "schädlich",
  "bedauerlich",
  "destruktiv",
  "schrecklich",
  "katastrophal",
  "schrecklich",
  "abscheulich",
  "tödlich",
  "verbieten",
  "schrecklich",
  "grimmig",
  "grausig",
  "harsch",
  "schrecklich",
  "schrecklich",
  "feindselig",
  "verletzend",
  "hasserfüllt",
  "krank",
  "schädlich",
  "lausig",
  "böswillig",
  "maligne",
  "böse",
  "Negativ",
  "schädlich",
  "beleidigend",
  "schmerzlich",
  "arm",
  "abstoßend",
  "abstoßend",
  "ekelhaft",
  "ruinös",
  "traurig",
  "brutal",
  "schwer",
  "schockierend",
  "unheimlich",
  "boshaft",
  "schrecklich",
  "giftig",
  "tragisch",
  "unangenehm",
  "aufregend",
  "abscheulich",
  "schurkisch",
  "böse",
  "bedauerlich",
  "besorgniserregend",
  "wertlos",
  "abscheulich",
  "erbittert",
  "quälend",
  "entsetzlich",
  "scheußlich",
  "schrecklich",
  "unheilvoll",
  "kriegerisch",
  "bitter",
  "grausam",
  "halsabschneiderisch",
  "schädigend",
  "tötlich",
  "dämonisch",
  "deprimierend",
  "abscheulich",
  "unangenehm",
  "katastrophal",
  "schrecklich",
  "teuflisch",
  "Foul",
  "schrecklich",
  "grausam",
  "schädlich",
  "herzzerreißend",
  "herzzerreißend",
  "erschreckend",
  "feindselig",
  "verletzend",
  "unbarmherzig",
  "schädlich",
  "unerträglich",
  "unerträglich",
  "tödlich",
  "abscheulich",
  "maligne",
  "monströs",
  "mörderisch",
  "schädlich",
  "unausstehlich",
  "abscheulich",
  "schmerzlich",
  "erbärmlich",
  "scharf",
  "ranzig",
  "abweisend",
  "verwerflich",
  "ekelhaft",
  "widerlich",
  "unheimlich",
  "schmutzig",
  "traurig",
  "geschmacklos",
  "quälend",
  "giftig",
  "tragisch",
  "unerträglich",
  "unangenehm",
  "ärgerlich",
  "bösartig",
  "abscheulich",
  "schurkisch",
  "rachsüchtig",
  "böse",
  "bedauerlich",
  "zornvoll",
  "vergewaltigen",
  "Sucht",
  "Korruption",
  "Gewalt",
  "Betrug",
  "Betrug",
  "Ausbeutung",
  "Terrorismus",
  "Missbrauch",
  "Verrat",
  "Mobbing",
  "Zwang",
  "Verbrechen",
  "Täuschung",
  "Unehrlichkeit",
  "Hass",
  "Ungerechtigkeit",
  "Unterdrückung",
  "Vorurteil",
  "Sklaverei",
  "leiden",
  "Tyrannei",
  "Krieg",
  "Attacke",
  "Bombe",
  "Explosion",
  "Gewalt",
  "Krieg",
  "Konflikt",
  "Terrorismus",
  "getötet",
  "verletzt",
  "Verluste",
  "Zerstörung",
  "Schaden",
  "Schaden",
  "Katastrophe",
  "Tragödie",
  "Katastrophe",
  "Verwüstung",
  "Schmerz",
  "leiden",
  "Opfer",
  "Angriff",
  "Feindseligkeit",
  "Aggression",
  "Massaker",
  "schlachten",
  "Grausamkeit",
  "Völkermord",
  "Brutalität",
  "Grusel",
  "Blutvergießen",
  "Ruine",
  "Zerstörung",
  "Vernichtung",
  "Degradierung",
  "Tod",
  "Mord",
  "Tötung",
  "töten",
  "Ermordung",
  "Ausführung",
  "Folter",
  "Prügel",
  "Kampf",
  "Schlacht",
  "kämpfen",
  "Kampf",
  "Krieg",
  "Belagerung",
  "Hinterhalt",
  "Überfall",
  "Invasion",
  "Beruf",
  "Unterdrückung",
  "Repression",
  "Verschiebung",
  "Flüchtling",
  "Krise",
  "Notfall",
  "Panik",
  "Furcht",
  "Furcht",
  "Chaos",
  "Anarchie",
  "verletzt",
  "gestört",
  "verwundet",
  "Blutbad",
  "Chaos",
  "Katastrophe",
  "Kollateralschaden",
  "Detonation",
  "explosiv",
  "Feuergefecht",
  "Feuersbrunst",
  "Granate",
  "Rakete",
  "Munition",
  "Hülse",
  "Schrapnell",
  "Napalm",
  "chemische Waffe",
  "biologische Waffe",
  "Strahlung",
  "atomar",
  "giftig",
  "Gift",
  "Kontamination",
  "Verschmutzung",
  "Russland",
  "Fehde",
  "Festnahme",
  "verhaftet",
  "explodiert",
  "sprengen",
  "süchtig",
  "Sucht",
  "wütend",
  "schießen",
  "abschießen",
  "Ukraine",
  "Palästina",
  "ist echt",
  "Schütze",
  "Krankheit",
  "verklagt",
  "Explosion",
  "Pistole",
  "Waffengewalt",
  "stechen",
  "stechend",
  "Verletzung",
  "Verletzungen",
  "Duell",
  "duellieren",
  "Handel",
  "Kokain",
  "Marihuana",
  "Diebstahl",
  "Raub",
  "Gewalt",
  "schrecklich",
  "Trauma",
  "stechend",
  "Sex",
  "Krebs",
  "Krankheiten",
  "Krankheit",
  "umstritten",
  "voreingenommen",
  "Kontroverse",
  "Gericht",
  "Wahl",
  "Wahlen",
  "Trumpf",
  "biden",
  "diagnostiziert",
  "Krankheit",
  "Erkrankung",
  "Verletzung",
  "Schmerz",
  "Krankheit",
  "Behinderung",
  "Störung",
  "Krebs",
  "Virus",
  "Infektion",
  "Therapie",
  "Behandlung",
  "Rezept",
  "Medizin",
  "Gesundheitspflege",
  "Korruption",
  "Skandal",
  "Partisan",
  "Kontroverse",
  "Bestechung",
  "Vetternwirtschaft",
  "Vetternwirtschaft",
  "Transplantat",
  "Konflikt",
  "Inkompetenz",
  "Manipulation",
  "Propaganda",
  "Spaltend",
  "Polarisation",
  "Unterdrückung",
  "Krankheit",
  "Erkrankung",
  "Verletzung",
  "Schmerz",
  "Krankheit",
  "Behinderung",
  "Störung",
  "Krebs",
  "Virus",
  "Infektion",
  "Therapie",
  "Behandlung",
  "Rezept",
  "Medizin",
  "Gesundheitspflege",
  "Terminal",
  "Chronisch",
  "Trauma",
  "Todesfall",
  "Fettleibigkeit",
  "Sucht",
  "Epidemie",
  "Pandemie",
  "Ausbruch",
  "Quarantäne",
  "Krankenhausaufenthalt",
  "Notfall",
  "Leiden",
  "Symptom",
  "Nebenwirkungen",
  "Tödlich",
  "Mortalität",
  "Vermeidbar",
  "Terminal",
  "Überdosis",
  "Euthanasie",
  "Missbrauch",
  "Fehlverhalten",
  "Selbstmord",
  "Korruption",
  "Skandal",
  "Partisan",
  "Kontroverse",
  "Bestechung",
  "Vetternwirtschaft",
  "Vetternwirtschaft",
  "Transplantat",
  "Konflikt",
  "Inkompetenz",
  "Manipulation",
  "Propaganda",
  "Spaltend",
  "Polarisation",
  "Unterdrückung",
  "Diktatur",
  "Autokratie",
  "Totalitarismus",
  "Oligarchie",
  "Autoritarismus",
  "Unterdrückung",
  "Tyrannei",
  "Anarchie",
  "Repression",
  "Unruhe",
  "Revolte",
  "Protest",
  "Aufstand",
  "Demonstration",
  "Ungerechtigkeit",
  "Diskriminierung",
  "Rassismus",
  "Sexismus",
  "Homophobie",
  "Fremdenfeindlichkeit",
  "Fanatismus",
  "Faschismus",
  "Extremismus",
  "Radikalisierung",
  "Heuchelei",
  "Betrug",
  "Betrug",
  "Unterschlagung",
  "Fälschung",
  "Meineid",
  "惨不忍睹",
  "不利",
  "令人震惊",
  "令人震惊",
  "残暴",
  "可怕",
  "坏的",
  "有害",
  "无聊的",
  "灾难性的",
  "灾难性的",
  "糟糕",
  "损害",
  "有害",
  "可悲",
  "破坏性",
  "可怕",
  "惨重",
  "可怕",
  "可恶的",
  "致命的",
  "禁止",
  "阴森",
  "严峻",
  "可怕",
  "残酷的",
  "可怕",
  "可怕",
  "敌对",
  "伤人",
  "可恶",
  "患病的",
  "有害",
  "糟糕",
  "恶意",
  "恶性",
  "可恶的",
  "消极的",
  "有毒",
  "进攻",
  "痛苦",
  "贫穷的",
  "令人反感",
  "丑恶",
  "反叛",
  "毁灭性的",
  "伤心",
  "野蛮的",
  "严重",
  "令人震惊",
  "险恶",
  "恶意",
  "糟糕的",
  "有毒的",
  "悲惨",
  "不愉快",
  "镦",
  "卑鄙",
  "恶人",
  "邪恶",
  "可悲",
  "令人担忧",
  "一文不值",
  "可恶",
  "尖刻",
  "痛苦",
  "令人震惊",
  "残暴",
  "可怕",
  "恶意",
  "好战",
  "苦的",
  "残忍的",
  "割喉",
  "损害",
  "致命",
  "恶魔",
  "压抑",
  "可恨",
  "不同意",
  "惨重",
  "可怕",
  "邪恶的",
  "犯规",
  "阴森",
  "可怕",
  "有害",
  "心碎",
  "心痛",
  "可怕",
  "敌对",
  "伤人",
  "恶劣",
  "有害",
  "难以忍受",
  "难以忍受",
  "致命",
  "可恶",
  "恶性",
  "滔天",
  "杀人",
  "有毒",
  "令人讨厌",
  "可恶",
  "痛苦",
  "可怜",
  "泼辣",
  "馊",
  "驱避",
  "应受谴责",
  "反叛",
  "令人作呕",
  "险恶",
  "肮脏",
  "悲伤",
  "无味",
  "折磨",
  "有毒的",
  "悲惨",
  "难以忍受",
  "不愉快",
  "无理取闹",
  "恶毒",
  "卑鄙",
  "恶人",
  "有仇必报",
  "邪恶",
  "可悲",
  "愤怒",
  "强奸",
  "瘾",
  "腐败",
  "暴力",
  "欺骗",
  "欺诈罪",
  "开发",
  "恐怖主义",
  "虐待",
  "背叛",
  "欺凌",
  "强迫",
  "犯罪",
  "欺骗",
  "不诚实",
  "仇恨",
  "不公正",
  "压迫",
  "偏见",
  "奴隶制",
  "痛苦",
  "暴政",
  "战争",
  "攻击",
  "炸弹",
  "爆炸",
  "暴力",
  "战争",
  "冲突",
  "恐怖主义",
  "被杀",
  "受伤",
  "伤亡",
  "破坏",
  "损害",
  "伤害",
  "灾难",
  "悲剧",
  "灾难",
  "破坏",
  "疼痛",
  "痛苦",
  "受害者",
  "突击",
  "敌意",
  "侵略",
  "屠杀",
  "屠宰",
  "暴行",
  "种族灭绝",
  "残酷",
  "恐怖",
  "流血",
  "废墟",
  "毁灭",
  "歼灭",
  "降解",
  "死亡",
  "谋杀",
  "杀戮",
  "杀",
  "暗杀",
  "执行",
  "酷刑",
  "殴打",
  "战斗",
  "战斗",
  "斗争",
  "斗争",
  "战争",
  "围城",
  "伏击",
  "袭击",
  "入侵",
  "职业",
  "压迫",
  "抑制",
  "移位",
  "难民",
  "危机",
  "紧急情况",
  "恐慌",
  "害怕",
  "恐惧",
  "混乱",
  "无政府状态",
  "违反",
  "扰乱",
  "受伤",
  "大屠杀",
  "混乱",
  "灾难",
  "附带损害",
  "爆炸",
  "爆炸",
  "交火",
  "大火",
  "手榴弹",
  "导弹",
  "弹药",
  "壳",
  "弹片",
  "凝固汽油弹",
  "化学武器",
  "生物武器",
  "辐射",
  "核",
  "有毒的",
  "毒",
  "污染",
  "污染",
  "俄罗斯",
  "世仇",
  "逮捕",
  "被捕",
  "炸了",
  "爆炸",
  "上瘾",
  "瘾",
  "狂怒",
  "射击",
  "击落",
  "乌克兰",
  "巴勒斯坦",
  "是真实的",
  "射手",
  "疾病",
  "起诉",
  "爆破",
  "枪",
  "枪支暴力",
  "刺",
  "刺",
  "受伤",
  "伤害",
  "决斗",
  "决斗",
  "贩卖",
  "可卡因",
  "大麻",
  "盗窃",
  "抢劫",
  "暴力",
  "可怕",
  "创伤",
  "刺",
  "性别",
  "癌症",
  "疾病",
  "疾病",
  "有争议的",
  "有偏见",
  "争议",
  "法庭",
  "选举",
  "选举",
  "王牌",
  "拜登",
  "确诊",
  "疾病",
  "疾病",
  "受伤",
  "疼痛",
  "疾病",
  "失能",
  "紊乱",
  "癌症",
  "病毒",
  "感染",
  "治疗",
  "治疗",
  "处方",
  "药品",
  "卫生保健",
  "腐败",
  "丑闻",
  "党派",
  "争议",
  "受贿",
  "任人唯亲",
  "裙带关系",
  "接枝",
  "冲突",
  "无能",
  "操纵",
  "宣传",
  "分裂",
  "极化",
  "压迫",
  "疾病",
  "疾病",
  "受伤",
  "疼痛",
  "疾病",
  "失能",
  "紊乱",
  "癌症",
  "病毒",
  "感染",
  "治疗",
  "治疗",
  "处方",
  "药品",
  "卫生保健",
  "终端",
  "慢性的",
  "创伤",
  "死亡",
  "肥胖",
  "瘾",
  "流行性",
  "大流行",
  "暴发",
  "隔离",
  "住院治疗",
  "紧急情况",
  "病痛",
  "症状",
  "副作用",
  "致命的",
  "死亡",
  "可预防",
  "终端",
  "过量",
  "安乐死",
  "虐待",
  "弊端",
  "自杀",
  "腐败",
  "丑闻",
  "党派",
  "争议",
  "受贿",
  "任人唯亲",
  "裙带关系",
  "接枝",
  "冲突",
  "无能",
  "操纵",
  "宣传",
  "分裂",
  "极化",
  "压迫",
  "专政",
  "专制",
  "极权主义",
  "寡头政治",
  "威权主义",
  "抑制",
  "暴政",
  "无政府状态",
  "抑制",
  "动荡",
  "反叛",
  "反对",
  "暴动",
  "示范",
  "不公正",
  "歧视",
  "种族主义",
  "性别歧视",
  "恐同症",
  "仇外心理",
  "偏执",
  "法西斯主义",
  "极端主义",
  "激进化",
  "虚伪",
  "骗局",
  "欺诈罪",
  "贪污",
  "伪造",
  "伪证",
  "abismal",
  "desfavoraveis",
  "alarmante",
  "terrível",
  "atroz",
  "horrível",
  "ruim",
  "nocivo",
  "tedioso",
  "cataclísmico",
  "catastrófico",
  "péssimo",
  "prejudicial",
  "deletério",
  "deplorável",
  "destrutivo",
  "terrível",
  "desastroso",
  "terrível",
  "execrável",
  "fatal",
  "proibindo",
  "horrível",
  "sinistro",
  "terrível",
  "severo",
  "horrível",
  "horrível",
  "hostil",
  "doloroso",
  "odioso",
  "doente",
  "prejudicial",
  "nojento",
  "malévolo",
  "maligno",
  "nojento",
  "negativo",
  "nocivo",
  "ofensiva",
  "doloroso",
  "pobre",
  "repugnante",
  "repulsivo",
  "revoltante",
  "ruinoso",
  "triste",
  "selvagem",
  "forte",
  "chocante",
  "sinistro",
  "rancoroso",
  "Terrível",
  "tóxico",
  "trágico",
  "desagradável",
  "perturbador",
  "vil",
  "vilão",
  "malvado",
  "lamentável",
  "preocupante",
  "inútil",
  "abominável",
  "acrimonioso",
  "agonizante",
  "terrível",
  "atroz",
  "horrível",
  "maligno",
  "beligerante",
  "amargo",
  "cruel",
  "desafiador",
  "prejudicial",
  "mortal",
  "demoníaco",
  "deprimente",
  "detestável",
  "desagradável",
  "desastroso",
  "terrível",
  "mal",
  "falta",
  "horrível",
  "horrível",
  "prejudicial",
  "de partir o coração",
  "comovente",
  "horrível",
  "hostil",
  "doloroso",
  "inclemente",
  "prejudicial",
  "insuportável",
  "intolerável",
  "letal",
  "repugnante",
  "maligno",
  "monstruoso",
  "assassino",
  "nocivo",
  "detestável",
  "odioso",
  "doloroso",
  "lamentável",
  "pungente",
  "rançoso",
  "repelente",
  "repreensível",
  "revoltante",
  "doentio",
  "sinistro",
  "sórdido",
  "triste",
  "insípido",
  "atormentador",
  "tóxico",
  "trágico",
  "insuportável",
  "desagradável",
  "vexatório",
  "vicioso",
  "vil",
  "vilão",
  "vingativo",
  "malvado",
  "lamentável",
  "colérico",
  "estupro",
  "vício",
  "corrupção",
  "violência",
  "engano",
  "fraude",
  "exploração",
  "terrorismo",
  "Abuso",
  "traição",
  "assédio moral",
  "coerção",
  "crime",
  "decepção",
  "desonestidade",
  "ódio",
  "injustiça",
  "opressão",
  "preconceito",
  "escravidão",
  "Sofrimento",
  "tirania",
  "guerra",
  "ataque",
  "bombear",
  "explosão",
  "violência",
  "guerra",
  "conflito",
  "terrorismo",
  "morto",
  "ferido",
  "vítimas",
  "destruição",
  "dano",
  "ferir",
  "desastre",
  "tragédia",
  "catástrofe",
  "devastação",
  "dor",
  "Sofrimento",
  "vítima",
  "assalto",
  "hostilidade",
  "agressão",
  "massacre",
  "massacre",
  "atrocidade",
  "genocídio",
  "brutalidade",
  "Horror",
  "derramamento de sangue",
  "ruína",
  "ruína",
  "aniquilação",
  "degradação",
  "morte",
  "assassinato",
  "matando",
  "matar",
  "assassinato",
  "execução",
  "tortura",
  "espancamento",
  "combate",
  "batalha",
  "lutar",
  "luta",
  "guerra",
  "cerco",
  "emboscada",
  "ataque",
  "invasão",
  "ocupação",
  "opressão",
  "repressão",
  "deslocamento",
  "refugiado",
  "crise",
  "emergência",
  "pânico",
  "temer",
  "temor",
  "caos",
  "anarquia",
  "violado",
  "perturbado",
  "ferido",
  "carnificina",
  "caos",
  "cataclismo",
  "dano colateral",
  "detonação",
  "explosivo",
  "Bombeiro",
  "conflagração",
  "granada",
  "míssil",
  "munição",
  "concha",
  "estilhaços",
  "napalm",
  "arma química",
  "arma biológica",
  "radiação",
  "nuclear",
  "tóxico",
  "tóxico",
  "contaminação",
  "poluição",
  "Rússia",
  "feudo",
  "prender prisão",
  "preso",
  "explodiu",
  "explodir",
  "viciado",
  "vício",
  "furioso",
  "atirar",
  "abater",
  "Ucrânia",
  "Palestina",
  "é real",
  "atirador",
  "doença",
  "processado",
  "explosão",
  "pistola",
  "Violência armada",
  "facada",
  "esfaqueamento",
  "ferida",
  "lesões",
  "duelo",
  "duelo",
  "tráfico",
  "cocaína",
  "maconha",
  "roubo",
  "roubo",
  "violência",
  "aterrorizante",
  "trauma",
  "esfaqueamento",
  "sexo",
  "Câncer",
  "doenças",
  "doença",
  "controverso",
  "enviesado",
  "controvérsia",
  "tribunal",
  "eleição",
  "eleições",
  "trunfo",
  "Biden",
  "diagnosticado",
  "Doença",
  "Doença",
  "Ferida",
  "Dor",
  "Doença",
  "Incapacidade",
  "Transtorno",
  "Câncer",
  "Vírus",
  "Infecção",
  "Terapia",
  "Tratamento",
  "Prescrição",
  "Medicamento",
  "Assistência médica",
  "Corrupção",
  "Escândalo",
  "Partidário",
  "Controvérsia",
  "Suborno",
  "Clonismo",
  "Nepotismo",
  "Enxerto",
  "Conflito",
  "Incompetência",
  "Manipulação",
  "Propaganda",
  "Divisivo",
  "Polarização",
  "Opressão",
  "Doença",
  "Doença",
  "Ferida",
  "Dor",
  "Doença",
  "Incapacidade",
  "Transtorno",
  "Câncer",
  "Vírus",
  "Infecção",
  "Terapia",
  "Tratamento",
  "Prescrição",
  "Medicamento",
  "Assistência médica",
  "Terminal",
  "Crônica",
  "Trauma",
  "Fatalidade",
  "Obesidade",
  "Vício",
  "Epidemia",
  "Pandemia",
  "Surto",
  "Quarentena",
  "Hospitalização",
  "Emergência",
  "Doença",
  "Sintoma",
  "Efeitos colaterais",
  "Fatal",
  "Mortalidade",
  "Evitável",
  "Terminal",
  "Overdose",
  "Eutanásia",
  "Abuso",
  "Erro médico",
  "Suicídio",
  "Corrupção",
  "Escândalo",
  "Partidário",
  "Controvérsia",
  "Suborno",
  "Clonismo",
  "Nepotismo",
  "Enxerto",
  "Conflito",
  "Incompetência",
  "Manipulação",
  "Propaganda",
  "Divisivo",
  "Polarização",
  "Opressão",
  "Ditadura",
  "Autocracia",
  "Totalitarismo",
  "Oligarquia",
  "Autoritarismo",
  "Supressão",
  "Tirania",
  "Anarquia",
  "Repressão",
  "Agitação",
  "Revolta",
  "Protesto",
  "Rebelião",
  "Demonstração",
  "Injustiça",
  "Discriminação",
  "Racismo",
  "Sexismo",
  "Homofobia",
  "Xenofobia",
  "Intolerância",
  "Fascismo",
  "Extremismo",
  "Radicalização",
  "Hipocrisia",
  "Fraude",
  "Fraude",
  "Desfalque",
  "Falsificação",
  "Perjúrio",
  "abissale",
  "avverso",
  "allarmante",
  "spaventoso",
  "atroce",
  "Terribile",
  "Cattivo",
  "dannoso",
  "noioso",
  "cataclisma",
  "catastrofico",
  "scadente",
  "dannoso",
  "deleterio",
  "deplorevole",
  "distruttivo",
  "terribile",
  "disastroso",
  "terribile",
  "esecrabile",
  "fatale",
  "vietato",
  "orribile",
  "cupo",
  "macabro",
  "duro",
  "orribile",
  "orribile",
  "ostile",
  "dannoso",
  "odioso",
  "malato",
  "dannoso",
  "schifoso",
  "malevolo",
  "maligno",
  "sgradevole",
  "negativo",
  "nocivo",
  "offensivo",
  "doloroso",
  "povero",
  "ripugnante",
  "ripugnante",
  "rivoltante",
  "rovinoso",
  "triste",
  "attaccare",
  "acuto",
  "scioccante",
  "sinistro",
  "dispettoso",
  "terribile",
  "tossico",
  "tragico",
  "sgradevole",
  "sconvolgente",
  "vile",
  "cattivo",
  "malvagio",
  "doloroso",
  "preoccupante",
  "senza valore",
  "abominevole",
  "aspro",
  "agonizzante",
  "spaventoso",
  "atroce",
  "Terribile",
  "funesto",
  "belligerante",
  "amaro",
  "crudele",
  "tagliagole",
  "dannoso",
  "mortale",
  "demoniaco",
  "deprimente",
  "detestabile",
  "sgradevole",
  "disastroso",
  "terribile",
  "cattivo",
  "ripugnante",
  "orribile",
  "raccapricciante",
  "dannoso",
  "straziante",
  "straziante",
  "orribile",
  "ostile",
  "dannoso",
  "inclemente",
  "dannoso",
  "insopportabile",
  "intollerabile",
  "letale",
  "odioso",
  "maligno",
  "mostruoso",
  "omicida",
  "nocivo",
  "odioso",
  "odioso",
  "doloroso",
  "pietoso",
  "pungente",
  "rancido",
  "repellente",
  "riprovevole",
  "rivoltante",
  "disgustoso",
  "sinistro",
  "sordido",
  "doloroso",
  "insapore",
  "torturante",
  "tossico",
  "tragico",
  "insopportabile",
  "sgradevole",
  "fastidioso",
  "vizioso",
  "vile",
  "cattivo",
  "vendicativo",
  "malvagio",
  "doloroso",
  "irato",
  "stupro",
  "dipendenza",
  "corruzione",
  "violenza",
  "inganno",
  "frode",
  "sfruttamento",
  "terrorismo",
  "abuso",
  "tradimento",
  "bullismo",
  "coercizione",
  "crimine",
  "inganno",
  "disonestà",
  "odio",
  "ingiustizia",
  "oppressione",
  "pregiudizio",
  "schiavitù",
  "sofferenza",
  "tirannia",
  "guerra",
  "attacco",
  "bomba",
  "esplosione",
  "violenza",
  "guerra",
  "conflitto",
  "terrorismo",
  "ucciso",
  "ferito",
  "vittime",
  "distruzione",
  "danno",
  "danno",
  "disastro",
  "tragedia",
  "catastrofe",
  "devastazione",
  "Dolore",
  "sofferenza",
  "vittima",
  "assalto",
  "ostilità",
  "aggressione",
  "massacro",
  "macellare",
  "atrocità",
  "genocidio",
  "brutalità",
  "orrore",
  "spargimento di sangue",
  "rovina",
  "rovina",
  "annientamento",
  "degradazione",
  "morte",
  "omicidio",
  "uccidere",
  "uccisione",
  "assassinio",
  "esecuzione",
  "tortura",
  "battere",
  "combattere",
  "battaglia",
  "combattimento",
  "lotta",
  "guerra",
  "assedio",
  "imboscata",
  "incursione",
  "invasione",
  "occupazione",
  "oppressione",
  "repressione",
  "Dislocamento",
  "profugo",
  "crisi",
  "emergenza",
  "panico",
  "Paura",
  "paura",
  "caos",
  "anarchia",
  "violato",
  "interrotto",
  "ferito",
  "carneficina",
  "caos",
  "cataclisma",
  "danno collaterale",
  "detonazione",
  "esplosivo",
  "scontro a fuoco",
  "conflagrazione",
  "bomba a mano",
  "missile",
  "munizioni",
  "conchiglia",
  "scheggia",
  "napalm",
  "Arma chimica",
  "arma biologica",
  "radiazione",
  "nucleare",
  "tossico",
  "veleno",
  "contaminazione",
  "inquinamento",
  "Russia",
  "feudo",
  "arresto",
  "arrestato",
  "esplose",
  "saltare",
  "dipendente",
  "dipendenza",
  "furioso",
  "sparare",
  "abbattere",
  "Ucraina",
  "Palestina",
  "è reale",
  "sparatutto",
  "malattia",
  "citato in giudizio",
  "raffica",
  "pistola",
  "Violenza armata",
  "pugnalata",
  "accoltellato",
  "infortunio",
  "lesioni",
  "duello",
  "duello",
  "traffico",
  "cocaina",
  "marijuana",
  "furto",
  "rapina",
  "violenza",
  "terrificante",
  "trauma",
  "accoltellato",
  "sesso",
  "cancro",
  "malattie",
  "malattia",
  "controverso",
  "prevenuto",
  "controversia",
  "Tribunale",
  "elezione",
  "elezioni",
  "briscola",
  "biden",
  "diagnosticato",
  "Malattia",
  "Malattia",
  "Infortunio",
  "Dolore",
  "Malattia",
  "Disabilità",
  "Disturbo",
  "Cancro",
  "Virus",
  "Infezione",
  "Terapia",
  "Trattamento",
  "Prescrizione",
  "Medicinale",
  "Assistenza sanitaria",
  "Corruzione",
  "Scandalo",
  "Partigiano",
  "Controversia",
  "Corruzione",
  "Cronyismo",
  "Nepotismo",
  "Corruzione",
  "Conflitto",
  "Incompetenza",
  "Manipolazione",
  "Propaganda",
  "Divisivo",
  "Polarizzazione",
  "Oppressione",
  "Malattia",
  "Malattia",
  "Infortunio",
  "Dolore",
  "Malattia",
  "Disabilità",
  "Disturbo",
  "Cancro",
  "Virus",
  "Infezione",
  "Terapia",
  "Trattamento",
  "Prescrizione",
  "Medicinale",
  "Assistenza sanitaria",
  "Terminale",
  "Cronico",
  "Trauma",
  "Fatalità",
  "Obesità",
  "Dipendenza",
  "Epidemia",
  "Pandemia",
  "Epidemia",
  "Quarantena",
  "Ospedalizzazione",
  "Emergenza",
  "Malattia",
  "Sintomo",
  "Effetti collaterali",
  "Fatale",
  "Mortalità",
  "Prevenibile",
  "Terminale",
  "Overdose",
  "Eutanasia",
  "Abuso",
  "Imperfezione",
  "Suicidio",
  "Corruzione",
  "Scandalo",
  "Partigiano",
  "Controversia",
  "Corruzione",
  "Cronyismo",
  "Nepotismo",
  "Corruzione",
  "Conflitto",
  "Incompetenza",
  "Manipolazione",
  "Propaganda",
  "Divisivo",
  "Polarizzazione",
  "Oppressione",
  "Dittatura",
  "Autocrazia",
  "Totalitarismo",
  "Oligarchia",
  "Autoritarismo",
  "Soppressione",
  "Tirannia",
  "Anarchia",
  "Repressione",
  "Disordini",
  "Rivolta",
  "Protesta",
  "Rivolta",
  "Dimostrazione",
  "Ingiustizia",
  "Discriminazione",
  "Razzismo",
  "Sessismo",
  "Omofobia",
  "Xenofobia",
  "Bigottismo",
  "Fascismo",
  "Estremismo",
  "Radicalizzazione",
  "Ipocrisia",
  "Truffa",
  "Frode",
  "Appropriazione indebita",
  "Falsificazione",
  "Spergiuro",
  "bottenlös",
  "ogynnsam",
  "oroväckande",
  "skrämmande",
  "fruktansvärt",
  "förfärlig",
  "dålig",
  "ödesdiger",
  "tråkig",
  "katalysmisk",
  "katastrofal",
  "kass",
  "skadlig",
  "skadlig",
  "beklaglig",
  "destruktiv",
  "hemsk",
  "katastrofal",
  "hemsk",
  "förlåtlig",
  "dödlig",
  "förbjuder",
  "hemskt",
  "dyster",
  "hemsk",
  "hård",
  "hemskt",
  "fruktansvärd",
  "fientlig",
  "sårande",
  "förhatlig",
  "sjuk",
  "skadlig",
  "nedlusad",
  "illvillig",
  "malign",
  "otäck",
  "negativ",
  "skadlig",
  "offensiv",
  "smärtsam",
  "fattig",
  "motbjudande",
  "motbjudande",
  "upprörande",
  "ödeläggande",
  "ledsen",
  "vilde",
  "svår",
  "upprörande",
  "olycksbådande",
  "hätsk",
  "fruktansvärd",
  "toxisk",
  "tragisk",
  "obehaglig",
  "upprörande",
  "vidrig",
  "skurkaktig",
  "ond",
  "bedrövad",
  "oroande",
  "värdelös",
  "avskyvärd",
  "bitter",
  "plågsam",
  "skrämmande",
  "fruktansvärt",
  "förfärlig",
  "ondskefull",
  "stridslysten",
  "bitter",
  "grym",
  "mördande",
  "skadlig",
  "dödligt",
  "demonisk",
  "deprimerande",
  "avskyvärd",
  "obehaglig",
  "katastrofal",
  "hemsk",
  "ondska",
  "ojust spel",
  "hemskt",
  "hemsk",
  "skadlig",
  "förkrossande",
  "hjärtskärande",
  "skräckinjagande",
  "fientlig",
  "sårande",
  "bister",
  "skadlig",
  "olidlig",
  "outhärdlig",
  "dödlig",
  "avskyvärd",
  "malign",
  "monstruös",
  "mördande",
  "skadlig",
  "motbjudande",
  "motbjudande",
  "smärtsam",
  "tråkigt",
  "skarp",
  "härsken",
  "frånstötande",
  "klandervärd",
  "upprörande",
  "kväljande",
  "olycksbådande",
  "smutsig",
  "sorgsen",
  "smaklös",
  "plåga",
  "toxisk",
  "tragisk",
  "outhärdlig",
  "obehaglig",
  "irriterande",
  "ondska",
  "vidrig",
  "skurkaktig",
  "hämndlysten",
  "ond",
  "bedrövad",
  "vred",
  "våldta",
  "missbruk",
  "korruption",
  "våld",
  "svek",
  "bedrägeri",
  "utnyttjande",
  "terrorism",
  "missbruk",
  "svek",
  "mobbning",
  "tvång",
  "brottslighet",
  "bedrägeri",
  "oärlighet",
  "hat",
  "orättvisa",
  "förtryck",
  "fördomar",
  "slaveri",
  "lidande",
  "tyranni",
  "krig",
  "ge sig på",
  "bomba",
  "explosion",
  "våld",
  "krig",
  "konflikt",
  "terrorism",
  "dödad",
  "skadade",
  "förluster",
  "förstörelse",
  "skada",
  "skada",
  "katastrof",
  "tragedi",
  "katastrof",
  "förödelse",
  "smärta",
  "lidande",
  "offer",
  "överfall",
  "fientlighet",
  "aggression",
  "massaker",
  "slakt",
  "illdåd",
  "folkmord",
  "brutalitet",
  "Skräck",
  "blodsutgjutelse",
  "ruin",
  "ödeläggelse",
  "förintelse",
  "degradering",
  "död",
  "mörda",
  "dödande",
  "döda",
  "lönnmord",
  "avrättning",
  "tortyr",
  "stryk",
  "bekämpa",
  "slåss",
  "bekämpa",
  "kamp",
  "krigföring",
  "belägring",
  "bakhåll",
  "räd",
  "invasion",
  "ockupation",
  "förtryck",
  "undertryckande",
  "förflyttning",
  "flykting",
  "kris",
  "nödsituation",
  "panik",
  "rädsla",
  "fruktan",
  "kaos",
  "anarki",
  "kränkt",
  "stört",
  "sårad",
  "blodbad",
  "förödelse",
  "kataklysm",
  "indirekta skador",
  "detonation",
  "explosiv",
  "brandbekämpning",
  "brand",
  "granat",
  "missil",
  "ammunition",
  "skal",
  "splitter",
  "napalm",
  "kemiskt vapen",
  "biologiskt vapen",
  "strålning",
  "kärn",
  "toxisk",
  "förgifta",
  "förorening",
  "förorening",
  "Ryssland",
  "fejd",
  "gripa",
  "arresterad",
  "sprängde",
  "spränga",
  "Beroende",
  "missbruk",
  "rasande",
  "skjuta",
  "skjuta ner",
  "ukraina",
  "palestina",
  "är riktig",
  "skytt",
  "sjukdom",
  "stämd",
  "kul",
  "pistol",
  "vapenvåld",
  "hugg",
  "hugg",
  "skada",
  "skador",
  "duell",
  "duellerande",
  "trafficking",
  "kokain",
  "marijuana",
  "stöld",
  "rån",
  "våld",
  "läskigt",
  "trauma",
  "hugg",
  "sex",
  "cancer",
  "sjukdomar",
  "sjukdom",
  "kontroversiell",
  "partisk",
  "kontrovers",
  "domstol",
  "val",
  "val",
  "trumf",
  "biden",
  "diagnostiserat",
  "Sjukdom",
  "Sjukdom",
  "Skada",
  "Smärta",
  "Sjukdom",
  "Handikapp",
  "Oordning",
  "Cancer",
  "Virus",
  "Infektion",
  "Terapi",
  "Behandling",
  "Recept",
  "Medicin",
  "Sjukvård",
  "Korruption",
  "Skandal",
  "Partisan",
  "Kontrovers",
  "Bestickning",
  "Kronyism",
  "Nepotism",
  "Ympa",
  "Konflikt",
  "Inkompetens",
  "Manipulation",
  "Propaganda",
  "Delande",
  "Polarisering",
  "Förtryck",
  "Sjukdom",
  "Sjukdom",
  "Skada",
  "Smärta",
  "Sjukdom",
  "Handikapp",
  "Oordning",
  "Cancer",
  "Virus",
  "Infektion",
  "Terapi",
  "Behandling",
  "Recept",
  "Medicin",
  "Sjukvård",
  "Terminal",
  "Kronisk",
  "Trauma",
  "Dödsoffer",
  "Fetma",
  "Missbruk",
  "Epidemi",
  "Pandemisk",
  "Utbrott",
  "Karantän",
  "Sjukhusinläggning",
  "Nödsituation",
  "Sjukdom",
  "Symptom",
  "Bieffekter",
  "Dödlig",
  "Dödlighet",
  "Förebyggbar",
  "Terminal",
  "Överdos",
  "Dödshjälp",
  "Missbruk",
  "Försummelse",
  "Självmord",
  "Korruption",
  "Skandal",
  "Partisan",
  "Kontrovers",
  "Bestickning",
  "Kronyism",
  "Nepotism",
  "Ympa",
  "Konflikt",
  "Inkompetens",
  "Manipulation",
  "Propaganda",
  "Delande",
  "Polarisering",
  "Förtryck",
  "Diktatur",
  "Envälde",
  "Totalitarism",
  "Oligarki",
  "Auktoritärism",
  "Undertryckande",
  "Tyranni",
  "Anarki",
  "Undertryckande",
  "Oro",
  "Revolt",
  "Protest",
  "Upplopp",
  "Demonstration",
  "Orättvisa",
  "Diskriminering",
  "Rasism",
  "Sexism",
  "Homofobi",
  "Främlingshat",
  "Bigotteri",
  "Fascism",
  "Extremism",
  "Radikalisering",
  "Hyckleri",
  "Lurendrejeri",
  "Bedrägeri",
  "Förskingring",
  "Förfalskning",
  "Mened",
  "ужасный",
  "неблагоприятный",
  "тревожный",
  "ужасный",
  "зверский",
  "ужасный",
  "плохой",
  "губительный",
  "скучный",
  "катастрофический",
  "катастрофический",
  "мерзкий",
  "вредный",
  "вредный",
  "жалкий",
  "разрушительный",
  "ужасный",
  "катастрофический",
  "ужасный",
  "отвратительный",
  "фатальный",
  "запрещающий",
  "ужасный",
  "мрачный",
  "ужасный",
  "жесткий",
  "ужасный",
  "ужасный",
  "враждебный",
  "обидный",
  "ненавистный",
  "больной",
  "вредный",
  "паршивый",
  "злой",
  "злокачественный",
  "противный",
  "негативный",
  "вредный",
  "оскорбительный",
  "болезненный",
  "бедный",
  "отвратительный",
  "отталкивающий",
  "отвратительный",
  "разрушительный",
  "грустный",
  "дикий",
  "серьезный",
  "шокирующий",
  "зловещий",
  "злобный",
  "ужасный",
  "токсичный",
  "трагический",
  "неприятный",
  "расстраивает",
  "мерзкий",
  "злодейский",
  "злой",
  "печальный",
  "тревожный",
  "бесполезный",
  "отвратительный",
  "жесткий",
  "мучительный",
  "ужасный",
  "зверский",
  "ужасный",
  "зловещий",
  "воинственный",
  "горький",
  "жестокий",
  "головорез",
  "вредный",
  "смертельный",
  "демонический",
  "депрессивный",
  "отвратительный",
  "несогласный",
  "катастрофический",
  "ужасный",
  "зло",
  "грязный",
  "ужасный",
  "ужасный",
  "вредный",
  "душераздирающий",
  "душераздирающий",
  "ужасающий",
  "враждебный",
  "обидный",
  "ненастный",
  "вредный",
  "невыносимый",
  "невыносимо",
  "смертельный",
  "отвратительный",
  "злокачественный",
  "чудовищный",
  "убийственный",
  "вредный",
  "противный",
  "одиозный",
  "болезненный",
  "жалкий",
  "острый",
  "прогорклый",
  "отталкивающий",
  "предосудительный",
  "отвратительный",
  "тошнотворный",
  "зловещий",
  "грязный",
  "печальный",
  "безвкусный",
  "мучительный",
  "токсичный",
  "трагический",
  "невыносимый",
  "неприятный",
  "досадный",
  "беспощадный",
  "мерзкий",
  "злодейский",
  "мстительный",
  "злой",
  "печальный",
  "гневный",
  "изнасилование",
  "зависимость",
  "коррупция",
  "насилие",
  "обман",
  "мошенничество",
  "эксплуатация",
  "терроризм",
  "злоупотреблять",
  "предательство",
  "издевательства",
  "принуждение",
  "преступление",
  "обман",
  "нечестность",
  "ненависть",
  "несправедливость",
  "угнетение",
  "предрассудки",
  "рабство",
  "страдания",
  "тирания",
  "война",
  "атака",
  "бомбить",
  "взрыв",
  "насилие",
  "война",
  "конфликт",
  "терроризм",
  "убит",
  "раненый",
  "жертвы",
  "разрушение",
  "повреждать",
  "вред",
  "катастрофа",
  "трагедия",
  "катастрофа",
  "опустошение",
  "боль",
  "страдания",
  "жертва",
  "атаковать",
  "враждебность",
  "агрессия",
  "резня",
  "резня",
  "злодеяние",
  "геноцид",
  "жестокость",
  "ужастик",
  "кровопролитие",
  "разрушение",
  "разрушение",
  "уничтожение",
  "деградация",
  "смерть",
  "убийство",
  "убийство",
  "убийство",
  "убийство",
  "исполнение",
  "пытка",
  "избиение",
  "боевой",
  "боевой",
  "драться",
  "борьба",
  "военное дело",
  "осада",
  "засада",
  "рейд",
  "вторжение",
  "занятие",
  "угнетение",
  "репрессии",
  "перемещение",
  "беженец",
  "кризис",
  "чрезвычайная ситуация",
  "паника",
  "страх",
  "страх",
  "хаос",
  "анархия",
  "нарушено",
  "нарушенный",
  "раненый",
  "резня",
  "беспредел",
  "катаклизм",
  "сопутствующий ущерб",
  "детонация",
  "взрывной",
  "перестрелка",
  "пожар",
  "граната",
  "ракета",
  "боеприпас",
  "оболочка",
  "шрапнель",
  "напалм",
  "химическое оружие",
  "биологическое оружие",
  "радиация",
  "ядерный",
  "токсичный",
  "яд",
  "загрязнение",
  "загрязнение",
  "россия",
  "вражда",
  "арест",
  "арестованный",
  "взорвал",
  "Взрывать",
  "с зависимостью",
  "зависимость",
  "яростный",
  "стрелять",
  "застрелить",
  "Украина",
  "Палестина",
  "реально",
  "стрелок",
  "болезнь",
  "подал в суд",
  "взрыв",
  "пистолет",
  "насилие с применением огнестрельного оружия",
  "колоть",
  "колоть",
  "рана",
  "травмы",
  "дуэль",
  "дуэль",
  "торговля людьми",
  "кокаин",
  "марихуана",
  "кража",
  "грабеж",
  "насилие",
  "ужасающий",
  "травма",
  "колоть",
  "секс",
  "рак",
  "болезни",
  "болезнь",
  "спорный",
  "пристрастный",
  "спор",
  "суд",
  "выборы",
  "выборы",
  "Трамп",
  "Байден",
  "диагностирован",
  "Болезнь",
  "Болезнь",
  "Рана",
  "Боль",
  "Болезнь",
  "Инвалидность",
  "Беспорядок",
  "Рак",
  "Вирус",
  "Инфекционное заболевание",
  "Терапия",
  "Уход",
  "Рецепт",
  "Лекарство",
  "Здравоохранение",
  "Коррупция",
  "Скандал",
  "Партизан",
  "Спор",
  "Взяточничество",
  "Кумовство",
  "Кумовство",
  "Прививка",
  "Конфликт",
  "Некомпетентность",
  "Манипуляция",
  "Пропаганда",
  "Дивизион",
  "Поляризация",
  "Угнетение",
  "Болезнь",
  "Болезнь",
  "Рана",
  "Боль",
  "Болезнь",
  "Инвалидность",
  "Беспорядок",
  "Рак",
  "Вирус",
  "Инфекционное заболевание",
  "Терапия",
  "Уход",
  "Рецепт",
  "Лекарство",
  "Здравоохранение",
  "Терминал",
  "Хронический",
  "Травма",
  "Фаталити",
  "Ожирение",
  "Зависимость",
  "Эпидемия",
  "Пандемия",
  "Вспышка",
  "Карантин",
  "Госпитализация",
  "Чрезвычайная ситуация",
  "Недуг",
  "Симптом",
  "Побочные эффекты",
  "Роковой",
  "Смертность",
  "Предотвратимый",
  "Терминал",
  "Передозировка",
  "Эвтаназия",
  "Злоупотреблять",
  "Злоупотребление служебным положением",
  "Самоубийство",
  "Коррупция",
  "Скандал",
  "Партизан",
  "Спор",
  "Взяточничество",
  "Кумовство",
  "Кумовство",
  "Прививка",
  "Конфликт",
  "Некомпетентность",
  "Манипуляция",
  "Пропаганда",
  "Дивизион",
  "Поляризация",
  "Угнетение",
  "Диктатура",
  "Самодержавие",
  "Тоталитаризм",
  "Олигархия",
  "Авторитаризм",
  "Подавление",
  "Тирания",
  "Анархия",
  "Репрессии",
  "Беспокойство",
  "Восстание",
  "Акция протеста",
  "Бунт",
  "Демонстрация",
  "Несправедливость",
  "Дискриминация",
  "Расизм",
  "Сексизм",
  "Гомофобия",
  "Ксенофобия",
  "Фанизм",
  "Фашизм",
  "Экстремизм",
  "Радикализация",
  "Лицемерие",
  "Мошенничество",
  "Мошенничество",
  "Растрата",
  "Подделка",
  "Лжесвидетельство",
  "निराशाजनक",
  "हानिकर",
  "चिंताजनक",
  "भयावह",
  "अत्याचारी",
  "भयंकर",
  "खराब",
  "हानिकारक",
  "उबाऊ",
  "प्रलयकारी",
  "विनाशकारी",
  "बेकार",
  "हानिकारक",
  "हानिकारक",
  "निंदनीय",
  "विनाशकारी",
  "गंभीर",
  "विनाशकारी",
  "भयानक",
  "निष्पादन योग्य",
  "घातक",
  "मना करना",
  "भयानक",
  "विकट",
  "भयानक",
  "कठोर",
  "भयानक",
  "भयंकर",
  "शत्रुतापूर्ण",
  "दुखदायी",
  "घृणित",
  "बीमार",
  "हानिकारक",
  "घटिया",
  "दुर्भावनापूर्ण",
  "घातक",
  "बहुत खराब",
  "नकारात्मक",
  "हानिकारक",
  "अप्रिय",
  "दर्दनाक",
  "गरीब",
  "प्रतिद्वंद्वी",
  "प्रतिकारक",
  "विद्रोही",
  "विनाशकारी",
  "उदास",
  "असभ्य",
  "गंभीर",
  "चौंका देने वाला",
  "भयावह",
  "द्वेषपूर्ण",
  "भयानक",
  "विषाक्त",
  "दुखद",
  "अप्रिय",
  "परेशान करने वाला",
  "नीच",
  "खलनायक",
  "दुष्ट",
  "दुखद",
  "चिंता",
  "बेकार",
  "घृणित",
  "कटु",
  "पीड़ादायक",
  "भयावह",
  "अत्याचारी",
  "भयंकर",
  "हानिकारक",
  "जुझारू",
  "कड़वा",
  "निर्दयी",
  "गला काट",
  "हानिकारक",
  "घातक",
  "राक्षसी",
  "निराशाजनक",
  "घृणित",
  "असहमति",
  "विनाशकारी",
  "भयानक",
  "बुराई",
  "बेईमानी",
  "भयानक",
  "भयानक",
  "हानिकारक",
  "दिल तोड़ने वाला",
  "दिल दहला देने वाला",
  "भयानक",
  "शत्रुतापूर्ण",
  "दुखदायी",
  "ख़राब",
  "हानिकारक",
  "असहनीय",
  "असहनीय",
  "घातक",
  "घृणित",
  "घातक",
  "राक्षसी",
  "हत्यारा",
  "हानिकारक",
  "अप्रिय",
  "घिनौना",
  "दर्दनाक",
  "दुःखद",
  "तीखा",
  "बासी",
  "विकर्षक",
  "निंदनीय",
  "विद्रोही",
  "बीमार करने वाला",
  "भयावह",
  "घिनौना",
  "दुःखद",
  "को फीका",
  "पीड़ा",
  "विषाक्त",
  "दुखद",
  "असहनीय",
  "अप्रिय",
  "परेशान करने वाला",
  "दुष्ट",
  "नीच",
  "खलनायक",
  "प्रतिशोधी",
  "दुष्ट",
  "दुखद",
  "क्रोधपूर्ण",
  "बलात्कार",
  "लत",
  "भ्रष्टाचार",
  "हिंसा",
  "छल",
  "धोखा",
  "शोषण",
  "आतंकवाद",
  "दुर्व्यवहार करना",
  "विश्वासघात",
  "बदमाशी",
  "दबाव",
  "अपराध",
  "धोखा",
  "बेईमानी",
  "घृणा",
  "अन्याय",
  "उत्पीड़न",
  "पूर्वाग्रह",
  "गुलामी",
  "कष्ट",
  "अत्याचार",
  "युद्ध",
  "आक्रमण करना",
  "बम",
  "विस्फोट",
  "हिंसा",
  "युद्ध",
  "टकराव",
  "आतंकवाद",
  "मारे गए",
  "घायल",
  "हताहत",
  "विनाश",
  "हानि",
  "चोट",
  "आपदा",
  "त्रासदी",
  "प्रलय",
  "तबाही",
  "दर्द",
  "कष्ट",
  "पीड़ित",
  "हमला करना",
  "शत्रुता",
  "आक्रामकता",
  "नरसंहार",
  "वध",
  "अत्याचार",
  "नरसंहार",
  "क्रूरता",
  "डरावनी",
  "रक्तपात",
  "बर्बाद करना",
  "बर्बाद",
  "विनाश",
  "निम्नीकरण",
  "मौत",
  "हत्या",
  "मारना",
  "मारना",
  "हत्या",
  "कार्यान्वयन",
  "यातना",
  "पिटाई",
  "लड़ाई",
  "युद्ध",
  "झगड़ा करना",
  "संघर्ष",
  "युद्ध",
  "घेराबंदी",
  "घात लगाना",
  "छापेमारी",
  "आक्रमण",
  "पेशा",
  "उत्पीड़न",
  "दमन",
  "विस्थापन",
  "शरणार्थी",
  "संकट",
  "आपातकाल",
  "घबड़ाहट",
  "डर",
  "डर लगना",
  "अव्यवस्था",
  "अराजकता",
  "उल्लंघन",
  "बाधित",
  "घायल",
  "नरसंहार",
  "तबाही",
  "प्रलय",
  "ज़मानत क्षति",
  "विस्फोट",
  "विस्फोटक",
  "गोलीबारी",
  "ज्वलन",
  "ग्रेनेड",
  "मिसाइल",
  "युद्ध सामग्री",
  "शंख",
  "छर्रे",
  "नैपलम",
  "रासायनिक हथियार",
  "जैविक हथियार",
  "विकिरण",
  "परमाणु",
  "विषाक्त",
  "ज़हर",
  "दूषण",
  "प्रदूषण",
  "रूस",
  "झगड़ा",
  "गिरफ़्तार करना",
  "गिरफ्तार",
  "विस्फोट से उड़ा दिया",
  "झटका",
  "आदी",
  "लत",
  "आगबबूला",
  "गोली मार",
  "गिराओ",
  "यूक्रेन",
  "फिलिस्तीन",
  "यह सचमुच का है",
  "निशानेबाज",
  "बीमारी",
  "मुकदमा",
  "धमाका",
  "बंदूक",
  "गन वायलेंस",
  "छूरा भोंकना",
  "छुरा घोंपना",
  "चोट",
  "चोटें",
  "द्वंद्वयुद्ध",
  "द्वंद्वयुद्ध",
  "तस्करी",
  "कोकीन",
  "मारिजुआना",
  "चोरी",
  "डकैती",
  "हिंसा",
  "भयानक",
  "सदमा",
  "छुरा घोंपना",
  "लिंग",
  "कैंसर",
  "रोग",
  "बीमारी",
  "विवादित",
  "पक्षपाती",
  "विवाद",
  "अदालत",
  "चुनाव",
  "चुनाव",
  "ट्रम्प",
  "बिडेन",
  "निदान",
  "बीमारी",
  "बीमारी",
  "चोट",
  "दर्द",
  "बीमारी",
  "विकलांगता",
  "विकार",
  "कैंसर",
  "वायरस",
  "संक्रमण",
  "थेरेपी",
  "इलाज",
  "नुस्खा",
  "दवा",
  "स्वास्थ्य देखभाल",
  "भ्रष्टाचार",
  "कांड",
  "पक्षपातपूर्ण",
  "विवाद",
  "रिश्वत",
  "क्रोनीज़्म",
  "भाई-भतीजावाद",
  "घूस",
  "टकराव",
  "अक्षमता",
  "चालाकी",
  "प्रचार करना",
  "विभाजनकारी",
  "ध्रुवीकरण",
  "उत्पीड़न",
  "बीमारी",
  "बीमारी",
  "चोट",
  "दर्द",
  "बीमारी",
  "विकलांगता",
  "विकार",
  "कैंसर",
  "वायरस",
  "संक्रमण",
  "थेरेपी",
  "इलाज",
  "नुस्खा",
  "दवा",
  "स्वास्थ्य देखभाल",
  "टर्मिनल",
  "दीर्घकालिक",
  "सदमा",
  "मृत्यु",
  "मोटापा",
  "लत",
  "महामारी",
  "महामारी",
  "प्रकोप",
  "संगरोधन",
  "अस्पताल में भर्ती",
  "आपातकाल",
  "बीमारी",
  "लक्षण",
  "दुष्प्रभाव",
  "घातक",
  "मृत्यु दर",
  "रोकथाम योग्य",
  "टर्मिनल",
  "ओवरडोज़",
  "इच्छामृत्यु",
  "दुर्व्यवहार करना",
  "कदाचार",
  "आत्महत्या",
  "भ्रष्टाचार",
  "कांड",
  "पक्षपातपूर्ण",
  "विवाद",
  "रिश्वत",
  "क्रोनीज़्म",
  "भाई-भतीजावाद",
  "घूस",
  "टकराव",
  "अक्षमता",
  "चालाकी",
  "प्रचार करना",
  "विभाजनकारी",
  "ध्रुवीकरण",
  "उत्पीड़न",
  "तानाशाही",
  "निरंकुशता",
  "अधिनायकवाद",
  "कुलीनतंत्र",
  "अधिनायकवाद",
  "दमन",
  "अत्याचार",
  "अराजकता",
  "दमन",
  "अशांति",
  "विद्रोह",
  "विरोध",
  "दंगा",
  "प्रदर्शन",
  "अन्याय",
  "भेदभाव",
  "जातिवाद",
  "सेक्सिज्म",
  "होमोफोबिया",
  "ज़ेनोफ़ोबिया",
  "कट्टरता",
  "फासीवाद",
  "अतिवाद",
  "कट्टरीकरण",
  "पाखंड",
  "घोटाला",
  "धोखा",
  "गबन",
  "जालसाजी",
  "झूठ बोलना",
  "ひどい",
  "不利な",
  "憂慮すべき",
  "ぞっとする",
  "ひどい",
  "ひどい",
  "悪い",
  "有害な",
  "つまらない",
  "大惨事",
  "壊滅的",
  "くどい",
  "ダメージを与える",
  "有害な",
  "嘆かわしい",
  "破壊的",
  "悲惨な",
  "悲惨な",
  "恐ろしい",
  "ひどい",
  "致命的",
  "禁止する",
  "恐ろしい",
  "厳しい",
  "恐ろしい",
  "ひどい",
  "恐ろしい",
  "最悪",
  "敵対的",
  "傷ついた",
  "憎い",
  "病気",
  "有害な",
  "ひどい",
  "悪意のある",
  "悪性",
  "汚い",
  "ネガティブ",
  "有害",
  "攻撃",
  "痛い",
  "貧しい",
  "嫌悪感のある",
  "反発的",
  "反乱を起こしている",
  "破滅的",
  "悲しい",
  "野蛮人",
  "厳しい",
  "ショッキング",
  "邪悪な",
  "悪意のある",
  "ひどい",
  "有毒",
  "悲劇的",
  "不快",
  "動揺する",
  "卑劣な",
  "悪役",
  "邪悪な",
  "ひどい",
  "心配",
  "無価値",
  "忌まわしい",
  "辛辣な",
  "苦しい",
  "ぞっとする",
  "ひどい",
  "ひどい",
  "有害な",
  "好戦的",
  "苦い",
  "残酷な",
  "凶悪",
  "ダメージを与える",
  "致命的",
  "悪魔的な",
  "憂鬱",
  "大嫌いな",
  "不快",
  "悲惨な",
  "恐ろしい",
  "悪",
  "ファウル",
  "恐ろしい",
  "恐ろしい",
  "有害",
  "悲痛な",
  "切ない",
  "恐ろしい",
  "敵対的",
  "傷ついた",
  "ひどい",
  "有害な",
  "耐えられない",
  "耐えられない",
  "致死",
  "忌まわしい",
  "悪性",
  "怪物的",
  "殺人的",
  "有害",
  "不快な",
  "いやらしい",
  "痛い",
  "情けない",
  "辛い",
  "悪臭",
  "忌避剤",
  "非難すべき",
  "反乱を起こしている",
  "気持ち悪い",
  "邪悪な",
  "卑劣な",
  "悲しい",
  "無味",
  "苦しめる",
  "有毒",
  "悲劇的",
  "耐えられない",
  "不快",
  "腹立たしい",
  "悪質",
  "卑劣な",
  "悪役",
  "執念深い",
  "邪悪な",
  "ひどい",
  "激怒",
  "レイプ",
  "依存症",
  "腐敗",
  "暴力",
  "欺瞞",
  "詐欺",
  "搾取",
  "テロ",
  "乱用",
  "裏切り",
  "いじめ",
  "強制",
  "犯罪",
  "欺くこと",
  "不正",
  "憎しみ",
  "不正",
  "抑圧",
  "偏見",
  "奴隷制",
  "苦しみ",
  "圧政",
  "戦争",
  "攻撃",
  "爆弾",
  "爆発",
  "暴力",
  "戦争",
  "対立",
  "テロ",
  "殺された",
  "怪我した",
  "死傷者",
  "破壊",
  "ダメージ",
  "危害",
  "災害",
  "悲劇",
  "大惨事",
  "荒廃",
  "痛み",
  "苦しみ",
  "被害者",
  "襲撃",
  "敵意",
  "侵略",
  "虐殺",
  "虐殺",
  "残虐行為",
  "大量虐殺",
  "残忍",
  "ホラー",
  "流血",
  "台無しにする",
  "破滅",
  "消滅",
  "劣化",
  "死",
  "殺人",
  "殺人",
  "殺す",
  "暗殺",
  "実行",
  "拷問",
  "殴る",
  "戦闘",
  "戦い",
  "戦い",
  "闘争",
  "戦争",
  "包囲",
  "待ち伏せ",
  "襲撃",
  "侵入",
  "職業",
  "抑圧",
  "抑圧",
  "変位",
  "難民",
  "危機",
  "緊急",
  "パニック",
  "恐れ",
  "恐怖",
  "混沌",
  "アナーキー",
  "違反した",
  "混乱した",
  "負傷した",
  "大虐殺",
  "騒乱",
  "大変動",
  "巻き添え被害",
  "爆発",
  "爆発物",
  "銃撃戦",
  "大火",
  "手榴弾",
  "ミサイル",
  "軍需品",
  "シェル",
  "破片",
  "ナパーム",
  "化学兵器",
  "生物兵器",
  "放射線",
  "核",
  "有毒",
  "毒",
  "汚染",
  "汚染",
  "ロシア",
  "確執",
  "逮捕",
  "逮捕された",
  "爆発した",
  "爆破",
  "中毒",
  "依存症",
  "激怒",
  "シュート",
  "撃ち落とします",
  "ウクライナ",
  "パレスチナ",
  "は本物",
  "シューター",
  "病気",
  "訴えられた",
  "爆発",
  "銃",
  "銃暴力",
  "刺す",
  "刺す",
  "けが",
  "怪我",
  "決闘",
  "決闘",
  "人身売買",
  "コカイン",
  "マリファナ",
  "盗難",
  "強盗",
  "暴力",
  "恐ろしい",
  "トラウマ",
  "刺す",
  "セックス",
  "癌",
  "病気",
  "病気",
  "物議を醸す",
  "偏見がある",
  "論争",
  "裁判所",
  "選挙",
  "選挙",
  "トランプ",
  "バイデン",
  "診断された",
  "病気",
  "病気",
  "けが",
  "痛み",
  "病気",
  "障害",
  "障害",
  "癌",
  "ウイルス",
  "感染",
  "治療",
  "処理",
  "処方箋",
  "薬",
  "健康管理",
  "腐敗",
  "スキャンダル",
  "パルチザン",
  "論争",
  "贈収賄",
  "縁故主義",
  "縁故主義",
  "グラフト",
  "対立",
  "無能",
  "操作",
  "宣伝",
  "分裂的",
  "二極化",
  "抑圧",
  "病気",
  "病気",
  "けが",
  "痛み",
  "病気",
  "障害",
  "障害",
  "癌",
  "ウイルス",
  "感染",
  "治療",
  "処理",
  "処方箋",
  "薬",
  "健康管理",
  "ターミナル",
  "慢性",
  "トラウマ",
  "致命的",
  "肥満",
  "依存症",
  "伝染病",
  "パンデミック",
  "アウトブレイク",
  "検疫",
  "入院",
  "緊急",
  "病気",
  "症状",
  "副作用",
  "致命的",
  "死亡",
  "予防可能",
  "ターミナル",
  "過剰摂取",
  "安楽死",
  "乱用",
  "医療過誤",
  "自殺",
  "腐敗",
  "スキャンダル",
  "パルチザン",
  "論争",
  "贈収賄",
  "縁故主義",
  "縁故主義",
  "グラフト",
  "対立",
  "無能",
  "操作",
  "宣伝",
  "分裂的",
  "二極化",
  "抑圧",
  "独裁",
  "専制",
  "全体主義",
  "寡頭制",
  "権威主義",
  "抑制",
  "暴政",
  "アナーキー",
  "抑圧",
  "不安",
  "反乱",
  "抗議する",
  "暴動",
  "デモンストレーション",
  "不正",
  "差別",
  "人種差別",
  "性差別",
  "同性愛嫌悪",
  "外国人恐怖症",
  "偏見",
  "ファシズム",
  "過激主義",
  "過激化",
  "偽善",
  "詐欺",
  "詐欺",
  "横領",
  "偽造",
  "偽証",
  "심연",
  "불리한",
  "놀라운",
  "질리는",
  "극악한",
  "끔찍한",
  "나쁜",
  "해로운",
  "지루한",
  "대격변",
  "재앙적",
  "지저분한",
  "해를 끼치는",
  "해로운",
  "통탄 할",
  "파괴적",
  "무서운",
  "비참한",
  "무서운",
  "실행 가능",
  "치명적인",
  "무서운",
  "무서운",
  "암울한",
  "소름 끼치게 하는",
  "가혹한",
  "무서운",
  "끔찍하다",
  "적의",
  "해롭다",
  "증오하다",
  "아픈",
  "유해한",
  "듬뿍 있는",
  "악의 있는",
  "악의 있는",
  "끔찍한",
  "부정적인",
  "유해한",
  "공격",
  "괴로운",
  "가난한",
  "지겨운",
  "반발적인",
  "반란",
  "파멸적인",
  "슬퍼",
  "야만적인",
  "극심한",
  "충격적인",
  "불길한",
  "짓궂은",
  "끔찍한",
  "독성",
  "비참한",
  "불쾌한",
  "속상하다",
  "비열한",
  "몹시 나쁜",
  "사악한",
  "비참한",
  "걱정",
  "가치 없는",
  "가증스러운",
  "통렬한",
  "괴로워하다",
  "질리는",
  "극악한",
  "끔찍한",
  "해로운",
  "교전국",
  "격렬한",
  "잔혹한",
  "치열한",
  "해를 끼치는",
  "치명적인",
  "악마 같은",
  "우울하다",
  "역겹다",
  "불쾌한",
  "비참한",
  "무서운",
  "사악한",
  "반칙",
  "무서운",
  "끔찍하다",
  "해로운",
  "가슴 아프다",
  "가슴이 아프다",
  "끔찍하다",
  "적의",
  "해롭다",
  "험악한",
  "유해한",
  "참을 수 없는",
  "용납할 수 없다",
  "치명적",
  "기분 나쁜",
  "악의 있는",
  "거대한",
  "살인",
  "유해한",
  "불쾌한",
  "추악한",
  "괴로운",
  "불쌍하다",
  "날카로운",
  "악취가 나는",
  "싫은",
  "비난받을 만하다",
  "반란",
  "역겹다",
  "불길한",
  "더러운",
  "슬프게 하는",
  "맛없는",
  "괴롭히다",
  "독성",
  "비참한",
  "견딜 수 없는",
  "불쾌한",
  "성가신",
  "나쁜",
  "비열한",
  "몹시 나쁜",
  "복수심 있는",
  "사악한",
  "비참한",
  "몹시 노한",
  "강간",
  "탐닉",
  "부패",
  "폭행",
  "책략",
  "사기",
  "착취",
  "테러",
  "남용",
  "배신",
  "왕따",
  "강제",
  "범죄",
  "기만",
  "부정직",
  "증오",
  "부당",
  "압박",
  "편견",
  "노예 제도",
  "고통",
  "포학",
  "전쟁",
  "공격",
  "폭탄",
  "폭발",
  "폭행",
  "전쟁",
  "갈등",
  "테러",
  "죽었다",
  "다쳐서",
  "사상자",
  "파괴",
  "손상",
  "피해",
  "재해",
  "비극",
  "대단원",
  "파멸",
  "통증",
  "고통",
  "피해자",
  "폭행",
  "적의",
  "침략",
  "대학살",
  "학살",
  "포악",
  "대량 학살",
  "야만성",
  "공포",
  "유혈",
  "망치다",
  "파멸",
  "전멸",
  "하락",
  "죽음",
  "살인",
  "죽이는",
  "죽이다",
  "암살",
  "실행",
  "고문",
  "고동",
  "전투",
  "전투",
  "싸움",
  "고심하다",
  "전쟁",
  "포위",
  "매복",
  "습격",
  "침입",
  "직업",
  "압박",
  "억제",
  "배수량",
  "난민",
  "위기",
  "비상",
  "공황",
  "두려움",
  "공포",
  "혼돈",
  "무정부 상태",
  "위반",
  "방해",
  "상처를 입다",
  "대학살",
  "신체 상해",
  "큰 홍수",
  "부수적 인 피해",
  "폭발",
  "폭발물",
  "총격전",
  "큰 화재",
  "수류탄",
  "미사일",
  "군수품",
  "껍데기",
  "유산탄",
  "네이팜",
  "화학 무기",
  "생물학적 무기",
  "방사능",
  "핵무기",
  "독성",
  "독",
  "오염",
  "오염",
  "러시아",
  "불화",
  "체포",
  "체포",
  "폭발하다",
  "폭발하다",
  "중독",
  "탐닉",
  "맹렬한",
  "사격",
  "격추하다",
  "우크라이나",
  "팔레스타인",
  "이스리얼",
  "사수",
  "질병",
  "고소",
  "폭발",
  "총",
  "총기 폭력",
  "찌르다",
  "찔러",
  "부상",
  "부상",
  "결투",
  "결투",
  "인신매매",
  "코카인",
  "삼",
  "훔침",
  "강도",
  "폭행",
  "겁나게 하는",
  "외상",
  "찔러",
  "섹스",
  "암",
  "질병",
  "질병",
  "논란이 많은",
  "치우친",
  "논쟁",
  "법원",
  "선거",
  "선거",
  "지우다",
  "바이든",
  "진단",
  "질병",
  "병",
  "부상",
  "통증",
  "병",
  "무능",
  "무질서",
  "암",
  "바이러스",
  "전염병",
  "요법",
  "치료",
  "처방",
  "약",
  "보건 의료",
  "부패",
  "스캔들",
  "도당",
  "논쟁",
  "수회",
  "정실주의",
  "친척 편중",
  "부정 이득",
  "갈등",
  "무능력",
  "시장 조작",
  "선전",
  "분열적인",
  "양극화",
  "압박",
  "질병",
  "병",
  "부상",
  "통증",
  "병",
  "무능",
  "무질서",
  "암",
  "바이러스",
  "전염병",
  "요법",
  "치료",
  "처방",
  "약",
  "보건 의료",
  "단말기",
  "만성병 환자",
  "외상",
  "숙명",
  "비만",
  "탐닉",
  "감염병 유행",
  "감염병 세계적 유행",
  "발생",
  "건강격리",
  "입원",
  "비상",
  "병",
  "징후",
  "부작용",
  "치명적인",
  "인류",
  "예방 가능",
  "단말기",
  "과다복용",
  "안락사",
  "남용",
  "부정 요법",
  "자살",
  "부패",
  "스캔들",
  "도당",
  "논쟁",
  "수회",
  "정실주의",
  "친척 편중",
  "부정 이득",
  "갈등",
  "무능력",
  "시장 조작",
  "선전",
  "분열적인",
  "양극화",
  "압박",
  "절대권",
  "독재 정치",
  "전체주의",
  "과두 정치",
  "권위주의",
  "억압",
  "포학",
  "무정부 상태",
  "억제",
  "불안",
  "반란",
  "항의",
  "폭동",
  "데모",
  "부당",
  "차별",
  "민족적 우월감",
  "성차별",
  "호모포비아",
  "외국인 혐오증",
  "편협한 신앙",
  "파시즘",
  "극단론",
  "급진화",
  "위선",
  "스캠",
  "사기",
  "도용",
  "위조",
  "위증",
  "verschrikkelijk",
  "nadelig",
  "alarmerend",
  "vreselijk",
  "afschuwelijk",
  "vreselijk",
  "slecht",
  "vervelend",
  "saai",
  "catastrofaal",
  "catastrofaal",
  "slordig",
  "schadelijk",
  "schadelijk",
  "betreurenswaardig",
  "destructief",
  "verschrikkelijk",
  "rampzalig",
  "vreselijk",
  "afschuwelijk",
  "fataal",
  "verboden",
  "afschuwelijk",
  "grimmig",
  "griezelig",
  "wreed",
  "gruwelijk",
  "vreselijk",
  "gewelddadig",
  "kwetsend",
  "haatdragend",
  "ziek",
  "schadelijk",
  "beroerd",
  "kwaadwillig",
  "kwaadaardig",
  "vervelend",
  "negatief",
  "schadelijk",
  "offensief",
  "pijnlijk",
  "arm",
  "weerzinwekkend",
  "weerzinwekkend",
  "weerzinwekkend",
  "ruïneus",
  "verdrietig",
  "wild",
  "streng",
  "schokkend",
  "sinister",
  "hatelijk",
  "vreselijk",
  "giftig",
  "tragisch",
  "onaangenaam",
  "verontrustend",
  "verachtelijk",
  "gemeen",
  "slecht",
  "jammerlijk",
  "zorgwekkend",
  "waardeloos",
  "afschuwelijk",
  "bitter",
  "pijnlijk",
  "vreselijk",
  "afschuwelijk",
  "vreselijk",
  "onheilspellend",
  "oorlogszuchtig",
  "bitter",
  "wreed",
  "moordenaar",
  "schadelijk",
  "dodelijk",
  "demonische",
  "deprimerend",
  "verfoeilijk",
  "onaangenaam",
  "rampzalig",
  "vreselijk",
  "kwaadaardig",
  "gek",
  "afschuwelijk",
  "gruwelijk",
  "schadelijk",
  "hartverscheurend",
  "hartverscheurend",
  "gruwelijk",
  "gewelddadig",
  "kwetsend",
  "guur",
  "schadelijk",
  "onuitstaanbaar",
  "ondraaglijk",
  "dodelijk",
  "walgelijk",
  "kwaadaardig",
  "monsterlijk",
  "moorddadig",
  "schadelijk",
  "weerzinwekkend",
  "verfoeilijk",
  "pijnlijk",
  "jammerlijk",
  "scherp",
  "ranzig",
  "afstotend",
  "verwerpelijk",
  "weerzinwekkend",
  "misselijkmakend",
  "sinister",
  "smerig",
  "treurig",
  "smaakloos",
  "kwellend",
  "giftig",
  "tragisch",
  "ondraaglijk",
  "onaangenaam",
  "ergerlijk",
  "gemeen",
  "verachtelijk",
  "gemeen",
  "wraakzuchtig",
  "slecht",
  "jammerlijk",
  "toornig",
  "verkrachting",
  "verslaving",
  "corruptie",
  "geweld",
  "bedrog",
  "fraude",
  "exploitatie",
  "terrorisme",
  "misbruik",
  "bedrog",
  "pesten",
  "dwang",
  "misdaad",
  "teleurstelling",
  "oneerlijkheid",
  "haat",
  "onrecht",
  "onderdrukking",
  "vooroordeel",
  "slavernij",
  "lijden",
  "tirannie",
  "oorlog",
  "aanval",
  "bom",
  "explosie",
  "geweld",
  "oorlog",
  "conflict",
  "terrorisme",
  "gedood",
  "gewond",
  "slachtoffers",
  "verwoesting",
  "schade",
  "leed",
  "ramp",
  "tragedie",
  "catastrofe",
  "verwoesting",
  "pijn",
  "lijden",
  "slachtoffer",
  "overval",
  "vijandigheid",
  "agressie",
  "bloedbad",
  "slachten",
  "gruweldaad",
  "genocide",
  "brutaliteit",
  "verschrikking",
  "bloedvergieten",
  "vernietigen",
  "ruïne",
  "vernietiging",
  "degradatie",
  "dood",
  "moord",
  "doden",
  "doden",
  "moord",
  "executie",
  "marteling",
  "slaan",
  "gevecht",
  "strijd",
  "gevecht",
  "strijd",
  "oorlogvoering",
  "belegering",
  "hinderlaag",
  "inval",
  "invasie",
  "bezigheid",
  "onderdrukking",
  "repressie",
  "verplaatsing",
  "vluchteling",
  "crisis",
  "noodgeval",
  "paniek",
  "angst",
  "draad",
  "chaos",
  "anarchie",
  "schendt",
  "verstoord",
  "gewond",
  "bloedbad",
  "bocht",
  "ramp",
  "bijkomende schade",
  "ontploffing",
  "explosief",
  "vuurgevecht",
  "vuurzee",
  "granaat",
  "raket",
  "munitie",
  "schelp",
  "granaatscherven",
  "napalm",
  "chemisch wapen",
  "biologisch wapen",
  "straling",
  "nucleair",
  "giftig",
  "vergif",
  "besmetting",
  "vervuiling",
  "Rusland",
  "vete",
  "arresteren",
  "gearresteerd",
  "opgeblazen",
  "opblazen",
  "verslaafd",
  "verslaving",
  "woest",
  "schieten",
  "neerschieten",
  "Oekraïne",
  "Palestina",
  "is echt",
  "schutter",
  "ziekte",
  "aangeklaagd",
  "ontploffing",
  "pistool",
  "wapengeweld",
  "steken",
  "steken",
  "blessure",
  "verwondingen",
  "duel",
  "duelleren",
  "mensenhandel",
  "cocaïne",
  "marihuana",
  "diefstal",
  "beroving",
  "geweld",
  "angstaanjagend",
  "trauma",
  "steken",
  "seks",
  "kanker",
  "ziekten",
  "ziekte",
  "controverseel",
  "bevooroordeeld",
  "controverse",
  "rechtbank",
  "verkiezing",
  "verkiezingen",
  "troef",
  "biden",
  "gediagnosticeerd",
  "Ziekte",
  "Ziekte",
  "Blessure",
  "Pijn",
  "Ziekte",
  "Onbekwaamheid",
  "Wanorde",
  "Kanker",
  "Virus",
  "Infectie",
  "Behandeling",
  "Behandeling",
  "Recept",
  "Geneesmiddel",
  "Gezondheidszorg",
  "Corruptie",
  "Schandaal",
  "Partisaan",
  "Controverse",
  "Omkoping",
  "Vriendjespolitiek",
  "Nepotisme",
  "Enten",
  "Conflict",
  "Incompetentie",
  "Manipulatie",
  "Propaganda",
  "Verdeeldheid",
  "Polarisatie",
  "Onderdrukking",
  "Ziekte",
  "Ziekte",
  "Blessure",
  "Pijn",
  "Ziekte",
  "Onbekwaamheid",
  "Wanorde",
  "Kanker",
  "Virus",
  "Infectie",
  "Behandeling",
  "Behandeling",
  "Recept",
  "Geneesmiddel",
  "Gezondheidszorg",
  "Terminal",
  "Chronisch",
  "Trauma",
  "Dodelijkheid",
  "Obesitas",
  "Verslaving",
  "Epidemie",
  "Pandemie",
  "Uitbraak",
  "Quarantaine",
  "Ziekenhuisopname",
  "Noodgeval",
  "Kleine",
  "Symptoom",
  "Bijwerkingen",
  "Fataal",
  "Sterfte",
  "Te voorkomen",
  "Terminal",
  "Overdosis",
  "Euthanasie",
  "Misbruik",
  "Mispraktijken",
  "Zelfmoord",
  "Corruptie",
  "Schandaal",
  "Partisaan",
  "Controverse",
  "Omkoping",
  "Vriendjespolitiek",
  "Nepotisme",
  "Enten",
  "Conflict",
  "Incompetentie",
  "Manipulatie",
  "Propaganda",
  "Verdeeldheid",
  "Polarisatie",
  "Onderdrukking",
  "Dictatuur",
  "Autocratie",
  "Totalitarisme",
  "Oligarchie",
  "Autoritarisme",
  "Onderdrukking",
  "Tirannie",
  "Anarchie",
  "Repressie",
  "Onrust",
  "Opstand",
  "Protest",
  "Opstand",
  "Demonstratie",
  "Onrecht",
  "Discriminatie",
  "Racisme",
  "Seksisme",
  "Homofobie",
  "Xenofobie",
  "Onverdraagzaamheid",
  "Fascisme",
  "Extremisme",
  "Radicalisering",
  "Hypocrisie",
  "Scam",
  "Fraude",
  "Verduistering",
  "Namaak",
  "Meineed",
  "dipsiz",
  "olumsuz",
  "endişe verici",
  "korkunç",
  "iğrenç",
  "berbat",
  "kötü",
  "kötü",
  "sıkıcı",
  "felaket",
  "felaket",
  "berbat",
  "zarar verici",
  "zararlı",
  "içler acısı",
  "yıkıcı",
  "korkunç",
  "felaket",
  "korkunç",
  "icra edilebilir",
  "ölümcül",
  "yasaklıyor",
  "korkunç",
  "acımasız",
  "tüyler ürpertici",
  "sert",
  "korkunç",
  "berbat",
  "düşmanca",
  "acı verici",
  "nefret dolu",
  "hasta",
  "yaralayıcı",
  "berbat",
  "kötü niyetli",
  "kötü huylu",
  "edepsiz",
  "olumsuz",
  "zararlı",
  "saldırgan",
  "acı verici",
  "fakir",
  "iğrenç",
  "itici",
  "İsyankar",
  "yıkıcı",
  "üzgün",
  "vahşi",
  "haşin",
  "şok edici",
  "uğursuz",
  "kindar",
  "korkunç",
  "zehirli",
  "trajik",
  "hoş olmayan",
  "üzücü",
  "aşağılık",
  "kötü",
  "kötü",
  "kederli",
  "endişe verici",
  "değersiz",
  "iğrenç",
  "sert",
  "acı verici",
  "korkunç",
  "iğrenç",
  "berbat",
  "kötü",
  "savaşçı",
  "acı",
  "acımasız",
  "kıyasıya",
  "zarar verici",
  "ölümcül",
  "şeytani",
  "iç karartıcı",
  "iğrenç",
  "hoş olmayan",
  "felaket",
  "korkunç",
  "fenalık",
  "faul",
  "korkunç",
  "korkunç",
  "zararlı",
  "kalp kırıcı",
  "yürek parçalayıcı",
  "korkunç",
  "düşmanca",
  "acı verici",
  "kaba",
  "yaralayıcı",
  "dayanılmaz",
  "dayanılmaz",
  "öldürücü",
  "iğrenç",
  "kötü huylu",
  "canavar",
  "öldürücü",
  "zararlı",
  "iğrenç",
  "iğrenç",
  "acı verici",
  "acınası",
  "keskin",
  "kokmuş",
  "kovucu",
  "kınanılacak",
  "İsyankar",
  "mide bulandırıcı",
  "uğursuz",
  "kötü",
  "üzüntü verici",
  "tatsız",
  "eziyet verici",
  "zehirli",
  "trajik",
  "dayanılmaz",
  "hoş olmayan",
  "can sıkıcı",
  "kötü",
  "aşağılık",
  "kötü",
  "kinci",
  "kötü",
  "kederli",
  "öfkeli",
  "tecavüz",
  "bağımlılık",
  "yolsuzluk",
  "şiddet",
  "aldatma",
  "sahtekar",
  "istismar",
  "terörizm",
  "suistimal etmek",
  "ihanet",
  "zorbalık",
  "zorlama",
  "suç",
  "aldatma",
  "sahtekârlık",
  "kin",
  "adaletsizlik",
  "Baskı",
  "ön yargı",
  "kölelik",
  "cefa",
  "Zalimlik",
  "savaş",
  "saldırı",
  "bomba",
  "patlama",
  "şiddet",
  "savaş",
  "anlaşmazlık",
  "terörizm",
  "öldürüldü",
  "yaralı",
  "kayıplar",
  "yıkım",
  "zarar",
  "zarar",
  "felaket",
  "trajedi",
  "felaket",
  "yıkım",
  "ağrı",
  "cefa",
  "kurban",
  "saldırı",
  "düşmanlık",
  "saldırganlık",
  "katliam",
  "Katliam",
  "vahşet",
  "soykırım",
  "vahşet",
  "korku",
  "kan dökülmesi",
  "mahvetmek",
  "yıkım",
  "yok etme",
  "bozulma",
  "ölüm",
  "cinayet",
  "öldürmek",
  "öldürmek",
  "suikast",
  "uygulamak",
  "işkence",
  "dayak",
  "savaş",
  "savaş",
  "kavga",
  "çabalamak",
  "savaş",
  "kuşatma",
  "pusu",
  "yağma",
  "işgal",
  "meslek",
  "Baskı",
  "baskı",
  "yer değiştirme",
  "mülteci",
  "kriz",
  "acil durum",
  "panik",
  "korku",
  "dehşet",
  "kaos",
  "anarşi",
  "ihlal edildi",
  "bozuldu",
  "yaralı",
  "katliam",
  "kargaşa",
  "felaket",
  "tali hasar",
  "patlama",
  "patlayıcı",
  "itfaiye",
  "Yangın",
  "el bombası",
  "füze",
  "mühimmat",
  "kabuk",
  "şarapnel",
  "napalm",
  "kimyasal silah",
  "biyolojik silah",
  "radyasyon",
  "nükleer",
  "zehirli",
  "zehir",
  "bulaşma",
  "kirlilik",
  "Rusya",
  "kavga",
  "tutuklamak",
  "tutuklanmış",
  "havaya uçtu",
  "patlamak",
  "bağımlı",
  "bağımlılık",
  "çok öfkeli",
  "film çekmek",
  "kapat",
  "Ukrayna",
  "Filistin",
  "gerçek",
  "atıcı",
  "hastalık",
  "dava açıldı",
  "büyük patlama",
  "silah",
  "silah şiddeti",
  "bıçak",
  "bıçaklama",
  "incinme",
  "yaralanmalar",
  "düello",
  "düello",
  "kaçakçılık",
  "kokain",
  "esrar",
  "Çalınması",
  "soygun",
  "şiddet",
  "korkutucu",
  "travma",
  "bıçaklama",
  "seks",
  "kanser",
  "hastalıklar",
  "hastalık",
  "tartışmalı",
  "önyargılı",
  "tartışma",
  "mahkeme",
  "seçim",
  "seçimler",
  "koz",
  "biden",
  "teşhis konuldu",
  "Hastalık",
  "Hastalık",
  "Yaralanma",
  "Ağrı",
  "Hastalık",
  "Engellilik",
  "Düzensizlik",
  "Kanser",
  "Virüs",
  "Enfeksiyon",
  "Terapi",
  "Tedavi",
  "Reçete",
  "İlaç",
  "Sağlık hizmeti",
  "Yolsuzluk",
  "Skandal",
  "Partizan",
  "Tartışma",
  "Rüşvet",
  "Kayıtçılık",
  "Kayırmacılık",
  "Aşı",
  "Anlaşmazlık",
  "Beceriksizlik",
  "Manipülasyon",
  "Propaganda",
  "Bölücü",
  "Polarizasyon",
  "Baskı",
  "Hastalık",
  "Hastalık",
  "Yaralanma",
  "Ağrı",
  "Hastalık",
  "Engellilik",
  "Düzensizlik",
  "Kanser",
  "Virüs",
  "Enfeksiyon",
  "Terapi",
  "Tedavi",
  "Reçete",
  "İlaç",
  "Sağlık hizmeti",
  "Terminal",
  "Kronik",
  "Travma",
  "Ölüm",
  "Obezite",
  "Bağımlılık",
  "Epidemi",
  "Pandemi",
  "Salgın",
  "Karantina",
  "Hastaneye yatış",
  "Acil durum",
  "Hastalık",
  "Belirti",
  "Yan etkiler",
  "Ölümcül",
  "Ölüm",
  "Önlenebilir",
  "Terminal",
  "Aşırı doz",
  "Ötenazi",
  "Suistimal etmek",
  "Yanlış uygulama",
  "İntihar",
  "Yolsuzluk",
  "Skandal",
  "Partizan",
  "Tartışma",
  "Rüşvet",
  "Kayıtçılık",
  "Kayırmacılık",
  "Aşı",
  "Anlaşmazlık",
  "Beceriksizlik",
  "Manipülasyon",
  "Propaganda",
  "Bölücü",
  "Polarizasyon",
  "Baskı",
  "Diktatörlük",
  "Otokrasi",
  "Totaliterlik",
  "Oligarşik",
  "Otoriterlik",
  "Bastırma",
  "Zalimlik",
  "Anarşi",
  "Baskı",
  "Huzursuzluk",
  "İsyan",
  "Protesto",
  "İsyan",
  "Gösteri",
  "Adaletsizlik",
  "Ayrımcılık",
  "Irkçılık",
  "Cinsiyetçilik",
  "Homofobi",
  "Yabancı düşmanlığı",
  "Bağnazlık",
  "Faşizm",
  "Aşırılık",
  "Radikalleşme",
  "İkiyüzlülük",
  "Sahtekarlık",
  "Sahtekar",
  "Zimmete para geçirme",
  "Sahtecilik",
  "Yalancı şahitlik",
  "sopimaton",
  "haitallinen",
  "hälyttävä",
  "hirvittävää",
  "hirvittävä",
  "kauhea",
  "huono",
  "kohtalokas",
  "tylsä",
  "kataklysminen",
  "katastrofaalinen",
  "kurja",
  "vahingollista",
  "haitallinen",
  "valitettavaa",
  "tuhoisa",
  "hirveä",
  "tuhoisa",
  "hirvittävää",
  "inhottava",
  "kuolettava",
  "kiellettävä",
  "kamala",
  "synkkä",
  "kammottava",
  "kova",
  "hirvittävää",
  "kamala",
  "vihamielinen",
  "loukkaavaa",
  "vihallinen",
  "sairas",
  "vahingollinen",
  "kurma",
  "pahantahtoinen",
  "pahanlaatuinen",
  "ilkeä",
  "negatiivinen",
  "haitallinen",
  "loukkaava",
  "tuskallista",
  "huono",
  "inhottava",
  "vastenmielinen",
  "vastenmielinen",
  "tuhoisa",
  "surullinen",
  "villi",
  "vaikea",
  "järkyttävä",
  "synkkä",
  "ilkeä",
  "kauhea",
  "myrkyllinen",
  "traaginen",
  "epämiellyttävä",
  "järkyttävä",
  "ilkeä",
  "häijy",
  "paha",
  "surkea",
  "huolestuttava",
  "arvoton",
  "iljettävä",
  "kiihkeä",
  "tuskallinen",
  "hirvittävää",
  "hirvittävä",
  "kauhea",
  "paali",
  "sodallinen",
  "katkera",
  "julma",
  "murhamies",
  "vahingollista",
  "tappava",
  "demoninen",
  "masentava",
  "inhottava",
  "vastenmielinen",
  "tuhoisa",
  "hirvittävää",
  "paha",
  "vika",
  "kamala",
  "hirvittävä",
  "haitallinen",
  "sydäntäsärkevä",
  "sydäntäsärkevä",
  "hirvittävää",
  "vihamielinen",
  "loukkaavaa",
  "kolea",
  "vahingollinen",
  "sietämätön",
  "siestämätön",
  "tappava",
  "inhottava",
  "pahanlaatuinen",
  "hirviömäinen",
  "murhaaja",
  "haitallinen",
  "vastenmielinen",
  "ilkeä",
  "tuskallista",
  "säälittävä",
  "pistävä",
  "eltaantunut",
  "hylkivä",
  "tuomittavaa",
  "vastenmielinen",
  "sairaus",
  "synkkä",
  "suruinen",
  "surullinen",
  "mauton",
  "piinaa",
  "myrkyllinen",
  "traaginen",
  "sietämätön",
  "epämiellyttävä",
  "hauska",
  "ilkeä",
  "ilkeä",
  "häijy",
  "kostonhimoinen",
  "paha",
  "surkea",
  "vihainen",
  "raiskata",
  "riippuvuus",
  "korruptio",
  "väkivalta",
  "petos",
  "petos",
  "hyödyntäminen",
  "terrorismi",
  "väärinkäyttö",
  "petos",
  "kiusaaminen",
  "pakottaminen",
  "rikos",
  "petos",
  "epärehellisyys",
  "viha",
  "epäoikeudenmukaisuus",
  "sorto",
  "ennakkoluulo",
  "orjuus",
  "kärsimystä",
  "tyrannia",
  "sota",
  "hyökkäys",
  "pommi",
  "räjähdys",
  "väkivalta",
  "sota",
  "konflikti",
  "terrorismi",
  "tapattu",
  "loukkaantunut",
  "uhrit",
  "tuho",
  "vahingoittaa",
  "vahingoittaa",
  "katastrofi",
  "tragedia",
  "katastrofi",
  "tuho",
  "kipu",
  "kärsimystä",
  "uhri",
  "hyökkäys",
  "vihamielisyys",
  "aggressio",
  "verilöyly",
  "teurastus",
  "julmuus",
  "kansanmurha",
  "raakuus",
  "kauhu",
  "verenvuodatus",
  "pilata",
  "tuho",
  "tuhoaminen",
  "hajoaminen",
  "kuolema",
  "murhata",
  "tappaa",
  "tappaa",
  "murha",
  "teloitus",
  "kiduttaa",
  "lyöminen",
  "taistelu",
  "taistelu",
  "taistella",
  "kamppailu",
  "sodankäynti",
  "piiritys",
  "väijytys",
  "ryöstö",
  "maahantunkeutuminen",
  "ammatti",
  "sorto",
  "sortoa",
  "siirtymä",
  "pakolainen",
  "kriisi",
  "hätä",
  "paniikki",
  "pelko",
  "pelko",
  "kaaos",
  "anarkia",
  "rikottu",
  "häiriintynyt",
  "haavoittunut",
  "verilöyly",
  "sekasorto",
  "mullistus",
  "siviiliuhreja",
  "räjähdys",
  "räjähtävä",
  "palotaistelu",
  "palo",
  "kranaatti",
  "ohjus",
  "ammus",
  "kuori",
  "sirpaleet",
  "napalm",
  "kemiallinen ase",
  "biologinen ase",
  "säteily",
  "ydin",
  "myrkyllinen",
  "myrkyttää",
  "saastuminen",
  "saaste",
  "Venäjä",
  "vihanpito",
  "pidätys",
  "pidätetty",
  "räjähti",
  "räjähtää",
  "riippuvainen",
  "riippuvuus",
  "raivoissaan",
  "ampua",
  "ampua alas",
  "Ukraina",
  "palestiina",
  "on todellinen",
  "ampuja",
  "sairaus",
  "haastettu oikeuteen",
  "räjähdys",
  "ase",
  "aseväkivalta",
  "puukottaa",
  "puukotus",
  "vamma",
  "vammat",
  "kaksintaistelu",
  "kaksintaistelu",
  "salakauppa",
  "kokaiini",
  "marihuana",
  "varkaus",
  "ryöstö",
  "väkivalta",
  "kauhistuttava",
  "trauma",
  "puukotus",
  "seksiä",
  "syöpä",
  "taudit",
  "sairaus",
  "kiistanalainen",
  "puolueellinen",
  "kiista",
  "tuomioistuin",
  "vaalit",
  "vaalit",
  "valtti",
  "biden",
  "diagnoosoitu",
  "sairaus",
  "Sairaus",
  "vamma",
  "Kipu",
  "sairaus",
  "vammaisuus",
  "Häiriö",
  "Syöpä",
  "Virus",
  "infektio",
  "terapia",
  "Hoito",
  "Resepti",
  "Lääke",
  "Terveydenhuolto",
  "Korruptio",
  "Skandaali",
  "Puolueellinen",
  "kiista",
  "Lahjonta",
  "Kronismi",
  "Nepotismi",
  "Graft",
  "Konflikti",
  "Epäpätevyys",
  "Manipulointi",
  "Propaganda",
  "Jakaja",
  "Polarisaatio",
  "sorto",
  "sairaus",
  "Sairaus",
  "vamma",
  "Kipu",
  "sairaus",
  "vammaisuus",
  "Häiriö",
  "Syöpä",
  "Virus",
  "infektio",
  "terapia",
  "Hoito",
  "Resepti",
  "Lääke",
  "Terveydenhuolto",
  "Terminaali",
  "Krooninen",
  "Trauma",
  "Kuolemallisuus",
  "Liikalihavuus",
  "Riippuvuus",
  "Epideeminen",
  "Pandeeminen",
  "Taudin puhkeaminen",
  "Karanteeni",
  "sairaalahoito",
  "Hätä",
  "Sairaus",
  "Oire",
  "Sivuvaikutukset",
  "kohtalokas",
  "Kuolleisuus",
  "Estettävissä oleva",
  "Terminaali",
  "Yliannostus",
  "Eutanasia",
  "Väärinkäyttö",
  "Väärinkäyttö",
  "Itsemurha",
  "Korruptio",
  "Skandaali",
  "Puolueellinen",
  "kiista",
  "Lahjonta",
  "Kronismi",
  "Nepotismi",
  "Graft",
  "Konflikti",
  "Epäpätevyys",
  "Manipulointi",
  "Propaganda",
  "Jakaja",
  "Polarisaatio",
  "sorto",
  "Diktatuuri",
  "Autokratia",
  "Totalitarismi",
  "oligarkia",
  "Auktoritaarisuus",
  "Tukahduttaminen",
  "Tyrania",
  "Anarkia",
  "Tukahduttaminen",
  "levottomuus",
  "Kapina",
  "Protesti",
  "Mellakka",
  "Esittely",
  "Epäoikeudenmukaisuus",
  "syrjintä",
  "Rasismi",
  "Seksismi",
  "homofobia",
  "muukalaisviha",
  "Bigotismi",
  "fasismi",
  "Extremismi",
  "radikalisoituminen",
  "Tekopyhyys",
  "Huijaus",
  "petos",
  "Kavallus",
  "Väärennös",
  "Väärä vala",
  "bezdenny",
  "niekorzystny",
  "niepokojący",
  "przerażający",
  "okrutny",
  "okropny",
  "zły",
  "zgubny",
  "nudny",
  "kataklizm",
  "katastrofalny",
  "kiepski",
  "szkodliwy",
  "szkodliwy",
  "opłakany",
  "destrukcyjny",
  "straszny",
  "fatalny",
  "straszny",
  "skandaliczny",
  "fatalny",
  "groźny",
  "upiorny",
  "ponury",
  "przerażający",
  "szorstki",
  "przerażający",
  "straszny",
  "wrogi",
  "bolesny",
  "nienawistny",
  "chory",
  "szkodliwy",
  "parszywy",
  "wrogi",
  "złośliwy",
  "paskudny",
  "negatywny",
  "szkodliwy",
  "ofensywa",
  "bolesny",
  "słaby",
  "odrażający",
  "odpychający",
  "oburzający",
  "rujnujący",
  "smutny",
  "okrutny",
  "ciężki : silny",
  "wstrząsający",
  "złowrogi",
  "złośliwy",
  "straszny",
  "toksyczny",
  "tragiczny",
  "nieprzyjemny",
  "denerwujący",
  "podły",
  "nikczemny",
  "podły",
  "fatalny",
  "niepokojący",
  "Bezwartościowy",
  "wstrętny",
  "zjadliwy",
  "bolesny",
  "przerażający",
  "okrutny",
  "okropny",
  "zgubny",
  "wojujący",
  "gorzki",
  "okrutny",
  "nożownik",
  "szkodliwy",
  "śmiertelnie",
  "demoniczny",
  "przygnębiający",
  "odrażający",
  "nieprzyjemny",
  "fatalny",
  "straszny",
  "zło",
  "faul",
  "upiorny",
  "makabryczny",
  "szkodliwy",
  "bolesny",
  "rozdzierający",
  "przerażający",
  "wrogi",
  "bolesny",
  "surowy",
  "szkodliwy",
  "nieznośny",
  "nie do zniesienia",
  "śmiertelny",
  "odrażający",
  "złośliwy",
  "potworny",
  "morderczy",
  "szkodliwy",
  "obrzydliwy",
  "wstrętny",
  "bolesny",
  "żałosny",
  "ostry",
  "zjełczały",
  "odpychający",
  "naganny",
  "oburzający",
  "obrzydliwy",
  "złowrogi",
  "brudny",
  "smutny",
  "bez smaku",
  "dręczenie",
  "toksyczny",
  "tragiczny",
  "nie do zniesienia",
  "nieprzyjemny",
  "irytujący",
  "podstępny",
  "podły",
  "nikczemny",
  "mściwy",
  "podły",
  "fatalny",
  "gniewny",
  "rzepak",
  "uzależnienie",
  "korupcja",
  "przemoc",
  "oszustwo",
  "oszustwo",
  "eksploatacja",
  "terroryzm",
  "nadużywać",
  "zdrada",
  "zastraszanie",
  "przymus",
  "przestępczość",
  "oszustwo",
  "nieuczciwość",
  "nienawiść",
  "niesprawiedliwość",
  "ucisk",
  "uprzedzenie",
  "niewolnictwo",
  "cierpienie",
  "tyrania",
  "wojna",
  "atak",
  "bomba",
  "eksplozja",
  "przemoc",
  "wojna",
  "konflikt",
  "terroryzm",
  "zabity",
  "ranny",
  "ofiary wypadku",
  "zniszczenie",
  "szkoda",
  "szkoda",
  "katastrofa",
  "tragedia",
  "katastrofa",
  "dewastacja",
  "ból",
  "cierpienie",
  "ofiara",
  "napaść",
  "wrogość",
  "agresja",
  "masakra",
  "ubój",
  "okrucieństwo",
  "ludobójstwo",
  "brutalność",
  "przerażenie",
  "rozlew krwi",
  "ruina",
  "ruina",
  "unicestwienie",
  "degradacja",
  "śmierć",
  "morderstwo",
  "zabicie",
  "zabić",
  "zamach",
  "wykonanie",
  "torturować",
  "bicie",
  "walka",
  "bitwa",
  "walka",
  "walka",
  "działania wojenne",
  "oblężenie",
  "zasadzka",
  "nalot",
  "inwazja",
  "zawód",
  "ucisk",
  "represja",
  "przemieszczenie",
  "uchodźca",
  "kryzys",
  "nagły wypadek",
  "panika",
  "strach",
  "strach",
  "chaos",
  "anarchia",
  "naruszony",
  "zakłócony",
  "ranny",
  "rzeź",
  "okaleczenie",
  "kataklizm",
  "szkody kolateralne",
  "detonacja",
  "materiał wybuchowy",
  "stój ogniowy",
  "pożar",
  "granat",
  "pocisk",
  "amunicja",
  "powłoka",
  "szrapnel",
  "napalm",
  "Broń chemiczna",
  "broń biologiczna",
  "promieniowanie",
  "jądrowy",
  "toksyczny",
  "zatruć",
  "zanieczyszczenie",
  "zanieczyszczenie",
  "Rosja",
  "wojna",
  "aresztować",
  "aresztowany",
  "wybuchł",
  "wysadzić w powietrze",
  "uzależniony",
  "uzależnienie",
  "wściekły",
  "strzelać",
  "zestrzelić",
  "Ukraina",
  "Palestyna",
  "jest realne",
  "strzelec",
  "choroba",
  "pozwany",
  "podmuch",
  "pistolet",
  "przemoc z użyciem broni",
  "zasztyletować",
  "dźganie",
  "obrażenia",
  "kontuzje",
  "pojedynek",
  "pojedynki",
  "handel",
  "kokaina",
  "marihuana",
  "kradzież",
  "rozbój",
  "przemoc",
  "przerażający",
  "uraz",
  "dźganie",
  "seks",
  "rak",
  "choroby",
  "choroba",
  "kontrowersyjny",
  "stronniczy",
  "spór",
  "sąd",
  "wybór",
  "wybory",
  "atut",
  "Biden",
  "zdiagnozowano",
  "Choroba",
  "Choroba",
  "Obrażenia",
  "Ból",
  "Choroba",
  "Inwalidztwo",
  "Nieład",
  "Rak",
  "Wirus",
  "Infekcja",
  "Terapia",
  "Leczenie",
  "Recepta",
  "Medycyna",
  "Opieka zdrowotna",
  "Korupcja",
  "Skandal",
  "Partyzant",
  "Spór",
  "Łapówkarstwo",
  "kumoterstwo",
  "Nepotyzm",
  "Zaszczepić",
  "Konflikt",
  "Niekompetencja",
  "Manipulacja",
  "Propaganda",
  "Dzielący",
  "Polaryzacja",
  "Ucisk",
  "Choroba",
  "Choroba",
  "Obrażenia",
  "Ból",
  "Choroba",
  "Inwalidztwo",
  "Nieład",
  "Rak",
  "Wirus",
  "Infekcja",
  "Terapia",
  "Leczenie",
  "Recepta",
  "Medycyna",
  "Opieka zdrowotna",
  "Terminal",
  "Chroniczny",
  "Uraz",
  "Śmiertelność",
  "Otyłość",
  "Uzależnienie",
  "Epidemia",
  "Pandemia",
  "Wybuch",
  "Kwarantanna",
  "Hospitalizacja",
  "Nagły wypadek",
  "Dolegliwość",
  "Objaw",
  "Skutki uboczne",
  "Fatalny",
  "Śmiertelność",
  "Możliwe do uniknięcia",
  "Terminal",
  "Przedawkować",
  "Eutanazja",
  "Nadużywać",
  "Zaniedbanie",
  "Samobójstwo",
  "Korupcja",
  "Skandal",
  "Partyzant",
  "Spór",
  "Łapówkarstwo",
  "kumoterstwo",
  "Nepotyzm",
  "Zaszczepić",
  "Konflikt",
  "Niekompetencja",
  "Manipulacja",
  "Propaganda",
  "Dzielący",
  "Polaryzacja",
  "Ucisk",
  "Dyktatura",
  "Autokracja",
  "Totalitaryzm",
  "Oligarchia",
  "Autorytaryzm",
  "Tłumienie",
  "Tyrania",
  "Anarchia",
  "Represja",
  "Niepokój",
  "Bunt",
  "Protest",
  "Zamieszki",
  "Demonstracja",
  "Niesprawiedliwość",
  "Dyskryminacja",
  "Rasizm",
  "Seksizm",
  "Homofobia",
  "Ksenofobia",
  "Bigoteria",
  "Faszyzm",
  "Ekstremizm",
  "Radykalizacja",
  "Hipokryzja",
  "Oszustwo",
  "Oszustwo",
  "Sprzeniewierzenie",
  "Fałszerstwo",
  "Krzywoprzysięstwo",
  "afgrundsdyb",
  "ugunstig",
  "alarmerende",
  "rystende",
  "grusomt",
  "forfærdelig",
  "dårligt",
  "banende",
  "kedelig",
  "katalysmisk",
  "katastrofale",
  "krumme",
  "skadende",
  "skadelige",
  "beklageligt",
  "Destruktiv",
  "dire",
  "katastrofal",
  "forfærdelig",
  "udtalende",
  "fatal",
  "forbyder",
  "uhyggelig",
  "barsk",
  "grimt",
  "barske",
  "rædselsfuldt",
  "forfærdelig",
  "fjendtlig",
  "skadende",
  "hadefuld",
  "syg",
  "skadende",
  "elendig",
  "ondsindet",
  "ondartet",
  "grim",
  "negativ",
  "skadelig",
  "offensiv",
  "smertefuld",
  "fattige",
  "afvisende",
  "frastødende",
  "oprørende",
  "ødelæggende",
  "trist",
  "brutal",
  "alvorlig",
  "chokerende",
  "uhyggelig",
  "ondsindet",
  "forfærdeligt",
  "giftig",
  "tragisk",
  "ubehagelig",
  "oprørende",
  "modfærdig",
  "skurkagtig",
  "ond",
  "kedeligt",
  "bekymrende",
  "værdiløs",
  "afskyelige",
  "skærende",
  "pinefuldt",
  "rystende",
  "grusomt",
  "forfærdelig",
  "uhyggelig",
  "krigsførende",
  "bitter",
  "grusom",
  "skærende",
  "skadende",
  "dødelig",
  "dæmonisk",
  "deprimerende",
  "afskyelig",
  "uenig",
  "katastrofal",
  "forfærdelig",
  "ond",
  "fejl",
  "uhyggelig",
  "grumme",
  "skadelig",
  "hjerteskærende",
  "hjerteskærende",
  "rædselsvækkende",
  "fjendtlig",
  "skadende",
  "inclement",
  "skadende",
  "ulidelig",
  "utålelig",
  "dødbringende",
  "afskyelig",
  "ondartet",
  "monstrøs",
  "morderisk",
  "skadelig",
  "modbydeligt",
  "odiøs",
  "smertefuld",
  "ærgerligt",
  "stikkende",
  "harsk",
  "frastødende",
  "forkasteligt",
  "oprørende",
  "sygelig",
  "uhyggelig",
  "slidt",
  "sorgelig",
  "smagløs",
  "pinende",
  "giftig",
  "tragisk",
  "uudholdelig",
  "ubehagelig",
  "ærgerlig",
  "ondskabsfuld",
  "modfærdig",
  "skurkagtig",
  "hævngerrig",
  "ond",
  "kedeligt",
  "vrede",
  "voldtage",
  "afhængighed",
  "korruption",
  "vold",
  "bedrag",
  "svig",
  "udnyttelse",
  "terrorisme",
  "misbrug",
  "forræderi",
  "mobning",
  "tvang",
  "forbrydelse",
  "bedrag",
  "uærlighed",
  "had",
  "uretfærdighed",
  "undertrykkelse",
  "fordomme",
  "slaveri",
  "lidelse",
  "tyranni",
  "krig",
  "angreb",
  "bombe",
  "eksplosion",
  "vold",
  "krig",
  "konflikt",
  "terrorisme",
  "dræbt",
  "skadet",
  "tilskadekomne",
  "ødelæggelse",
  "skade",
  "skade",
  "katastrofe",
  "tragedie",
  "katastrofe",
  "ødelæggelse",
  "smerte",
  "lidelse",
  "offer",
  "angreb",
  "fjendtlighed",
  "aggression",
  "massakre",
  "slagte",
  "grusomhed",
  "folkedrab",
  "brutalitet",
  "rædsel",
  "blodsudgydelse",
  "ruin",
  "ødelæggelse",
  "tilintetgørelse",
  "nedbrydning",
  "død",
  "mord",
  "drab",
  "dræbe",
  "snigmord",
  "udførelse",
  "tortur",
  "prygl",
  "bekæmpe",
  "kamp",
  "kæmpe",
  "kamp",
  "krigsførelse",
  "belejring",
  "baghold",
  "raid",
  "invasion",
  "beskæftigelse",
  "undertrykkelse",
  "undertrykkelse",
  "forskydning",
  "flygtning",
  "krise",
  "nødsituation",
  "panik",
  "frygt",
  "rædsel",
  "kaos",
  "anarki",
  "overtrådt",
  "forstyrret",
  "såret",
  "blodbad",
  "kaos",
  "katastrofe",
  "indirekte skader",
  "detonation",
  "eksplosiv",
  "brandkamp",
  "brand",
  "granat",
  "missil",
  "munition",
  "skal",
  "skrapnel",
  "napalm",
  "kemiske våben",
  "biologisk våben",
  "stråling",
  "atomisk",
  "giftig",
  "gift",
  "forurening",
  "forurening",
  "rusland",
  "fejde",
  "anholdelse",
  "anholdt",
  "sprang i luften",
  "sprænge i luften",
  "afhængig",
  "afhængighed",
  "rasende",
  "skyde",
  "skyde ned",
  "ukraine",
  "palæstina",
  "er ægte",
  "skytte",
  "sygdom",
  "sagsøgt",
  "sprængning",
  "pistol",
  "våbenvold",
  "dolke",
  "stikker",
  "skade",
  "skader",
  "duel",
  "duellering",
  "handel",
  "kokain",
  "marihuana",
  "tyveri",
  "røveri",
  "vold",
  "frygtindgydende",
  "trauma",
  "stikker",
  "køn",
  "Kræft",
  "sygdomme",
  "sygdom",
  "kontroversiel",
  "partisk",
  "kontrovers",
  "ret",
  "valg",
  "valg",
  "trumf",
  "biden",
  "diagnosticeret",
  "Sygdom",
  "Sygdom",
  "Skade",
  "Smerte",
  "Sygdom",
  "handicap",
  "Sygdom",
  "Kræft",
  "Virus",
  "Infektion",
  "Terapi",
  "Behandling",
  "Recept",
  "Medicin",
  "Sundhedspleje",
  "Korruption",
  "Skandale",
  "partisan",
  "Kontrovers",
  "Bestikkelse",
  "Kronyisme",
  "Nepotisme",
  "Pode",
  "Konflikt",
  "Inkompetence",
  "Manipulation",
  "Propaganda",
  "Splittende",
  "Polarisering",
  "Undertrykkelse",
  "Sygdom",
  "Sygdom",
  "Skade",
  "Smerte",
  "Sygdom",
  "handicap",
  "Sygdom",
  "Kræft",
  "Virus",
  "Infektion",
  "Terapi",
  "Behandling",
  "Recept",
  "Medicin",
  "Sundhedspleje",
  "Terminal",
  "Kronisk",
  "Trauma",
  "Dødsfald",
  "Fedme",
  "Afhængighed",
  "Epidemi",
  "Pandemi",
  "Udbrud",
  "Karantæne",
  "Hospitalisering",
  "Nødsituation",
  "sygdom",
  "Symptom",
  "Bivirkninger",
  "Fatal",
  "Dødelighed",
  "Forebyggelig",
  "Terminal",
  "Overdosis",
  "Aktiv dødshjælp",
  "Misbrug",
  "Uredelighed",
  "Selvmord",
  "Korruption",
  "Skandale",
  "partisan",
  "Kontrovers",
  "Bestikkelse",
  "Kronyisme",
  "Nepotisme",
  "Pode",
  "Konflikt",
  "Inkompetence",
  "Manipulation",
  "Propaganda",
  "Splittende",
  "Polarisering",
  "Undertrykkelse",
  "Diktatur",
  "Autokrati",
  "Totalitarisme",
  "Oligarki",
  "Autoritarisme",
  "Undertrykkelse",
  "Tyranni",
  "Anarki",
  "Undertrykkelse",
  "Uro",
  "Opstand",
  "Protest",
  "Optøjer",
  "Demonstration",
  "Uretfærdighed",
  "Forskelsbehandling",
  "Racisme",
  "Sexisme",
  "Homofobi",
  "Xenofobi",
  "Fordomme",
  "Fascisme",
  "Ekstremisme",
  "Radikalisering",
  "Hykleri",
  "Fup",
  "Svig",
  "Underslæb",
  "Forfalskning",
  "Mened",
];
