import React from "react";

const Privacy = () => {
	return (
		<div className="pt-[50px]">
			<div className="mx-auto w-full max-w-7xl px-5 pb-16 md:px-10">
				<div className="col-span-8 rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow pb-10">
					<p>Please find privacy URL here:</p>
					<a
						className="text-gray-600 underline"
						href="https://www.termsfeed.com/live/dd4a2d99-ace3-4a21-b86d-990e012e8f93"
						target="_blank"
						rel="noreferrer"
					>
						Privacy & Policy
					</a>
				</div>
			</div>
		</div>
	);
};

export default Privacy;
