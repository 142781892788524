import { useAuth } from "hooks";
import React, { useEffect, useState } from "react";
import { languages, storageKeys } from "utils/constants";
import { storeData } from "utils/functions";

const SingleArticle = ({
  article,
  category,
  titleRedirects = false,
  showInsight,
}) => {
  const { setAppLocale, isDefaultLang } = useAuth();
  const languageOptions = Object.keys(languages).map((langCode) => ({
    label: languages[langCode].name,
    value: languages[langCode].code,
  }));

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const isExpandable = (article.description || article.content)?.length > 150;
  const publisedDate = new Date(article?.publishedAt);

  const contentToShow = expanded
    ? article.description || article.content
    : (article.description || article.content).slice(0, 150);

  useEffect(() => {
    const browserLanguage =
      navigator?.userLanguage?.substring(0, 2) ||
      navigator?.language?.substring(0, 2);
    const isExist = languageOptions?.find(
      (lang) => lang?.value === browserLanguage
    );
    if (isExist && isDefaultLang) {
      storeData(storageKeys?.PREFERRED_LANGUAGE, isExist?.value);
      setAppLocale(isExist?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 rounded-md border border-solid border-[#cdcdcd] px-4 py-8 md:p-0">
      <img
        src={
          article.image ||
          "https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a94016de6cf90_Rectangle%2035.svg"
        }
        alt=""
        className="h-60 object-cover"
      />
      <div className="px-6 py-4">
        <div className="mb-4 w-fit text-center text-sm font-semibold uppercase text-[white] bg-primary rounded-full p-[0.3rem] pl-[1rem] pr-[1rem] ">
          {category}
        </div>
        {showInsight && (
          <button
            onClick={showInsight}
            className="block mb-2 mt-[-12px] ml-auto bg-primary text-white rounded-lg p-1 text-sm"
          >
            Insights
          </button>
        )}
        {titleRedirects ? (
          <a
            href={article.url}
            target="_blank"
            rel="noreferrer"
            className="mb-4 text-xl font-semibold"
          >
            {article?.title}
          </a>
        ) : (
          <p className="mb-4 text-xl font-semibold">{article?.title}</p>
        )}
        <p className="mb-6 text-sm text-[#636262] sm:text-base lg:mb-8">
          {contentToShow}
          {!expanded && isExpandable ? "..." : ""} {"   "}
          {isExpandable && (
            <button className="text-primary" onClick={toggleExpanded}>
              {expanded ? "Show less" : "Show more"}
            </button>
          )}
        </p>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-600 mr-2 flex-shrink-0">
            {article?.source?.name}
          </span>
          <span className="text-sm text-gray-600 mr-2 flex-shrink-0">
            {publisedDate?.toDateString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SingleArticle;
