import { AuthContext } from 'contexts/AuthProvider'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'flowbite-react';
import { getData, storeData } from 'utils/functions';
import { categories, storageKeys } from 'utils/constants';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useTranslation } from 'hooks';
import texts from 'utils/languages/texts';
import Select from 'react-select'
import { auth, Firestore } from 'firebase_';
import toast from 'react-hot-toast';


const GoodNewsPreferenceModal = () => {
    const { renderText: _ } = useTranslation()
    const user = auth.currentUser
    const { setShowPreferencesModal, showPreferencesModal: { visible, isUpdate } } = useContext(AuthContext)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const categoryOptions = categories.map((category) => ({ value: category.category_type, label: _(category.name.toLowerCase()) }))


    const checkIfGuestUserHasEmptyPreferences = async () => {
        try {
            const preferences = await getData(storageKeys.GOODNEWS_PREFERENCES)
            if (!preferences || (preferences && !preferences.length)) {
                setShowPreferencesModal({ visible: true, isUpdate: false })
                return
            }
            const previouslySelectedCategories = categoryOptions.filter(cat => preferences.includes(cat.value))
            if (previouslySelectedCategories.length) {
                setSelectedCategories(previouslySelectedCategories)
            }
            setSelectedCategories(preferences)
        }
        catch (e) {
            console.log(e)
        }
    }

    const checkIfUserHasEmptyPreferences = async () => {
        if (user?.isAnonymous || !user) {
            checkIfGuestUserHasEmptyPreferences()
            return
        }
        if (!user.displayName) {
            return
        }
        try {
            const userDocRef = doc(Firestore, "Users/", user.displayName)
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                const preferences = userData.preferences || []
                const previouslySelectedCategories = categoryOptions.filter(cat => preferences.includes(cat.value))
                if (previouslySelectedCategories.length) {
                    setSelectedCategories(previouslySelectedCategories)
                }
                else {
                    setShowPreferencesModal({ visible: true, isUpdate: false })
                }
            } else {
                const username = user.displayName
                const userCheck = await getDoc(doc(Firestore, "Users", username))
                if (!userCheck.exists()) {
                    await setDoc(doc(Firestore, "Users/", username), {
                        isSubscribed: false, timeLeft: new Date()
                    });
                }
                setShowPreferencesModal({ visible: true, isUpdate: false })
            }

        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (user) {
            if (isUpdate) {
                if (visible) {
                    checkIfUserHasEmptyPreferences()
                    return
                }
            }
            else {
                checkIfUserHasEmptyPreferences()
            }
        }
        if (!visible && selectedCategories.length) {
            setSelectedCategories([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, user, isUpdate])

    const onExit = (force = false) => {
        if (!selectedCategories.length) {
            if (error) {
                setShowPreferencesModal({ isUpdate: false, visible: false })
                return
            }
            else {
                toast.error("Please select at least one category to proceed")
                return
            }
        }
        if (saving && !force) {
            return
        }
        setSelectedCategories([])
        setShowPreferencesModal({ isUpdate: false, visible: false })
    }

    const saveGuestUserPreferences = async () => {
        try {
            await storeData(storageKeys.GOODNEWS_PREFERENCES, selectedCategories.map(cat => cat.value))
            setSaving(false)
            onExit(true)
            toast.success("Your preferences have been updated!")
        }
        catch (e) {
            toast.error("Please try again later")
            setSaving(false)
        }
    }

    const onSave = async () => {
        if (!selectedCategories?.length) {
            toast.error("Please select at least one category")
            return
        }
        setSaving(true)
        if (user?.isAnonymous || !user) {
            saveGuestUserPreferences()
            return
        }
        try {
            await updateDoc(doc(Firestore, "Users/", user.displayName), { preferences: selectedCategories.map(_ => _.value) })
            setSaving(false)
            toast.success("Your preferences have been updated!")
            onExit(true)
        }
        catch (e) {
            toast.error("There was an error")
            setSaving(false)
            setError(true)
        }
    }

    return (
        <Modal show={visible} onClose={() => setShowPreferencesModal({ visible: false, isUpdate: false })}>
            <Modal.Header>{_(texts.select_goodnews_preferences)}</Modal.Header>
            <div className="px-6 py-5">
                <Select
                    isMulti
                    value={selectedCategories}
                    options={categoryOptions}
                    isClearable
                    onChange={setSelectedCategories}
                />
            </div>
            <Modal.Body>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSave} disabled={saving} isProcessing={saving}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default GoodNewsPreferenceModal