import React from "react";
import logo from "../images/logo.jpeg";
import { Link } from "react-router-dom";
import appRoutes from "utils/routing";

const Logo = () => {
  return (
    <Link to={appRoutes.communityposts} className="flex flex-col space-y-1">
      <div className="flex items-center justify-center">
        <img src={logo} className="h-[20px] w-[20px]" alt="logo" />
      </div>
      <div className="block text-white font-bold text-3xl" href="/">
        <h1 className="md:text-4xl">GoodNews</h1>
      </div>
    </Link>
  );
};

export default Logo;
