import React from "react";
import logo from "images/logo.jpeg"
import { Link } from "react-router-dom";
import appRoutes from "utils/routing";
import { Button } from "modules/shared/components";
import { useForm } from "react-hook-form";
import { useAuth, useTranslation } from "hooks";
import FormInputError, { validators } from "modules/shared/components/FormInputError";
import texts from "utils/languages/texts";
import toast from "react-hot-toast";


export default function Register() {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const { renderText: _ } = useTranslation()
    const { signup, registering } = useAuth()

    const onSubmit = data => {
        if (data.password !== data.confirm_password) {
            toast.error("Passwords do not match")
            return
        }
        signup(data.email, data.password, data.username)
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <Link to={"/"} className="flex items-center mb-6 text-2xl font-semibold text-gray-900 space-x-2 dark:text-white">
                    <img src={logo} className="h-[50px] w-[50px]" alt="GoodNews Logo" />
                    <span>GoodNews</span>
                </Link>
                <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                            {_(texts.signup)}
                        </h1>
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6" action="#">
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{_(texts.email)}</label>
                                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""
                                    {...register("email", validators.isEmail)}
                                />
                                <FormInputError
                                    error={errors.email}
                                    errorText={"Please input a valid email"}
                                />
                            </div>
                            <div>
                                <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{_(texts.username)}</label>
                                <input type="text" name="text" id="text" placeholder="John Doe" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                    {...register("username", validators.isRequiredString)}
                                />
                            </div>
                            <FormInputError
                                error={errors.username}
                                errorText={"Please input a valid username"}
                            />
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{_(texts.password)}</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                    {...register("password", validators.isStringWithSpacesAllowed)}
                                />
                                <FormInputError
                                    error={errors.password}
                                    errorText={"Please input a valid password"}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{_(texts.confirm_password)}</label>
                                <input type="password" name="confirm_password" id="confirm_password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required=""
                                    {...register("confirm_password", validators.isStringWithSpacesAllowed)}
                                />
                                <FormInputError
                                    error={errors.confirm_password}
                                    errorText={"Please input a valid password"}
                                />
                            </div>

                            <Button
                                text={"Register"}
                                type="submit"
                                loading={registering}
                            />
                            <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                Have an account? <Link to={appRoutes.login} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}