import clsx from "clsx";
import { useRef } from "react";

/**
 * Height is 50px
 */
const Tabs = ({ tabs, activeTab, onTabChange, className }) => {
  const containerRef = useRef(null);

  const onTabClick = (e, tab) => {
    containerRef.current &&
      containerRef.current.scrollTo(e.target.offsetLeft, 0);
    if (activeTab === tab.id) {
      return;
    }
    onTabChange(tab.id);
  };

  return (
    <div
      className={clsx(
        "fixed scroll-smooth top-[80px] bg-white z-[2] flex left-0 lg:left-[50%] w-full lg:w-auto lg:-translate-x-1/2 shadow-md h-[50px] overflow-x-auto",
        className
      )}
      ref={containerRef}
    >
      {tabs.map((tab) => (
        <button
          type="button"
          key={tab.id}
          onClick={(e) => onTabClick(e, tab)}
          className={clsx(
            "items-center whitespace-nowrap w-fit justify-center p-4 text-sm font-medium disabled:cursor-not-allowed focus:outline-none ml-0 first:ml-0 flex bg-white hover:text-gray-700 hover:bg-gray-50 rounded-none",
            {
              "!bg-primary !text-white": tab.id === activeTab,
            }
          )}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
