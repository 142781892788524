import { Modal, Button } from "flowbite-react";
import { useTranslation } from "hooks";
import React, { useMemo } from "react";
import {
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappIcon,
  LinkedinIcon,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
} from "react-share";
import texts from "utils/languages/texts";

const ShareModal = ({ visible, onClose, content }) => {
  const { renderText: _ } = useTranslation();
  const url = content?.newsWeb || window.location.href;
  const title = useMemo(() => {
    if (content?.title) {
      return `Sharing an uplifting article with the title of: ${content?.title}. View at GoodNews downloadable in the App Store or Play Store!`;
    }
    return "GoodNews";
  }, [content]);
  return (
    <Modal dismissible show={visible} onClose={onClose}>
      <Modal.Header>{_(texts.share)}</Modal.Header>
      <Modal.Body>
        <div className="space-y-6">
          <h2 className="my-2 text-black text-[20px]">{title}</h2>
          {content && (
            <div className="flex flex-col justify-center items-center space-y-2">
              <WhatsappShareButton title={title} url={url}>
                <WhatsappIcon />
              </WhatsappShareButton>
              <LinkedinShareButton title={title} url={url}>
                <LinkedinIcon />
              </LinkedinShareButton>
              <FacebookShareButton title={title} url={url}>
                <FacebookIcon />
              </FacebookShareButton>
              <TwitterShareButton
                title={title}
                url={url}
                hashtags={["GoodNews"]}
              >
                <TwitterIcon />
              </TwitterShareButton>
              <TelegramShareButton title={title} url={url}>
                <TelegramIcon />
              </TelegramShareButton>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button color="gray" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
