import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

const useTranslation = () => {
  const { appLocale, translations } = useContext(AuthContext);

  const renderText = (textKey, providedParameters) => {
    let translationBag = translations?.[appLocale] || translations["en"];
    let translation = translationBag[textKey] || "";
    var regex = /{{(.*?)}}/;
    var variables = regex.exec(translation);
    if (variables && variables.length > 1 && providedParameters) {
      variables.forEach((variable) => {
        const var_name = variable.replace("{{", "").replace("}}", "").trim();
        if (var_name) {
          const providedParameter = providedParameters?.[var_name];
          const replacement =
            translationBag[providedParameter] ?? providedParameter;
          translation = translation.replace(variable, replacement);
        }
      });
    }
    return translation;
  };
  return {
    renderText,
    appLocale,
  };
};

export default useTranslation;
