import { Modal, Button } from "flowbite-react";
import { useAuth, useTranslation } from "hooks";
import React, { useEffect, useState } from "react";
import { categories, storageKeys } from "utils/constants";
import texts from "utils/languages/texts";
import Select from "react-select";
import { doc, updateDoc } from "firebase/firestore";
import toast from "react-hot-toast";
import { getData, storeData } from "utils/functions";
import { Firestore } from "firebase_";

const WelcomeModal = () => {
  const { renderText: _ } = useTranslation();
  const { user } = useAuth();
  const [isPrefExist, setIsPrefExist] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const categoryOptions = categories.map((category) => ({
    value: category.category_type,
    label: _(category.name.toLowerCase()),
  }));

  const [isWelcomeModal, setIsWelcomeModal] = useState(false);
  const [isPreferenceModal, setIsPreferenceModal] = useState(false);

  const shouldAskForPreferences = async () => {
    if (user?.isAnonymous) {
      const preferences = await getData(storageKeys.GOODNEWS_PREFERENCES);
      if (!preferences || !preferences?.length) {
        setIsPrefExist(false);
      } else {
        setIsPrefExist(true);
      }
    } else {
      setIsPrefExist(false);
    }
  };

  const onExit = async () => {
    await storeData(storageKeys.IS_WELCOMED, true);
    setSelectedCategories([]);
    setIsPreferenceModal(false);
  };

  const saveGuestUserPreferences = async () => {
    try {
      await storeData(
        storageKeys.GOODNEWS_PREFERENCES,
        selectedCategories.map((cat) => cat.value)
      );
      setSaving(false);
      onExit(true);
      toast.success("Your preferences have been updated!");
    } catch (e) {
      toast.error("Please try again later");
      setSaving(false);
    }
  };

  const onSave = async () => {
    if (!selectedCategories?.length) {
      toast.error("Please select at least one category");
      return;
    }
    setSaving(true);
    if (user?.isAnonymous || !user) {
      saveGuestUserPreferences();
      return;
    }
    try {
      await updateDoc(doc(Firestore, "Users/", user.displayName), {
        preferences: selectedCategories.map((_) => _.value),
      });
      setSaving(false);
      toast.success("Your preferences have been updated!");
      onExit(true);
    } catch (e) {
      toast.error("There was an error");
      setSaving(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (user) {
      if (
        user?.metadata?.createdAt === user?.metadata?.lastLoginAt &&
        !user?.isAnonymous &&
        !isPrefExist
      ) {
        setIsPreferenceModal(true);
      } else {
        setIsPreferenceModal(false);
      }
    }
  }, [user, isPrefExist]);

  useEffect(() => {
    shouldAskForPreferences();
    const isAlreadyWelcomed = localStorage.getItem(storageKeys?.IS_WELCOMED);
    if (isAlreadyWelcomed) {
      setIsWelcomeModal(false);
    } else {
      setIsWelcomeModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Modal dismissible show={isWelcomeModal}>
        <Modal.Body>
          <div className="space-y-4">
            <h2 className="text-black text-[24px]">
              {_(texts.welcome_good_news)}
            </h2>
            <h4 className="text-black text-[16px]">
              {_(texts.welcome_good_news_msg)}
            </h4>
            <h5 className="text-black text-[16px]">GoodNews App Team</h5>
          </div>
          <div className="flex justify-end mt-8">
            <Button
              onClick={async () => {
                await storeData(storageKeys.IS_WELCOMED, true);
                setIsWelcomeModal(false);
              }}
            >
              Explore GoodNews
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isPreferenceModal}>
        <Modal.Header>{_(texts.select_goodnews_preferences)}</Modal.Header>
        <div className="px-6 py-5">
          <Select
            isMulti
            value={selectedCategories}
            options={categoryOptions}
            isClearable
            onChange={setSelectedCategories}
          />
        </div>
        <Modal.Body></Modal.Body>
        <Modal.Footer className="justify-end">
          <Button onClick={onSave} disabled={saving} isProcessing={saving}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default WelcomeModal;
