import { Button, TextInput } from "flowbite-react";
import { useAuth, useTranslation } from "hooks";
import { storeData, translateText } from "utils/functions";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import texts from "utils/languages/texts";
import { arrayRemove, arrayUnion, doc, updateDoc } from "firebase/firestore";
import { Firestore } from "firebase_";
import { Controller, useForm } from "react-hook-form";
import FormInputError, {
  validators,
} from "modules/shared/components/FormInputError";
import { languages, storageKeys } from "utils/constants";
import { googleTranslate } from "services/api";
import moment from "moment";

const CommunityPost = ({ article, category, setPosts, activeTab, onShare }) => {
  const { appLocale, setAppLocale, user, isDefaultLang } = useAuth();
  const languageOptions = Object.keys(languages).map((langCode) => ({
    label: languages[langCode].name,
    value: languages[langCode].code,
  }));
  const { renderText: _ } = useTranslation();
  const [translatedVersion, setTranslatedVersion] = useState(null);
  const [isBeingBlocked, setIsBeingBlocked] = useState(false);
  const [isBeingTranslated, setIsBeingTranslated] = useState(false);
  const [isBeingLiked, setisBeingLiked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [commenting, setCommenting] = useState(false);
  const [showMoreComments, setShowMoreComments] = useState(false);
  const [userAlreadyLiked, setUserAlreadyLiked] = useState(
    article.likedBy?.includes(user?.uid)
  );
  const [translatedArticle, setTranslatedArticle] = useState([]);

  const commentsToDisplay =
    (showMoreComments ? article?.comments : article.comments?.slice(0, 2)) ||
    [];

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm({
    defaultValues: {
      comment: "",
    },
  });

  const handleTranslate = async () => {
    setIsBeingTranslated(true);
    const postContent = article.content;
    const postTitle = article.title;
    const { data, error } = await translateText(postContent, appLocale);
    const { data: titleData, error: titleError } = await translateText(
      postTitle,
      appLocale
    );
    if (error || titleError) {
      console.log(error, titleError);
      setIsBeingTranslated(false);
      toast.error(_(texts.failed_to_translate));
      return;
    }
    setIsBeingTranslated(false);
    setTranslatedVersion({ title: titleData, content: data });
  };

  const onComment = async ({ comment }) => {
    setCommenting(true);
    try {
      const docRef = doc(Firestore, "posts", article.id);
      const newData = { userId: user.displayName, comment };
      await updateDoc(docRef, {
        comments: arrayUnion(newData),
      });
      setPosts((_) => ({
        ..._,
        [activeTab]: {
          loading: false,
          content: (_[activeTab]?.content || []).map((_post) => {
            if (article.id === _post.id) {
              const newComments = [...(article.comments || []), newData];
              return { ..._post, comments: newComments };
            }
            return _post;
          }),
        },
      }));
      reset({ comment: "" });
      toast.success(_(texts.successful));
      setCommenting(false);
    } catch (e) {
      console.log(e);
      toast.success(_(texts.there_was_an_error));
      setCommenting(false);
    }
  };

  const blockPost = async () => {
    setIsBeingBlocked(true);
    try {
      const docRef = doc(Firestore, "posts", article.id);
      await updateDoc(docRef, {
        blockedBy: arrayUnion(user.uid),
      });
      setPosts((_) => ({
        ..._,
        [activeTab]: {
          loading: false,
          content: (_[activeTab]?.content || []).filter(
            (x) => x.id !== article.id
          ),
        },
      }));
      toast.success(_(texts.successful));
      // update here
    } catch (e) {
      console.log(e);
      toast.error(_(texts.there_was_an_error));
      setIsBeingBlocked(false);
    }
  };

  const likePost = async () => {
    setisBeingLiked(true);
    const docRef = doc(Firestore, "posts", article.id);
    if (!userAlreadyLiked) {
      await updateDoc(docRef, {
        likedBy: arrayUnion(user?.uid),
      });
    } else {
      await updateDoc(docRef, {
        likedBy: arrayRemove(user?.uid),
      });
    }
    setUserAlreadyLiked(!userAlreadyLiked);
    setPosts((_) => ({
      ..._,
      [activeTab]: {
        loading: false,
        content: (_[activeTab]?.content || []).map((_post) => {
          if (article.id === _post.id) {
            let newLikes;
            if (userAlreadyLiked) {
              newLikes = (article.likedBy || []).filter((_) => _ !== user?.uid);
            } else {
              newLikes = [...(article.likedBy || []), user?.uid];
            }
            return { ..._post, likedBy: newLikes };
          }
          return _post;
        }),
      },
    }));
    setisBeingLiked(false);
  };

  const reportPost = async () => {
    const text = `I would like to report post "${article.title}" with id ${article.id}.
        `;
    window.open(`mailto:Dasgoodnews@gmail.com?subject=subject&body=${text}`);
    // try {
    //     setIsBeingReported(true)
    //     await sendMail(`<Center><h1>Good News Appp</h1></Center><p>Some one report this post and postid is ${article.id}</p>`)
    //     toast.success(_(texts.successful))
    //     setIsBeingReported(false)
    // }
    // catch (err) {
    //     setIsBeingReported(false)
    //     toast.error(_(texts.there_was_an_error))
    //     console.log(err)
    // }
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const isExpandable = translatedArticle?.[1]?.translatedText?.length > 150;

  const contentToShow = expanded
    ? translatedVersion?.content || translatedArticle?.[1]?.translatedText
    : (
        translatedVersion?.content || translatedArticle?.[1]?.translatedText
      )?.slice(0, 150);

  const getTranslatedArticle = async (article) => {
    if (appLocale === languages.en.code) {
      setTranslatedArticle([
        { translatedText: article?.title },
        { translatedText: article?.description || article?.content },
      ]);
    } else {
      try {
        let resposne = await googleTranslate(
          [article?.title, article?.description || article?.content],
          appLocale
        );
        setTranslatedArticle(resposne?.translations);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getTranslatedArticle(article);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article, appLocale]);

  useEffect(() => {
    const browserLanguage =
      navigator?.userLanguage?.substring(0, 2) ||
      navigator?.language?.substring(0, 2);
    const isExist = languageOptions?.find(
      (lang) => lang?.value === browserLanguage
    );
    if (isExist && isDefaultLang) {
      storeData(storageKeys?.PREFERRED_LANGUAGE, isExist?.value);
      setAppLocale(isExist?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 rounded-md border border-solid border-[#cdcdcd] px-4 py-8 md:p-0">
      <img
        src={
          article.image ||
          "https://assets.website-files.com/6458c625291a94a195e6cf3a/6458c625291a94016de6cf90_Rectangle%2035.svg"
        }
        alt=""
        className="h-60 object-cover"
      />
      <div className="px-6 py-4">
        <div className="mb-4 w-fit text-center text-sm font-semibold uppercase text-[white] bg-primary rounded-full p-[0.3rem] pl-[1rem] pr-[1rem] ">
          {category}
        </div>
        <p className="mb-4 text-xl font-semibold">
          {translatedVersion?.title || translatedArticle?.[0]?.translatedText}
        </p>
        <p className="mb-6 text-sm text-[#636262] sm:text-base lg:mb-8">
          {contentToShow}
          {!expanded && isExpandable ? "..." : ""} {"   "}
          {isExpandable && (
            <button className="text-primary" onClick={toggleExpanded}>
              {expanded ? _(texts.show_less) : _(texts.show_more)}
            </button>
          )}
        </p>
        <div className="my-4 flex items-center justify-between">
          <span className="text-sm">
            {article.likedBy?.length ?? 0} {_(texts.likes)}
          </span>
          {user && !user.isAnonymous && (
            <Button
              className="bg-primary"
              disabled={isBeingLiked}
              isProcessing={isBeingLiked}
              onClick={likePost}
            >
              {_(userAlreadyLiked ? texts.unlike : texts.like)}
            </Button>
          )}
        </div>
        <div className="flex space-x-1 py-2">
          {user && !user.isAnonymous && (
            <Button
              className="bg-primary"
              disabled={isBeingBlocked}
              isProcessing={isBeingBlocked}
              onClick={blockPost}
            >
              {_(texts.block)}
            </Button>
          )}
          {user && !user.isAnonymous && (
            <Button className="bg-primary" onClick={reportPost}>
              {_(texts.report)}
            </Button>
          )}
          {appLocale !== languages.en.code && (
            <Button
              className="bg-primary hidden"
              onClick={handleTranslate}
              disabled={isBeingTranslated || translatedVersion?.title}
              isProcessing={isBeingTranslated}
            >
              {_(texts.translate)}
            </Button>
          )}
          <Button onClick={onShare} className="bg-primary">
            {_(texts.share)}
          </Button>
        </div>
        {user && !user.isAnonymous && (
          <form onSubmit={handleSubmit(onComment)} className="my-2">
            <div className="flex space-x-1">
              <Controller
                control={control}
                name="comment"
                rules={validators.isRequiredString}
                render={({ field }) => (
                  <TextInput
                    className="flex-1"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={_(texts.comment)}
                  />
                )}
              />
              <Button
                type="submit"
                isProcessing={commenting}
                disabled={commenting}
                className="bg-primary flex-shrink-0"
              >
                {_(texts.submit)}
              </Button>
            </div>
            <FormInputError error={errors.comment} />
          </form>
        )}
        <div className=" pb-2">
          {commentsToDisplay?.map((comment, index) => (
            <div
              className="flex flex-col space-y-3"
              key={`${comment?.userId}_${index}`}
            >
              <span>{comment.comment}</span>
              {!!comment.userId && (
                <span style={{ color: "grey" }}>{comment.userId}</span>
              )}
            </div>
          ))}
          {article.comments?.length > 2 && (
            <Button
              className="bg-primary mt-2"
              onClick={() => {
                setShowMoreComments(!showMoreComments);
              }}
            >
              {showMoreComments ? _(texts.show_less) : _(texts.show_more)}
            </Button>
          )}
        </div>
        <div className="mt-2 flex flex-col space-y-2 ">
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-600 mr-2 flex-shrink-0">
              {article.newsReporter}
            </span>
            <a
              href={article.newsWeb}
              target="_blank"
              rel="noreferrer"
              className="bg-primary h-8 w-8 rounded-full flex items-center justify-center"
            >
              <svg
                viewBox="0 0 24 24"
                className="h-5 w-5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M15.197 3.35462C16.8703 1.67483 19.4476 1.53865 20.9536 3.05046C22.4596 4.56228 22.3239 7.14956 20.6506 8.82935L18.2268 11.2626M10.0464 14C8.54044 12.4882 8.67609 9.90087 10.3494 8.22108L12.5 6.06212"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>
                  <path
                    d="M13.9536 10C15.4596 11.5118 15.3239 14.0991 13.6506 15.7789L11.2268 18.2121L8.80299 20.6454C7.12969 22.3252 4.55237 22.4613 3.0464 20.9495C1.54043 19.4377 1.67609 16.8504 3.34939 15.1706L5.77323 12.7373"
                    stroke="#ffffff"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>{" "}
                </g>
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-2 flex justify-between items-center">
          <span className="text-sm text-gray-600">{article.newsSource}</span>
          <span className="text-sm text-gray-600">
            {moment?.unix(article?.createdAt?.seconds)?.format("DD MMM YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommunityPost;
