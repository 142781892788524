import React, { useState } from "react";
import { Button, FileInput, Label, TextInput, Textarea } from "flowbite-react";
import { useAuth, useTranslation } from "hooks";
import texts from "utils/languages/texts";
import { tabs } from "utils/languages/translations";
import { useForm, Controller } from "react-hook-form";
import FormInputError, {
  validators,
} from "modules/shared/components/FormInputError";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Firestore, analytics, storage } from "firebase_";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import axios from "axios";
import { logEvent } from "firebase/analytics";

function AddPost() {
  const { user } = useAuth();
  const [image, setImage] = useState("");
  const { renderText: _ } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    defaultValues: {
      title: "",
      content: "",
      url: "",
      reporter: "",
      source: "",
    },
  });

  // const uploadImage = async(imageFile) => {
  //     try{
  //         const filename = uri.substring(uri.lastIndexOf('/') + 1);
  //     }
  //     catch(e){}
  // }

  const checkIfIsAdultImage = async (img) => {
    try {
      const response = await axios.get(
        `https://api.moderatecontent.com/moderate/?key=ecad2c1cd0ac3dd8407da669e6d4c1e3&url=${img}`,
        {
          headers: {
            Cookie: "PHPSESSID=nja67hlfbtrg6pq43okhh8lpqs",
          },
        }
      );
      const result = response.data;
      if (result?.predictions?.adult < 95) {
        return { isAdult: false, error: null };
      } else {
        toast.error("You cannot post this image!");
        return { isAdult: true, error: null };
      }
    } catch (error) {
      return { isAdult: false, error: error.response.data };
    }
  };

  // const sendNotifcation = () => {
  //     var myHeaders = new Headers();
  //     myHeaders.append('Content-Type', 'application/json');
  //     myHeaders.append(
  //         'Authorization',
  //         'key =AAAA80B3cAc:APA91bGsH2tRhEp5UG4gOtXVdpuhT_7hDzgCNhjxGZ-3x8qc658NrXQVbnaYkRcyD9HZwVroZ_MuwyDeZdjeyrYwm4L4TCx60yl5qsYAJB42xi_nkgp9trrZjhi3jlEratLabtls-bE4',
  //     );

  //     var raw = JSON.stringify({
  //         data: {},
  //         notification: {
  //             title: 'WorldNews',
  //             body: 'WorldNews just created a post',
  //             image: '',
  //         },
  //         to: '/topics/WorldNews',
  //     });

  //     var requestOptions = {
  //         method: 'POST',
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: 'follow',
  //     };

  //     fetch('https://fcm.googleapis.com/fcm/send', requestOptions)
  //         .then(response => response.text())
  //         .then(result => console.log(result))
  //         .catch(error => console.log('error', error));
  // };

  const onSubmit = (data) => {
    if (!image) {
      toast.error("Please upload a valid image");
      return;
    }
    setLoading(true);
    const storageRef = ref(storage, image.name);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(`Upload percent => ${percent}%`);
      },
      (err) => {
        console.log(err);
        toast.error(JSON.stringify(err));
        setLoading(false);
      },
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          const imageUrl = url;
          const { isAdult, error } = await checkIfIsAdultImage(imageUrl);
          if (error) {
            toast.error(error);
            setLoading(false);
            return;
          }
          if (isAdult) {
            toast.error("You cannot post this image");
            setLoading(false);
            return;
          }
          try {
            console.log("adding doc");
            await addDoc(collection(Firestore, "posts/"), {
              title: data.title,
              content: data.content,
              image: imageUrl,
              newsSource: data.source,
              newsWeb: data.url,
              newsReporter: data.reporter,
              createdAt: serverTimestamp(), // Add timestamp
            });
            toast.success(_(texts.post_added_successfully));
            setLoading(false);
            logEvent(analytics, "created new post", data);
            reset();
          } catch (e) {
            console.log(e);
            toast.error(_(texts.there_was_an_error));
            setLoading(false);
          }
          //   sendNotifcation();
        });
      }
    );
  };

  function handleChange(event) {
    setImage(event.target.files[0]);
  }

  return (
    <div className="pt-[50px]">
      <div className="mx-auto max-w-[500px] px-5 pb-16 md:px-10">
        <h3 className="text-left text-[40px] font-bold mb-3 text-black">
          {_(tabs.add_post)}
        </h3>
        {!user || user.isAnonymous ? (
          <div className="flex">
            {_(texts.create_an_account_to_create_post)}
          </div>
        ) : (
          <form
            className="flex max-w-md flex-col gap-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* image */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="image" value={_(texts.upload_image)} />
              </div>
              <FileInput
                placeholder={_(texts.upload_image)}
                required
                shadow
                accept="image/*"
                onChange={handleChange}
              />
              <FormInputError error={errors.image} />
            </div>
            {/* title */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="title" value={_(texts.title)} />
              </div>
              <Controller
                control={control}
                name="title"
                rules={validators.isRequiredString}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    type="text"
                    placeholder={_(texts.title)}
                    required
                    shadow
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormInputError error={errors.title} />
            </div>
            {/* content */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="content" value={_(texts.content)} />
              </div>
              <Controller
                control={control}
                name="content"
                rules={validators.isRequiredString}
                render={({ field: { value, onChange } }) => (
                  <Textarea
                    type="text"
                    placeholder={_(texts.content)}
                    required
                    shadow
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormInputError error={errors.content} />
            </div>
            {/* url */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="url" value={_(texts.url)} />
              </div>
              <Controller
                control={control}
                name="url"
                rules={validators.isURL}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    type="text"
                    placeholder={_(texts.url)}
                    required
                    shadow
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormInputError error={errors.url} />
            </div>
            {/* reporter */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="reporter" value={_(texts.reporter)} />
              </div>
              <Controller
                control={control}
                name="reporter"
                rules={validators.isRequiredString}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    type="text"
                    placeholder={_(texts.reporter)}
                    required
                    shadow
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormInputError error={errors.reporter} />
            </div>
            {/* source */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="source" value={_(texts.source)} />
              </div>
              <Controller
                control={control}
                name="source"
                rules={validators.isRequiredString}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    type="text"
                    placeholder={_(texts.source)}
                    required
                    shadow
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <FormInputError error={errors.source} />
            </div>
            <Button
              type="submit"
              disabled={loading}
              isProcessing={loading}
              className="bg-primary"
            >
              {_(texts.submit)}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
}

export default AddPost;
