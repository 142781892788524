import React from "react";
import { ReactComponent as AppStoreIcon } from "images/appstore.svg";
import { ReactComponent as PlayStoreIcon } from "images/playstore.svg";
import Logo from "./logo";

function footer() {
  return (
    <>
      <footer className="bg-primary">
        <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="flex justify-center ">
            {/* <h1 className="text-5xl font-bold text-white">GoodNews</h1> */}
            <Logo />
          </div>

          <p className="mx-auto mt-6 max-w-md text-center leading-relaxed text-white">
            Made by GoodNews App LLC. All Rights Reserved 2024.{" "}
          </p>

          <div className="mt-12 flex flex-col items-center gap-6 md:gap-8">
            <a
              href="https://apps.apple.com/us/app/goodnews-trending-news/id6446507143"
              rel="noreferrer"
              target="_blank"
              className="text-white transition hover:scale-[1.1] flex items-center space-x-2"
            >
              <span>Download on the App Store</span>
              <AppStoreIcon />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.goodnewsv0hn6"
              rel="noreferrer"
              target="_blank"
              className="text-white transition hover:scale-[1.1] flex items-center space-x-2"
            >
              <span>Download on the Play Store</span>
              <PlayStoreIcon />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default footer;
