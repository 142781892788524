import moment from "moment";
import React, { useMemo } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ReactComponent as Clock } from "../images/clock.svg";
import microphone from "../images/microphone.png";
import greyMicrophone from "../images/microphone-grey.png";
import NewsImage from "./NewsImage";
import { useTranslation } from "hooks";

const SingleNewsCard = ({ newsItem, showInsights }) => {
  const { image, title, publishedAt, source, url } = newsItem;
  return (
    <div className="flex flex-col space-y-5">
      <NewsImage
        imageUrl={image}
        alt={title}
        containerClassName={"h-[200px] 528px:h-[300px] rounded-md"}
      >
        <div className="absolute insights-btn-container transition-all duration-100 md:translate-y-2 bottom-4 left-[50%] translate-x-[-50%] md:opacity-0 md:pointer-events-none">
          <button
            className="bg-primary py-2 px-3 rounded-lg text-white"
            onClick={() => showInsights(newsItem)}
          >
            Insights
          </button>
        </div>
      </NewsImage>
      <div className="flex flex-col space-y-3">
        <a href={url} target="_blank" rel="noreferrer" className="w-fit">
          <h1 className="text-black text-sm md:text-base line-clamp-2">
            {title}
          </h1>
        </a>
        <div className="flex flex-col space-y-3 528px:justify-between 528px:flex-row">
          <div className="flex space-x-2 items-center">
            <Clock className="h-4 w-4 fill-[grey]" />
            <span className="text-[12px] text-[grey]">
              {moment(publishedAt).format("DD MMM YYYY h:mm A")}
            </span>
          </div>
          <div className="flex space-x-2 items-center">
            <img src={greyMicrophone} className="h-4 w-4" alt={title} />
            <span className="text-[12px] text-[grey]">{source?.name}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsCategoryDisplay = ({ categoryName, newsItems, showInsights }) => {
  const { renderText } = useTranslation();
  const spotlightedNewsItem = useMemo(() => {
    if (newsItems.length) {
      return newsItems[0];
    }
  }, [newsItems]);

  const orderedNews = useMemo(() => {
    const sortedNews = [...newsItems].sort((a, b) => {
      const dateA = new Date(a.publishedAt);
      const dateB = new Date(b.publishedAt);
      return dateB - dateA;
    });
    return sortedNews;
  }, [newsItems]);

  return (
    <div className="flex flex-col space-y-10">
      <div className="flex flex-col space-y-4">
        <h3 className="text-[23px] md:text-[40px] text-black capitalize font-semibold">
          {categoryName}
        </h3>
        {spotlightedNewsItem && (
          <NewsImage
            imageUrl={spotlightedNewsItem.image}
            alt={spotlightedNewsItem.title}
            containerClassName="h-[400px] flex flex-col relative overflow-hidden rounded-xl"
          >
            <div className="absolute bg-black/[.4] top-0 left-0 h-full w-full" />
            <div className="mt-auto pb-8 pl-8 flex flex-col space-y-3 relative">
              <a
                href={spotlightedNewsItem.url}
                target="_blank"
                rel="noreferrer"
                className="max-w-[80%] md:max-w-[60%]"
              >
                <h1 className="text-white text-[20px] md:text-[28px]">
                  {spotlightedNewsItem.title}
                </h1>
              </a>
              <div className="flex space-x-[50px]">
                <div className="flex items-center space-x-2">
                  <Clock className="h-5 w-5 fill-white" />
                  <span className="text-[12px] text-white">
                    {moment(spotlightedNewsItem.publishedAt).format(
                      "DD MMM YYYY h:mm A"
                    )}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <img src={microphone} alt="Microphone" className="h-5 w-5" />
                  <span className="text-[12px] text-white">
                    {spotlightedNewsItem.source?.name}
                  </span>
                </div>
              </div>
            </div>
          </NewsImage>
        )}
      </div>
      <div className="flex flex-col space-y-3">
        <h3 className="text-[20px] md:text-[30px] text-black capitalize font-semibold">
          {renderText("latest_news")}
        </h3>
        <div className="grid grid-cols-2 900px:grid-cols-3 1157px:grid-cols-4 gap-x-6 gap-y-6">
          {orderedNews.map((item, index) => (
            <SingleNewsCard
              key={`${categoryName}-${index}`}
              newsItem={item}
              showInsights={showInsights}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsCategoryDisplay;
