import { AuthContext } from "contexts/AuthProvider";
import { useContext } from "react";

const useAuth = () => {
  const {
    user,
    setUser,
    setAppLocale,
    appLocale,
    isDefaultLang, // detects if user manually updated the language selection from setting.
    setIsDefaultLang,
    // login
    login,
    signInAnon,
    loggingIn,
    // register
    registering,
    signup,
    // logging out
    logout,
    loggingOut,
    setShowPreferencesModal,
  } = useContext(AuthContext);

  return {
    user,
    setUser,
    setAppLocale,
    appLocale,
    isDefaultLang,
    setIsDefaultLang,
    // login
    login,
    signInAnon,
    loggingIn,
    // register
    registering,
    signup,
    // logging out
    logout,
    loggingOut,
    setShowPreferencesModal,
  };
};

export default useAuth;
