import { logEvent } from "firebase/analytics";
import { analytics } from "firebase_";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    logEvent(analytics, `Opened ${window.location.url}`);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
