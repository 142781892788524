import clsx from "clsx";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";

const NewsImage = ({ imageUrl, alt, containerClassName, children }) => {
  const [imageState, setImageState] = useState({
    loading: true,
    error: false,
  });

  const handleLoad = () => {
    setImageState({ loading: false, error: false });
  };

  const handleError = () => {
    setImageState({ loading: false, error: true });
  };
  return (
    <div
      className={clsx("relative overflow-hidden news-img", containerClassName)}
    >
      {(imageState.loading || imageState.error) && (
        <Skeleton className={"absolute top-0 left-0 w-full h-full"} />
      )}
      <img
        src={imageUrl}
        className={clsx(
          "absolute top-0 left-0 w-full h-full object-cover opacity-0",
          {
            "!opacity-100": !imageState.loading && !imageState.error,
            "!h-0": imageState.loading || imageState.error,
          }
        )}
        alt={alt}
        onLoad={handleLoad}
        onError={handleError}
      />
      {children}
    </div>
  );
};

export default NewsImage;
