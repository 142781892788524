/* eslint-disable no-unused-expressions */

import { languages, storageKeys } from "./constants";

const translate = (x) => {
  console.log(x);
};

export const storeData = async (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e, "failed to save");
    return e;
    // saving error
  }
};

/**
 * Function to retrieve stored data from local storage.
 * @param {string} key
 * @returns
 */
export const getData = async (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (e) {
    return e;
  }
};

export const setUpAppLanguages = async () => {
  const languageCode = await getData(storageKeys.PREFERRED_LANGUAGE);
  let locale;
  if (languageCode) {
    locale = languageCode;
  } else {
    const deviceLanguages = navigator.languages;
    deviceLanguages.forEach((deviceLanguage) => {
      const formattedLanguage = deviceLanguage?.split("-")?.[0];
      if (Object.keys(languages).includes(formattedLanguage)) {
        if (!locale) {
          locale = formattedLanguage;
        }
      }
    });
  }
  if (languages?.[locale]) {
    console.log({ locale });
    return locale;
  } else {
    return languages.en.code;
  }
};

export const checkDefaultLanguage = async () => {
  const isDefaultLanguage = await getData(storageKeys.IS_DEFAULT_LANGUAGE);
  if (isDefaultLanguage === undefined) {
    return true;
  } else {
    return isDefaultLanguage;
  }
};

export const translateText = async (text, toLanguage) => {
  console.log(text, toLanguage);
  try {
    const response = await translate(text, { to: toLanguage });
    return { data: response.text, error: null };
  } catch (e) {
    console.log(e);
    const errString = typeof e === "string" ? e : JSON.stringify(e);
    return { data: null, error: errString };
  }
};

// var Email = {
//     send: function (a) {
//         return new Promise(function (n, e) {
//             a.nocache = Math.floor(1e6 * Math.random() + 1), a.Action = "Send";
//             var t = JSON.stringify(a);
//             Email.ajaxPost("https://smtpjs.com/v3/smtpjs.aspx?", t, function (e) {
//                 n(e)
//             })
//         })
//     },
//     ajaxPost: function (e, n, t) {
//         var a = Email.createCORSRequest("POST", e);
//         a.setRequestHeader("Content-type", "application/x-www-form-urlencoded"), a.onload = function () {
//             var e = a.responseText;
//             null != t && t(e)
//         }, a.send(n)
//     },
//     ajax: function (e, n) {
//         var t = Email.createCORSRequest("GET", e);
//         t.onload = function () {
//             var e = t.responseText;
//             null != n && n(e)
//         }, t.send()
//     },
//     createCORSRequest: function (e, n) {
//         var t = new XMLHttpRequest();
//         t.open(e, n, true);
//         return t;
//     }
// };

// export const sendMail = htmlBody => {
//     return Email.send({
//         Host: "smtp.gmail.com",
//         Username: "alejandrosorto798@gmail.com",
//         Password: "voaeismnxfmjiuyp",
//         To: 'Dasgoodnews@gmail.com',
//         From: "ood News App",
//         Subject: "Subject",
//         Body: htmlBody
//     })
// }
