import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import appRoutes from "utils/routing";
import { useAuth, useTranslation } from "hooks";
import clsx from "clsx";
import { tabs } from "utils/languages/translations";
import texts from "utils/languages/texts";
import Logo from "./logo";

function Header() {
  const { renderText: _ } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navLinkClassName = ({ isActive }, extraCheck) =>
    isActive || extraCheck
      ? "text-black bg-white p-2 rounded-full capitalize"
      : "text-white transition hover:text-black hover:bg-white p-2 rounded-full capitalize";

  // Determine nav class names based on state

  return (
    <>
      <header className="bg-primary h-[80px] fixed top-0 left-0 w-full z-[2] px-4">
        <div className="mx-auto flex h-[5rem] max-w-screen-xl items-center justify-between sm:px-6 lg:px-8">
          <Logo />
          <nav className={"hidden 1000px:block"} aria-label="Global">
            <ul className="flex flex-col md:flex-row items-center gap-6 text-base font-semibold">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    navLinkClassName(
                      { isActive },
                      location.pathname === appRoutes.newspapers
                    )
                  }
                  to={appRoutes.communityposts}
                >
                  Goodnews
                </NavLink>
              </li>
              <li>
                <NavLink className={navLinkClassName} to={appRoutes.economy}>
                  {_(tabs.economy)}
                </NavLink>
              </li>

              <li>
                <NavLink className={navLinkClassName} to={appRoutes.search}>
                  {_(tabs.search)}
                </NavLink>
              </li>
              <li>
                <NavLink className={navLinkClassName} to={appRoutes.addpost}>
                  {_(tabs.add_post)}
                </NavLink>
              </li>
              <li>
                <NavLink className={navLinkClassName} to={appRoutes.setting}>
                  {_(tabs.settings)}
                </NavLink>
              </li>
            </ul>
          </nav>

          <div className="items-center gap-[1rem] hidden 1000px:flex">
            <div className="flex gap-4">
              {user?.displayName && (
                <h2 className="bg-white rounded-full p-2 font-semibold text-blue-400 hidden md:block">
                  {user?.displayName}
                </h2>
              )}
              {user ? (
                <button
                  className="block rounded-md bg-black px-5 py-2.5 text-sm font-medium text-white transition hover:bg-white hover:text-black"
                  onClick={() => logout()}
                >
                  {_(texts.logout)}
                </button>
              ) : (
                <button
                  className={clsx(
                    "block rounded-md bg-black px-5 py-2.5 text-sm font-medium text-white transition hover:bg-white hover:text-black",
                    {
                      "!opacity-0 !pointer-events-none":
                        location.pathname === appRoutes.login,
                    }
                  )}
                  onClick={() => navigate(appRoutes.login)}
                >
                  {_(texts.login)}
                </button>
              )}
            </div>
          </div>
          {location.pathname !== appRoutes.login && (
            <div className="1000px:hidden" onClick={toggleMenu}>
              <button className="rounded bg-gray-100 p-2.5 text-gray-600 transition hover:text-gray-600/75">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </header>
      <div
        className={clsx(
          "fixed bg-primary transition-all duration-300 1000px:hidden z-[10] h-screen overflow-y-auto w-screen top-0 left-0 pointer-events-none opacity-0",
          { "!opacity-100 !pointer-events-auto": isMenuOpen }
        )}
      >
        <button
          className="absolute right-[30px] top-[30px] bg-white py-4 px-5 text-black"
          onClick={() => setIsMenuOpen(false)}
        >
          Close
        </button>

        <ul className="flex flex-col justify-center items-center gap-10 text-base font-semibold pt-[150px]">
          <li>
            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={({ isActive }) =>
                navLinkClassName(
                  { isActive },
                  location.pathname === appRoutes.communityposts
                )
              }
              to={appRoutes.newspapers}
            >
              Goodnews
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={navLinkClassName}
              to={appRoutes.economy}
            >
              {_(tabs.economy)}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={navLinkClassName}
              to={appRoutes.search}
            >
              {_(tabs.search)}
            </NavLink>
          </li>

          <li>
            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={navLinkClassName}
              to={appRoutes.addpost}
            >
              {_(tabs.add_post)}
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setIsMenuOpen(false)}
              className={navLinkClassName}
              to={appRoutes.setting}
            >
              {_(tabs.settings)}
            </NavLink>
          </li>
        </ul>

        <div className="flex flex-col justify-center items-center pt-[30px]">
          {user?.displayName && (
            <h2 className="bg-white rounded-full p-2 font-semibold text-blue-400 hidden md:block">
              {user?.displayName}
            </h2>
          )}
          {user ? (
            <button
              className="block rounded-md bg-black mt-[30px] px-5 py-2.5 text-sm font-medium text-white transition hover:bg-white hover:text-black"
              onClick={() => {
                logout();
                setIsMenuOpen(false);
              }}
            >
              {_(texts.logout)}
            </button>
          ) : (
            <button
              className={clsx(
                "block rounded-md bg-black px-5 py-2.5 text-sm font-medium text-white transition hover:bg-white hover:text-black",
                {
                  "!opacity-0 !pointer-events-none":
                    location.pathname === appRoutes.login,
                }
              )}
              onClick={() => {
                navigate(appRoutes.login);
                setIsMenuOpen(false);
              }}
            >
              {_(texts.login)}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
