import { auth } from "firebase_";
import { useAuth, useTranslation } from "hooks";
import { Button } from "modules/shared/components";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { languages, storageKeys } from "utils/constants";
import { storeData } from "utils/functions";
import texts from "utils/languages/texts";

import { useNavigate } from "react-router-dom";

const languageOptions = Object.keys(languages).map((langCode) => ({
	label: languages[langCode].name,
	value: languages[langCode].code,
}));

function Settings() {
	const navigate = useNavigate();
	const { renderText: _ } = useTranslation();
	const {
		setShowPreferencesModal,
		appLocale,
		setAppLocale,
		user,
		setIsDefaultLang,
	} = useAuth();

	const selectedLanguage = useMemo(() => {
		return languageOptions.find((option) => option.value === appLocale);
	}, [appLocale]);

	const handleDeleteAccount = () => {
		const currentUser = auth.currentUser;

		const hardDelete = () => {
			currentUser
				.delete()
				.then(() => {
					toast.success("Account deleted successfully");
				})
				.catch((error) => {
					console.log("Error deleting account: ", error);
					toast.error("Failed to delete account");
				});
		};
		toast.custom((t) => (
			<div
				className={`${
					t.visible ? "animate-enter" : "animate-leave"
				} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex items-center ring-1 ring-black ring-opacity-5`}
			>
				<div className="flex-1 w-0 p-4">{_(texts.delete_account)} ?</div>
				<div className="flex flex-col border-l border-gray-200 space-y-2 items-center">
					<button
						onClick={() => {
							hardDelete();
							toast.dismiss(t.id);
						}}
						className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center  justify-center text-sm font-medium text-[red] focus:outline-none focus:ring-2 focus:ring-[red]"
					>
						{_(texts.delete_account)}
					</button>
					<button
						onClick={() => {
							toast.dismiss(t.id);
						}}
						className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
					>
						{_(texts.cancel)}
					</button>
				</div>
			</div>
		));
	};

	return (
		<div className="pt-[50px]">
			<div className="mx-auto w-full max-w-7xl px-5 pb-16 md:px-10">
				<div className="col-span-8 rounded-xl sm:bg-gray-50 sm:px-8 sm:shadow pb-10">
					<div className="pt-4">
						<h1 className="py-2 text-2xl font-semibold">{_(texts.settings)}</h1>
					</div>
					<hr className="mt-4 mb-8" />
					<p className="py-2 text-xl font-semibold">
						{_(texts.have_questions_or_comments)}
					</p>
					<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
						<a
							className="text-gray-600 underline"
							href="mailto:dasgoodnews@gmail.com"
							target="_blank"
							rel="noreferrer"
						>
							{_(texts.contact_us)}
						</a>
					</div>
					<hr className="mt-4 mb-8" />
					<p className="py-2 text-xl font-semibold">
						{_(texts.select_goodnews_preferences)}
					</p>
					<Button
						text={_(texts.select_goodnews_preferences)}
						className={"!w-fit"}
						onClick={() =>
							setShowPreferencesModal({ isUpdate: true, visible: true })
						}
					/>
					<hr className="mt-4 mb-8" />
					<p className="py-2 text-xl font-semibold">
						{_(texts.select_app_language)}
					</p>
					<div className="max-w-[400px]">
						<Select
							value={selectedLanguage}
							options={languageOptions}
							onChange={({ value }) => {
								storeData(storageKeys.PREFERRED_LANGUAGE, value);
								storeData(storageKeys.IS_DEFAULT_LANGUAGE, false);
								setAppLocale(value);
								setIsDefaultLang(false);
							}}
						/>
					</div>
					<hr className="mt-4 mb-8" />
					<p className="py-2 text-xl font-semibold">
						Please find privacy URL here:
					</p>
					<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
						<Button
							text="Privacy Policy"
							className={"!w-fit"}
							onClick={() => navigate("/privacy")}
						/>
					</div>
					{/* <Button
                        text={_(texts.select_goodnews_preferences)}
                        className={"!w-fit"}
                        onClick={() => setShowPreferencesModal({ isUpdate: true, visible: true })}
                    /> */}
					<hr className="mt-4 mb-8" />
					{user && (
						<div className="mb-10">
							<p className="py-2 text-xl font-semibold">
								{_(texts.delete_account)}
							</p>
							<button
								onClick={handleDeleteAccount}
								className="inline-flex items-center rounded-full bg-rose-100 px-4 py-1 text-rose-600"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="mr-2 h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
										clipRule="evenodd"
									/>
								</svg>
								{_(texts.delete_account)}
							</button>
							{/* <p className="mt-2">Make sure you have taken backup of your account in case you ever need to get access to your data. We will completely wipe your data. There is no way to access your account after this action.</p> */}
							{/* <button className="ml-auto text-sm font-semibold text-rose-600 underline decoration-2">Continue with deletion</button> */}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default Settings;
