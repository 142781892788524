/* eslint-disable no-useless-escape */
import * as tf from "@tensorflow/tfjs";
import { createContext, useEffect, useLayoutEffect, useState } from "react";
import {
  loadHostedPretrainedModel,
  loadHostedMetadata,
  padSequences,
} from "./utils";

// const ORIGINAL_SOURCE =
//   "https://storage.googleapis.com/tfjs-examples/sentiment/dist/index.html";
const SENTIMENT_CNN_MODEL =
  "https://storage.googleapis.com/tfjs-models/tfjs/sentiment_cnn_v1/model.json";
const SENTIMENT_METADATA =
  "https://storage.googleapis.com/tfjs-models/tfjs/sentiment_cnn_v1/metadata.json";
const OOV_INDEX = 2;

export const AppContext = createContext();

export default function AppProvider({ children }) {
  const [modelReady, setModelReady] = useState(false);
  const [sentimentModel, setSentimentModel] = useState(null);
  const [sentimentMetadata, setSentimentMetadata] = useState(null);

  useLayoutEffect(() => {
    // declare the data fetching function
    const initLoad = async () => {
      const model = await loadHostedPretrainedModel(SENTIMENT_CNN_MODEL);
      const metadata = await loadHostedMetadata(SENTIMENT_METADATA);
      setSentimentModel(model);
      setSentimentMetadata(metadata);
    };

    if (!sentimentModel) {
      initLoad().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sentimentModel && sentimentMetadata) {
      setModelReady(true);
    }
  }, [sentimentModel, sentimentMetadata]);

  const indexFrom = sentimentMetadata?.["index_from"];
  const maxLen = sentimentMetadata?.["max_len"];
  const wordIndex = sentimentMetadata?.["word_index"];
  const vocabularySize = sentimentMetadata?.["vocabulary_size"];

  const generateSentiment = (text) => {
    // Convert to lower case and remove all punctuations.
    const inputText = text
      .trim()
      .toLowerCase()
      .replace(/(\.|\,|\!)/g, "")
      .split(" ");
    // Convert the words to a sequence of word indices.
    const sequence = inputText.map((word) => {
      let swordIndex = wordIndex[word] + indexFrom;
      if (swordIndex > vocabularySize) {
        swordIndex = OOV_INDEX;
      }
      return swordIndex;
    });
    // Perform truncation and padding.
    const paddedSequence = padSequences([sequence], maxLen);
    const input = tf.tensor2d(paddedSequence, [1, maxLen]);

    const beginMs = performance.now();
    const predictOut = sentimentModel.predict(input);
    const score = predictOut.dataSync()[0];
    predictOut.dispose();
    const endMs = performance.now();

    return { score: score, elapsed: endMs - beginMs };
  };

  const getSentiment = (text) => {
    let score;
    if (modelReady) {
      score = generateSentiment(text)?.score || 1;
    } else {
      score = 1;
    }
    return score;
  };

  const value = {
    getSentiment,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
