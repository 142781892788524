import { getDocs, collection, query, orderBy } from "firebase/firestore";
import { Firestore } from "firebase_";
import Sentiment from "sentiment";
import { getData, storeData } from "utils/functions";
import openAiService from "./openai";

const apikey = "24caa0b591918de3438845394255e99b";

const sentiment = new Sentiment();

const __DEV__ = process.env.NODE_ENV === "development";

class NewsService {
  findSentiment = (content) => {
    const result = sentiment.analyze(content);
    return result.score;
  };

  parseError = (error) => {
    if (!error) {
      return "There was an error";
    }
    return typeof error === "string" ? error : JSON.stringify(error);
  };

  getArticles = async (
    category,
    useSentiment = false,
    locale = "en",
    getInsightsFromGpt = false
  ) => {
    if (__DEV__) {
      let storedNews = await getData(`${category}___news`);
      if (storedNews?.length) {
        return { data: storedNews, error: null };
      }
    }
    try {
      let articles = await fetch(
        "https://gnews.io/api/v4/top-headlines?token=" +
          apikey +
          `&lang=${locale}&max=10&category=${category}`
      );
      let data = await articles.json();
      let allArticles = data.articles;
      if (getInsightsFromGpt) {
        let renderArticles = [];
        for (let i = 0; i < allArticles?.length; i++) {
          const data = await openAiService.isAValidEconomicArticle(
            allArticles[i],
            locale
          );
          if (data) {
            if (renderArticles.length < 5) {
              allArticles[i].gptData = data;
              renderArticles.push(allArticles[i]);
              if (renderArticles.length === 5) {
                allArticles = renderArticles;
                break;
              }
            }
          }
        }
      }
      if (__DEV__ && allArticles?.length) {
        storeData(`${category}___news`, allArticles);
      }
      return { data: allArticles, error: null };
    } catch (error) {
      console.log({ error });
      return { data: null, error };
    }
  };

  getSearchedArticles = async (q, locale = "en") => {
    try {
      let articles = await fetch(
        `https://gnews.io/api/v4/search?q=${q}&lang=${locale}&max=5&apikey=${apikey}`
      );
      let data = await articles.json();
      let allArticles = data.articles;
      let renderArticles = [];
      for (let i = 0; i < allArticles?.length; i++) {
        const data = await openAiService.isAValidEconomicArticle(
          allArticles[i],
          locale
        );
        if (data) {
          if (renderArticles.length < 5) {
            allArticles[i].gptData = data;
            renderArticles.push(allArticles[i]);
            if (renderArticles.length === 5) {
              allArticles = renderArticles;
              break;
            }
          }
        }
      }
      return { data: allArticles, error: null };
    } catch (error) {
      return { data: null, error };
    }
  };

  getBigWins = async (user) => {
    try {
      const list = [];
      const postsCollection = collection(Firestore, "posts");
      const querySnapshot = await getDocs(
        query(postsCollection, orderBy("createdAt", "desc"))
      );
      querySnapshot.forEach((document) => {
        const t = document.get("blockedBy");
        if (t && t.indexOf(user?.uid) === -1) {
          list.push({ ...document.data(), id: document.id });
        }
        if (!t) {
          list.push({ ...document.data(), id: document.id });
        }
      });
      return { data: list, error: null };
    } catch (e) {
      return { data: null, error: this.parseError(e) };
    }
    //   .then(querySnapshot => {
    //     /* ... */
    //     setPosts(list);
  };

  getAllArticlesAndInspiring = async (user) => {
    try {
      const list = [];
      const postsCollection = collection(Firestore, "posts");
      const querySnapshot = await getDocs(
        query(postsCollection, orderBy("createdAt", "desc"))
      );
      querySnapshot.forEach((document) => {
        const t = document.get("blockedBy");
        if (t && t.indexOf(user?.uid) === -1) {
          list.push({ ...document.data(), id: document.id });
        }
        if (!t) {
          list.push({ ...document.data(), id: document.id });
        }
      });
      return { data: list, error: null };
    } catch (e) {
      return { data: null, error: this.parseError(e) };
    }
    //   .then(querySnapshot => {
    //     /* ... */
    //     setPosts(list);
  };
}

const newsService = new NewsService();

export default newsService;
