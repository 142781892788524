const appRoutes = {
	login: "/auth/login",
	register: "/auth/register",
	economy: "/economy",
	terms: "/terms",
	goodnews: "/goodnews",
	search: "/search",
	addpost: "/addpost",
	setting: "/setting",
	worldpost: "/worldpost",
	communityposts: "/goodnews/community-posts",
	newspapers: "/goodnews/newspapers",
	privacy: "/privacy",
};

export default appRoutes;
