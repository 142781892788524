const translations = {
  en: {
    latest_news: "Latest News",
    translate: "Translate",
    contact_us: "Contact us",
    logout: "Logout",
    delete_account: "Delete Account",
    pick_an_image: "Pick an image",
    upload_image: "Upload image",
    newspapers: "Newspapers",
    have_questions_or_comments: "Have questions/comments?",
    settings: "Settings",
    fetching_articles:
      "Fetching Articles...Please wait for 30 seconds as we are creating customized feed of articles for you. Tap on graphs to view impact on the economy of news once loaded.",
    select_app_language: "Select App Language",
    type_language_here: "Type language here...",
    search: "Search",
    free_trial_expired:
      "Your Free Trial has expired. Please buy subscription from settings",
    prompt_to_search: "No {{news_category}} news yet, search it!",
    community_posts: "Community posts",
    economy: "economy",
    general: "General",
    world: "World",
    nation: "Nation",
    business: "Business",
    technology: "Technology",
    entertainment: "Entertainment",
    sports: "Sports",
    health: "Health",
    science: "Science",
    image_upload_prompt: "Choose image from gallery or camera",
    camera: "CAMERA",
    gallery: "GALLERY",
    undefined: "Recent Searches",
    cancel: "Cancel",
    alert: "Alert",
    view: "View",
    Economy: "Economy",
    Search: "Search",
    "Add Post": "Add post",
    Settings: "Settings",
    "Select GoodNews Preferences": "Select GoodNews Preferences",
    search_here: "Search economy news here",
    goodnews_preferences_prompt: "Select your interests.",
    "Read and share uplifting and economy news":
      "Read and share uplifting and economy news",
    Email: "Email",
    Password: "Password",
    login: "Log in",
    sign_in_as_guest: "Sign in as Guest",
    dont_have_an_account: "Don't have an account ?",
    sign_up: "Sign up",
    by_signing_in: "By signing in, you agree to our terms",
    interested_in_becoming_a_goodnews_reporter:
      "Interested in becoming a GoodNews reporter ?",
    goodnews_terms_information: "GoodNews Terms Information",
    username: "Username",
    confirm_password: "Confirm Password",
    register: "Register",
    terms: "Terms",
    term1:
      "News articles published by users should have the mininum amount of grammar errors possible.",
    term2:
      "Users should only publish news articles, such as those published by news providers like BBC that has a source, title and content.",
    term3:
      "GoodNews App has the right to moderate news of journalists if they contain hate speech, sensitive or unrelated content.",
    term4:
      "Your news post may not be shown automatically, the order of articles is randomized.",
    term5: "The accuracy of news positivity varies.",
    term6:
      "Website of post should start with ‘http://www…’ for hyperlink to work.",
    term7:
      "Users agree to terms (EULA) where there is no tolerance for objectionable content or abusive users. Goodnews will act on objectionable content reports within 24 hours by removing the content and ejecting the user who provided the offending content.",
    term8: "Only logged in users who have created accounts can post.",
    term9:
      "Economy Feed and Search takes some time to load. Please test in your free trial before purchasing.",
    term10: "Some articles don't have shifts and will show in graphs results.",
    big_wins: "BIG WINS",
    all_news_articles: "ALL NEWS, ARTICLES AND INSPIRING STORIES",
    block: "Block",
    report: "Report",
    share: "Share",
    comment: "Comment",
    submit: "Submit",
    like: "Like",
    likes: "likes",
    Title: "Title",
    Content: "Content",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Source",
    enter_a_valid_value: "Please enter a valid value",
    create_an_account_to_create_post:
      "Please create an account to create a post",
    post_added_successfully: "Post added successfully",
    there_was_an_error: "There was an error",
    failed_to_translate: "Failed to translate",
    show_more: "Show more",
    show_less: "Show less",
    successful: "Successful!",
    unlike: "Unlike",
    no_goodnews_available: "No Good News available online right now",
    feedback: "Feedback",
    welcome_good_news_msg: `Let’s embrace the warmth of positive news, for it is not just a
    fleeting feel-good moment; it is a catalyst for a more
    compassionate, connected, and hopeful world.`,
    welcome_good_news: "Welcome to GoodNews",
    real_estate: "Real Estate",
    energy: "Energy",
    climate: "Climate",
    retail: "Retail",
    small_business: "Small Business",
    healthcare: "Healthcare",
    aerospace: "Aerospace",
    defense: "Defense",
    crypto: "Crypto",
    startup: "Startups",
  },
  fr: {
    latest_news: "Dernières Nouvelles",
    translate: "Traduire",
    contact_us: "Nous contacter",
    logout: "Déconnexion",
    delete_account: "Supprimer le compte",
    pick_an_image: "Choisissez une image",
    upload_image: "Télécharger une image",
    newspapers: "Journaux",
    have_questions_or_comments: "Avez-vous des questions/commentaires ?",
    settings: "Paramètres",
    fetching_articles:
      "Récupération des articles... Veuillez patienter pendant 30 secondes pendant que nous créons un flux d'articles personnalisé pour vous. Appuyez sur les graphiques pour voir l'impact sur l'économie des nouvelles une fois chargées.",
    select_app_language: "Sélectionnez la langue de l'application",
    type_language_here: "Tapez la langue ici...",
    search: "Chercher",
    free_trial_expired:
      "Votre période d'essai gratuit a expiré. Veuillez acheter un abonnement depuis les paramètres",
    prompt_to_search:
      "Pas encore de nouvelles {{news_category}}, recherchez-le !",
    community_posts: "Publications de la communauté",
    economy: "économie",
    general: "Général",
    world: "Monde",
    nation: "Nation",
    business: "Affaires",
    technology: "Technologie",
    entertainment: "Divertissement",
    sports: "Sports",
    health: "Santé",
    science: "Science",
    image_upload_prompt:
      "Choisissez une image dans la galerie ou l'appareil photo",
    camera: "CAMÉRA",
    gallery: "GALERIE",
    undefined: "Recherches récentes",
    cancel: "Annuler",
    alert: "Alerte",
    view: "Voir",
    Economy: "Économie",
    Search: "Recherche",
    "Add Post": "Ajouter message",
    Settings: "Paramètres",
    "Select GoodNews Preferences": "Sélectionnez les préférences GoodNews",
    search_here: "Recherchez l’actualité économique ici",
    goodnews_preferences_prompt: "Sélectionnez vos intérêts.",
    "Read and share uplifting and economy news":
      "Lire et partager des nouvelles inspirantes et économiques",
    Email: "E-mail",
    Password: "Mot de passe",
    login: "Se connecter",
    sign_in_as_guest: "Se connecter en tant qu'invité",
    dont_have_an_account: "Vous n'avez pas de compte ?",
    sign_up: "S'inscrire",
    by_signing_in: "En vous connectant, vous acceptez nos conditions",
    interested_in_becoming_a_goodnews_reporter:
      "Intéressé à devenir un reporter pour GoodNews ?",
    goodnews_terms_information: "Informations sur les termes de GoodNews",
    username: "Nom d'utilisateur",
    confirm_password: "Confirmez le mot de passe",
    register: "S'inscrire",
    terms: "Termes",
    term1:
      "Les articles de presse publiés par les utilisateurs doivent avoir le moins d'erreurs grammaticales possible.",
    term2:
      "Les utilisateurs ne doivent publier que des articles de presse, comme ceux publiés par des fournisseurs d'informations comme la BBC, qui ont une source, un titre et un contenu.",
    term3:
      "L'application GoodNews a le droit de modérer les nouvelles des journalistes si elles contiennent des discours de haine, du contenu sensible ou non pertinent.",
    term4:
      "Votre article de presse peut ne pas être affiché automatiquement, l'ordre des articles est aléatoire.",
    term5: "La précision de la positivité des nouvelles varie.",
    term6:
      "Le site Web de la publication doit commencer par 'http://www...' pour que le lien hypertexte fonctionne.",
    term7:
      "Les utilisateurs acceptent les conditions (CLUF) où il n'y a aucune tolérance pour le contenu inacceptable ou les utilisateurs abusifs. Goodnews agira sur les rapports de contenu inacceptable dans les 24 heures en supprimant le contenu et en expulsant l'utilisateur ayant fourni le contenu offensant.",
    term8:
      "Seuls les utilisateurs connectés qui ont créé des comptes peuvent publier.",
    term9:
      "Le flux et la recherche économiques prennent un certain temps à charger. Veuillez tester lors de votre essai gratuit avant d'acheter.",
    term10:
      "Certains articles n'ont pas de changements et apparaîtront dans les résultats graphiques.",
    big_wins: "GRANDS SUCCÈS",
    all_news_articles:
      "TOUTES LES ACTUALITÉS, ARTICLES ET HISTOIRES INSPIRANTES",
    block: "Bloquer",
    report: "Signaler",
    share: "Partager",
    comment: "Commenter",
    submit: "Soumettre",
    like: "J'aime",
    likes: "aime",
    Title: "Titre",
    Content: "Contenu",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Source",
    enter_a_valid_value: "Veuillez saisir une valeur valide",
    create_an_account_to_create_post:
      "Veuillez créer un compte pour créer une publication",
    post_added_successfully: "Publication ajoutée avec succès",
    there_was_an_error: "Une erreur s'est produite",
    failed_to_translate: "Échec de la traduction",
    show_more: "Afficher plus",
    show_less: "Afficher moins",
    successful: "Succès !",
    unlike: "Je n'aime plus",
    no_goodnews_available:
      "Aucune bonne nouvelle disponible en ligne pour le moment",
    feedback: "Feedback",
    welcome_good_news_msg: `Accueillons la chaleur des nouvelles positives, car il ne s’agit pas seulement d’une
    moment de bien-être éphémère; c'est un catalyseur pour une plus grande
    un monde compatissant, connecté et plein d’espoir.`,
    welcome_good_news: "Bienvenue sur GoodNews",
    real_estate: "Immobilier",
    energy: "Énergie",
    climate: "Climat",
    retail: "Vente au détail",
    small_business: "Petite entreprise",
    healthcare: "Soins de santé",
    aerospace: "Aérospatial",
    defense: "La défense",
    crypto: "Cryptomonnaie",
    startup: "Startups",
  },
  es: {
    latest_news: "Últimas Noticias",
    translate: "Traducir",
    contact_us: "Contacto",
    logout: "Cerrar sesión",
    delete_account: "Eliminar cuenta",
    pick_an_image: "Elegir una imagen",
    upload_image: "Subir imagen",
    newspapers: "Periódicos",
    have_questions_or_comments: "¿Tienes preguntas/comentarios?",
    settings: "Configuración",
    fetching_articles:
      "Recuperando artículos... Espere 30 segundos mientras creamos un feed personalizado de artículos para usted. Toque en los gráficos para ver el impacto en la economía de las noticias una vez cargadas.",
    select_app_language: "Seleccionar idioma de la aplicación",
    type_language_here: "Escriba el idioma aquí...",
    search: "Buscar",
    free_trial_expired:
      "Su prueba gratuita ha caducado. Compre una suscripción desde la configuración",
    prompt_to_search: "¡Aún no hay noticias de {{news_category}}, búscalas!",
    community_posts: "Publicaciones de la comunidad",
    economy: "economía",
    general: "General",
    world: "Mundo",
    nation: "Nación",
    business: "Negocios",
    technology: "Tecnología",
    entertainment: "Entretenimiento",
    sports: "Deportes",
    health: "Salud",
    science: "Ciencia",
    image_upload_prompt: "Elija una imagen de la galería o la cámara",
    camera: "CÁMARA",
    gallery: "GALERÍA",
    undefined: "Búsquedas recientes",
    cancel: "Cancelar",
    alert: "Alerta",
    view: "Ver",
    Economy: "Economía",
    Search: "Búsqueda",
    "Add Post": "Agregar publicación",
    Settings: "Configuración",
    "Select GoodNews Preferences": "Seleccionar preferencias de GoodNews",
    search_here: "Busque noticias de economía aquí",
    goodnews_preferences_prompt: "Selecciona tus intereses.",
    "Read and share uplifting and economy news":
      "Leer y compartir noticias inspiradoras y económicas",
    Email: "Correo electrónico",
    Password: "Contraseña",
    login: "Iniciar sesión",
    sign_in_as_guest: "Iniciar sesión como invitado",
    dont_have_an_account: "¿No tienes una cuenta ?",
    sign_up: "Regístrate",
    by_signing_in: "Al iniciar sesión, aceptas nuestros términos",
    interested_in_becoming_a_goodnews_reporter:
      "¿Interesado en convertirse en reportero de GoodNews ?",
    goodnews_terms_information: "Información sobre los términos de GoodNews",
    username: "Nombre de usuario",
    confirm_password: "Confirmar contraseña",
    register: "Registrarse",
    terms: "Términos",
    term1:
      "Los artículos de noticias publicados por los usuarios deben tener la menor cantidad de errores gramaticales posible.",
    term2:
      "Los usuarios solo deben publicar artículos de noticias, como los publicados por proveedores de noticias como la BBC, que tienen una fuente, un título y un contenido.",
    term3:
      "La aplicación GoodNews tiene el derecho de moderar las noticias de los periodistas si contienen discursos de odio, contenido sensible o no relacionado.",
    term4:
      "Es posible que su publicación de noticias no se muestre automáticamente, el orden de los artículos es aleatorio.",
    term5: "La precisión de la positividad de las noticias varía.",
    term6:
      "El sitio web de la publicación debe comenzar con 'http://www...' para que el hipervínculo funcione.",
    term7:
      "Los usuarios aceptan los términos (EULA) donde no se tolera el contenido objetable o los usuarios abusivos. Goodnews actuará sobre informes de contenido objetable dentro de las 24 horas, eliminando el contenido y expulsando al usuario que proporcionó el contenido ofensivo.",
    term8:
      "Solo los usuarios registrados que hayan creado cuentas pueden publicar.",
    term9:
      "La alimentación y la búsqueda económica tardan un tiempo en cargarse. Pruebe en su prueba gratuita antes de comprar.",
    term10:
      "Algunos artículos no tienen cambios y aparecerán en los resultados de los gráficos.",
    big_wins: "GRANDES GANANCIAS",
    all_news_articles: "TODAS LAS NOTICIAS, ARTÍCULOS E HISTORIAS INSPIRADORAS",
    block: "Bloquear",
    report: "Informe",
    share: "Compartir",
    comment: "Comentar",
    submit: "Enviar",
    like: "Me gusta",
    likes: "me gusta",
    Title: "Título",
    Content: "Contenido",
    URL: "URL",
    Reporter: "Reportero",
    Source: "Fuente",
    enter_a_valid_value: "Por favor, introduzca un valor válido",
    create_an_account_to_create_post:
      "Por favor, cree una cuenta para crear una publicación",
    post_added_successfully: "Publicación añadida exitosamente",
    there_was_an_error: "Hubo un error",
    failed_to_translate: "Error al traducir",
    show_more: "Mostrar más",
    show_less: "Mostrar menos",
    successful: "¡Exitoso!",
    unlike: "No me gusta",
    no_goodnews_available:
      "No hay buenas noticias disponibles en línea en este momento",
    feedback: "Feedback",
    welcome_good_news_msg: `Abracemos la calidez de las noticias positivas, porque no son sólo una
    momento fugaz de bienestar; es un catalizador para una mayor
    mundo compasivo, conectado y esperanzador.`,
    welcome_good_news: "Bienvenido a Buenas Noticias",
    real_estate: "Bienes raíces",
    energy: "Energía",
    climate: "Clima",
    retail: "Minorista",
    small_business: "Pequeños negocios",
    healthcare: "Cuidado de la salud",
    aerospace: "Aeroespacial",
    defense: "Defensa",
    crypto: "Cripto",
    startup: "Inauguración",
  },
  de: {
    latest_news: "Neueste Nachrichten",
    translate: "Übersetzen",
    contact_us: "Kontaktiere uns",
    logout: "Ausloggen",
    delete_account: "Konto löschen",
    pick_an_image: "Bild auswählen",
    upload_image: "Bild hochladen",
    newspapers: "Zeitungen",
    have_questions_or_comments: "Fragen/Kommentare?",
    settings: "Einstellungen",
    fetching_articles:
      "Artikel werden abgerufen... Bitte warten Sie 30 Sekunden, während wir einen individuellen Artikel-Feed für Sie erstellen. Tippen Sie auf die Grafiken, um die Auswirkungen auf die Wirtschaft der Nachrichten anzuzeigen, sobald sie geladen sind.",
    select_app_language: "Wähle App-Sprache",
    type_language_here: "Geben Sie hier die Sprache ein...",
    search: "Suche",
    free_trial_expired:
      "Ihre kostenlose Testversion ist abgelaufen. Bitte kaufen Sie ein Abonnement in den Einstellungen",
    prompt_to_search:
      "Noch keine {{news_category}}-Nachrichten, suchen Sie danach!",
    community_posts: "Community-Beiträge",
    economy: "Wirtschaft",
    general: "Allgemein",
    world: "Welt",
    nation: "Nation",
    business: "Geschäft",
    technology: "Technologie",
    entertainment: "Unterhaltung",
    sports: "Sport",
    health: "Gesundheit",
    science: "Wissenschaft",
    image_upload_prompt:
      "Wählen Sie ein Bild aus der Galerie oder der Kamera aus",
    camera: "KAMERA",
    gallery: "GALERIE",
    undefined: "Letzte Suchanfragen",
    cancel: "Abbrechen",
    alert: "Alarm",
    view: "Anzeigen",
    Economy: "Wirtschaft",
    Search: "Suche",
    "Add Post": "Beitrag hinzufügen",
    Settings: "Einstellungen",
    "Select GoodNews Preferences": "Wählen Sie GoodNews-Einstellungen",
    search_here: "Suchen Sie hier nach Wirtschaftsnachrichten",
    goodnews_preferences_prompt: "Wählen Sie Ihre Interessen.",
    "Read and share uplifting and economy news":
      "Lesen und teilen Sie erhebende und wirtschaftliche Nachrichten",
    Email: "Email",
    Password: "Passwort",
    login: "Einloggen",
    sign_in_as_guest: "Als Gast anmelden",
    dont_have_an_account: "Sie haben kein Konto ?",
    sign_up: "Anmelden",
    by_signing_in: "Durch das Anmelden akzeptieren Sie unsere Bedingungen",
    interested_in_becoming_a_goodnews_reporter:
      "Interessiert daran, ein GoodNews-Reporter zu werden ?",
    goodnews_terms_information: "GoodNews Geschäftsbedingungen Informationen",
    username: "Benutzername",
    confirm_password: "Passwort bestätigen",
    register: "Registrieren",
    terms: "Begriffe",
    term1:
      "Nachrichtenartikel, die von Benutzern veröffentlicht werden, sollten so wenig wie möglich Grammatikfehler aufweisen.",
    term2:
      "Benutzer sollten nur Nachrichtenartikel veröffentlichen, wie die von Nachrichtenanbietern wie der BBC, die eine Quelle, einen Titel und einen Inhalt haben.",
    term3:
      "Die GoodNews App hat das Recht, Nachrichten von Journalisten zu moderieren, wenn sie Hassreden, sensiblen oder nicht verwandten Inhalt enthalten.",
    term4:
      "Ihr Nachrichtenbeitrag wird möglicherweise nicht automatisch angezeigt, die Reihenfolge der Artikel ist zufällig.",
    term5: "Die Genauigkeit der positiven Nachrichten variiert.",
    term6:
      "Die Website des Beitrags sollte mit 'http://www...' beginnen, damit der Hyperlink funktioniert.",
    term7:
      "Benutzer stimmen den Bedingungen (EULA) zu, bei denen es keine Toleranz für anstößige Inhalte oder missbräuchliche Benutzer gibt. Goodnews wird innerhalb von 24 Stunden auf Berichte über anstößige Inhalte reagieren, indem der Inhalt entfernt und der Benutzer, der den anstößigen Inhalt bereitgestellt hat, ausgeschlossen wird.",
    term8:
      "Nur eingeloggte Benutzer, die Konten erstellt haben, können Beiträge veröffentlichen.",
    term9:
      "Das Economy-Feed und die Suche benötigen einige Zeit zum Laden. Bitte testen Sie es während Ihrer kostenlosen Testversion, bevor Sie es kaufen.",
    term10:
      "Einige Artikel haben keine Verschiebungen und werden in den Graphenergebnissen angezeigt.",
    big_wins: "GROßE GEWINNE",
    all_news_articles:
      "ALLE NACHRICHTEN, ARTIKEL UND INSPIRIERENDEN GESCHICHTEN",
    block: "Blockieren",
    report: "Bericht",
    share: "Teilen",
    comment: "Kommentieren",
    submit: "Einreichen",
    like: "Gefällt mir",
    likes: "gefällt",
    Title: "Titel",
    Content: "Inhalt",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Quelle",
    enter_a_valid_value: "Bitte geben Sie einen gültigen Wert ein",
    create_an_account_to_create_post:
      "Bitte erstellen Sie ein Konto, um einen Beitrag zu erstellen",
    post_added_successfully: "Beitrag erfolgreich hinzugefügt",
    there_was_an_error: "Es gab einen Fehler",
    failed_to_translate: "Übersetzung fehlgeschlagen",
    show_more: "Mehr anzeigen",
    show_less: "Weniger anzeigen",
    successful: "Erfolgreich!",
    unlike: "Entliken",
    no_goodnews_available:
      "Derzeit sind keine guten Nachrichten online verfügbar",
    feedback: "Feedback",
    welcome_good_news_msg: `Lassen Sie uns die Wärme positiver Nachrichten annehmen, denn es handelt sich nicht nur um eine
    flüchtiger Wohlfühlmoment; es ist ein Katalysator für mehr
    mitfühlende, verbundene und hoffnungsvolle Welt.`,
    welcome_good_news: "Willkommen bei GoodNews",
    real_estate: "Immobilie",
    energy: "Energie",
    climate: "Klima",
    retail: "Einzelhandel",
    small_business: "Kleinbetrieb",
    healthcare: "Gesundheitspflege",
    aerospace: "Luft- und Raumfahrt",
    defense: "Verteidigung",
    crypto: "Krypto",
    startup: "Startups",
  },
  ar: {
    latest_news: "أحدث الأخبار",
    translate: "ترجمة",
    contact_us: "اتصل بنا",
    logout: "تسجيل الخروج",
    delete_account: "حذف الحساب",
    pick_an_image: "اختر صورة",
    upload_image: "تحميل صورة",
    newspapers: "صحف",
    have_questions_or_comments: "هل لديك أسئلة / تعليقات؟",
    settings: "الإعدادات",
    fetching_articles:
      "جلب المقالات... يرجى الانتظار لمدة 30 ثانية بينما نقوم بإنشاء تغذية مخصصة من المقالات لك. اضغط على الرسوم البيانية لعرض تأثير الأخبار على الاقتصاد بمجرد التحميل.",
    select_app_language: "اختر لغة التطبيق",
    type_language_here: "اكتب اللغة هنا...",
    search: "بحث",
    free_trial_expired:
      "انتهت فترة التجربة المجانية الخاصة بك. يرجى شراء اشتراك من الإعدادات",
    prompt_to_search: "لا توجد أخبار {{news_category}} بعد ، ابحث عنها!",
    community_posts: "مشاركات المجتمع",
    economy: "اقتصاد",
    general: "عام",
    world: "العالم",
    nation: "الأمة",
    business: "أعمال",
    technology: "تكنولوجيا",
    entertainment: "ترفيه",
    sports: "رياضة",
    health: "صحة",
    science: "علم",
    image_upload_prompt: "اختر صورة من المعرض أو الكاميرا",
    camera: "كاميرا",
    gallery: "معرض",
    undefined: "البحث الأخيرة",
    cancel: "إلغاء",
    alert: "تنبيه",
    view: "عرض",
    Economy: "اقتصاد",
    Search: "بحث",
    "Add Post": "أضف مشاركة",
    Settings: "الإعدادات",
    "Select GoodNews Preferences": "حدد تفضيلات GoodNews",
    search_here: "ابحث عن أخبار الاقتصاد هنا",
    goodnews_preferences_prompt: "حدد اهتماماتك.",
    "Read and share uplifting and economy news":
      "قراءة ومشاركة الأخبار الملهمة والاقتصادية",
    Email: "البريد الإلكتروني",
    Password: "كلمه السر",
    login: "تسجيل الدخول",
    sign_in_as_guest: "تسجيل الدخول كضيف",
    dont_have_an_account: "ليس لديك حساب ؟",
    sign_up: "سجل",
    by_signing_in: "من خلال تسجيل الدخول ، فإنك توافق على شروطنا",
    interested_in_becoming_a_goodnews_reporter:
      "هل أنت مهتم بأن تصبح صحفيًا لجريدة GoodNews ؟",
    goodnews_terms_information: "معلومات شروط GoodNews",
    username: "اسم المستخدم",
    confirm_password: "تأكيد كلمة المرور",
    register: "تسجيل",
    terms: "شروط",
    term1:
      "يجب أن تحتوي المقالات الإخبارية التي ينشرها المستخدمون على الحد الأدنى من الأخطاء النحوية الممكنة.",
    term2:
      "يجب على المستخدمين نشر مقالات الأخبار فقط ، مثل تلك التي تنشرها موفرو الأخبار مثل بي بي سي التي تحتوي على مصدر وعنوان ومحتوى.",
    term3:
      "تحتفظ تطبيق GoodNews بالحق في تنظيم أخبار الصحفيين إذا احتوت على خطابات كراهية أو محتوى حساس أو غير متصل.",
    term4:
      "قد لا يتم عرض مشاركتك في الأخبار تلقائيًا ، وترتيب المقالات معتمد على الصدفة.",
    term5: "دقة إيجابية الأخبار تختلف.",
    term6:
      "يجب أن يبدأ موقع المنشور بـ 'http://www...' لتعمل الارتباطات التشعبية.",
    term7:
      "يوافق المستخدمون على الشروط (EULA) حيث لا يوجد تسامح مع المحتوى غير المقبول أو المستخدمين المسيئين. ستتصرف Goodnews في تقارير المحتوى غير المقبول في غضون 24 ساعة عن طريق إزالة المحتوى وطرد المستخدم الذي قدم المحتوى المسيء.",
    term8:
      "يمكن للمستخدمين الذين قاموا بتسجيل الدخول فقط والذين قاموا بإنشاء حساباتهم بالنشر.",
    term9:
      "يستغرق البحث والتغذية الاقتصادية بعض الوقت للتحميل. يرجى اختباره في التجربة المجانية قبل الشراء.",
    term10: "بعض المقالات ليست لها تحولات وستظهر في نتائج الرسوم البيانية.",
    big_wins: "انتصارات كبيرة",
    all_news_articles: "جميع الأخبار والمقالات والقصص الملهمة",
    block: "منع",
    report: "أبلغ",
    share: "شارك",
    comment: "تعليق",
    submit: "تقديم",
    like: "إعجاب",
    likes: "يحب",
    Title: "عنوان",
    Content: "محتوى",
    URL: "رابط",
    Reporter: "صحفي",
    Source: "مصدر",
    enter_a_valid_value: "الرجاء إدخال قيمة صالحة",
    create_an_account_to_create_post: "يرجى إنشاء حساب لإنشاء منشور",
    post_added_successfully: "تمت إضافة المنشور بنجاح",
    there_was_an_error: "حدث خطأ",
    failed_to_translate: "فشل الترجمة",
    show_more: "أظهر المزيد",
    show_less: "أظهر أقل",
    successful: "ناجح!",
    unlike: "غير معجب",
    no_goodnews_available:
      "لا توجد أخبار جيدة متاحة على الإنترنت في الوقت الحالي",
    feedback: "Feedback",
    welcome_good_news_msg: `دعونا نحتضن دفء الأخبار الإيجابية، فهي ليست مجرد
    لحظة شعور بالسعادة عابرة؛ إنه حافز للمزيد
    عالم رحيم ومتصل ومفعم بالأمل.`,
    welcome_good_news: "مرحبا بكم في أخبار جيدة",
    real_estate: "العقارات",
    energy: "طاقة",
    climate: "مناخ",
    retail: "بيع بالتجزئة",
    small_business: "أعمال صغيرة",
    healthcare: "الرعاىة الصحية",
    aerospace: "الفضاء الجوي",
    defense: "دفاع",
    crypto: "تشفير",
    startup: "الشركات الناشئة",
  },
  zh: {
    latest_news: "最新消息",
    translate: "翻译",
    contact_us: "联系我们",
    logout: "登出",
    delete_account: "删除帐户",
    pick_an_image: "选择图片",
    upload_image: "上传图片",
    newspapers: "报纸",
    have_questions_or_comments: "有问题/评论吗？",
    settings: "设置",
    fetching_articles:
      "获取文章中... 请稍候30秒，因为我们正在为您创建定制的文章源。加载完成后，点击图表查看新闻对经济的影响。",
    select_app_language: "选择应用程序语言",
    type_language_here: "在这里键入语言...",
    search: "搜索",
    free_trial_expired: "您的免费试用已过期。请从设置中购买订阅",
    prompt_to_search: "尚无{{news_category}}新闻，搜索一下！",
    community_posts: "社区帖子",
    economy: "经济",
    general: "一般",
    world: "世界",
    nation: "国家",
    business: "商业",
    technology: "科技",
    entertainment: "娱乐",
    sports: "体育",
    health: "健康",
    science: "科学",
    image_upload_prompt: "从图库或相机选择图片",
    camera: "相机",
    gallery: "画廊",
    undefined: "最近搜索",
    cancel: "取消",
    alert: "警报",
    view: "查看",
    Economy: "经济",
    Search: "搜索",
    "Add Post": "添加帖子",
    Settings: "设置",
    "Select GoodNews Preferences": "选择GoodNews首选项",
    search_here: "在这里搜索经济新闻",
    goodnews_preferences_prompt: "选择你的兴趣。",
    "Read and share uplifting and economy news": "阅读并分享振奋人心的经济新闻",
    Email: "电子邮件",
    Password: "密码",
    login: "登录",
    sign_in_as_guest: "以访客身份登录",
    dont_have_an_account: "没有帐号 ?",
    sign_up: "报名",
    by_signing_in: "通过登录，即表示您同意我们的条款",
    interested_in_becoming_a_goodnews_reporter: "有兴趣成为GoodNews记者吗？",
    goodnews_terms_information: "GoodNews条款信息",
    username: "用户名",
    confirm_password: "确认密码",
    register: "寄存器",
    terms: "条款",
    term1: "用户发布的新闻文章应该具有尽可能少的语法错误。",
    term2:
      "用户只应发布新闻文章，例如像BBC这样的新闻提供商发布的那些具有来源、标题和内容的文章。",
    term3:
      "GoodNews App有权审核记者的新闻，如果它们包含仇恨言论、敏感或不相关的内容。",
    term4: "您的新闻帖子可能不会自动显示，文章的顺序是随机的。",
    term5: "新闻积极性的准确性各不相同。",
    term6: "帖子的网站应以'http://www...'开头才能使超链接起作用。",
    term7:
      "用户同意条款（EULA），不容忍令人反感的内容或滥用用户。 Goodnews 将在 24 小时内处理令人反感的内容报告，删除内容并驱逐提供冒犯性内容的用户。",
    term8: "只有登录并创建了帐户的用户才能发布。",
    term9: "经济动态和搜索需要一些时间来加载。 请在购买前进行免费试用测试。",
    term10: "一些文章没有转变，并将显示在图形结果中。",
    big_wins: "巨大胜利",
    all_news_articles: "所有新闻，文章和鼓舞人心的故事",
    block: "封锁",
    report: "报告",
    share: "分享",
    comment: "评论",
    submit: "提交",
    like: "喜欢",
    likes: "喜欢",
    Title: "标题",
    Content: "内容",
    URL: "链接",
    Reporter: "记者",
    Source: "来源",
    enter_a_valid_value: "请输入有效值",
    create_an_account_to_create_post: "请创建帐户以创建帖子",
    post_added_successfully: "帖子成功添加",
    there_was_an_error: "发生错误",
    failed_to_translate: "翻译失败",
    show_more: "显示更多",
    show_less: "显示更少",
    successful: "成功！",
    unlike: "取消赞",
    no_goodnews_available: "目前网上没有好消息",
    feedback: "Feedback",
    welcome_good_news_msg: `让我们拥抱温暖的正面消息，因为它不仅仅是一个
    转瞬即逝的感觉良好的时刻； 它是更多的催化剂
    富有同情心、相互联系、充满希望的世界。`,
    welcome_good_news: "欢迎来到好消息",
    real_estate: "房地产",
    energy: "活力",
    climate: "气候",
    retail: "零售",
    small_business: "小本生意",
    healthcare: "卫生保健",
    aerospace: "航天",
    defense: "防御",
    crypto: "加密货币",
    startup: "初创公司",
  },
  pt: {
    latest_news: "Últimas Notícias",
    translate: "Traduzir",
    contact_us: "Contate-nos",
    logout: "Sair",
    delete_account: "Excluir conta",
    pick_an_image: "Escolha uma imagem",
    upload_image: "Enviar imagem",
    newspapers: "Jornais",
    have_questions_or_comments: "Tem perguntas/comentários?",
    settings: "Configurações",
    fetching_articles:
      "Obtendo artigos... Por favor, aguarde 30 segundos enquanto criamos um feed personalizado de artigos para você. Toque nos gráficos para ver o impacto na economia das notícias assim que carregadas.",
    select_app_language: "Selecionar Idioma do Aplicativo",
    type_language_here: "Digite o idioma aqui...",
    search: "Pesquisar",
    free_trial_expired:
      "O seu período de avaliação gratuita expirou. Por favor, compre uma assinatura nas configurações",
    prompt_to_search: "Ainda não há notícias {{news_category}}, pesquise!",
    community_posts: "Publicações da comunidade",
    economy: "economia",
    general: "Geral",
    world: "Mundo",
    nation: "Nação",
    business: "Negócios",
    technology: "Tecnologia",
    entertainment: "Entretenimento",
    sports: "Esportes",
    health: "Saúde",
    science: "Ciência",
    image_upload_prompt: "Escolha uma imagem da galeria ou da câmera",
    camera: "CÂMERA",
    gallery: "GALERIA",
    undefined: "Pesquisas recentes",
    cancel: "Cancelar",
    alert: "Alerta",
    view: "Ver",
    Economy: "Economia",
    Search: "Pesquisa",
    "Add Post": "Adicionar postagem",
    Settings: "Configurações",
    "Select GoodNews Preferences": "Selecione as preferências do GoodNews",
    search_here: "Pesquise notícias de economia aqui",
    goodnews_preferences_prompt: "Selecione seus interesses.",
    "Read and share uplifting and economy news":
      "Leia e compartilhe notícias inspiradoras e econômicas",
    Email: "E-mail",
    Password: "Senha",
    login: "Entrar",
    sign_in_as_guest: "Entrar como convidado",
    dont_have_an_account: "Não tem uma conta ?",
    sign_up: "Inscrever-se",
    by_signing_in: "Ao iniciar sessão, concorda com os nossos termos",
    interested_in_becoming_a_goodnews_reporter:
      "Interessado em se tornar um repórter do GoodNews ?",
    goodnews_terms_information: "Informações sobre os termos do GoodNews",
    username: "Nome de usuário",
    confirm_password: "Confirmar senha",
    register: "Registrar-se",
    terms: "Termos",
    term1:
      "Os artigos de notícias publicados pelos usuários devem ter o mínimo possível de erros gramaticais.",
    term2:
      "Os usuários devem publicar apenas artigos de notícias, como os publicados por fornecedores de notícias como a BBC, que têm uma fonte, um título e um conteúdo.",
    term3:
      "O aplicativo GoodNews tem o direito de moderar as notícias de jornalistas se elas contiverem discurso de ódio, conteúdo sensível ou não relacionado.",
    term4:
      "A sua publicação de notícias pode não ser mostrada automaticamente, a ordem dos artigos é aleatória.",
    term5: "A precisão da positividade das notícias varia.",
    term6:
      "O site da postagem deve começar com 'http://www...' para que o hiperlink funcione.",
    term7:
      "Os usuários concordam com os termos (EULA) onde não há tolerância para conteúdo objetável ou usuários abusivos. O Goodnews agirá em relatórios de conteúdo objetável dentro de 24 horas, removendo o conteúdo e expulsando o usuário que forneceu o conteúdo ofensivo.",
    term8: "Apenas usuários logados que criaram contas podem postar.",
    term9:
      "A alimentação e a pesquisa econômica levam algum tempo para carregar. Por favor, teste em seu teste gratuito antes de comprar.",
    term10:
      "Alguns artigos não têm mudanças e serão mostrados nos resultados dos gráficos.",
    big_wins: "GRANDES VITÓRIAS",
    all_news_articles: "TODAS AS NOTÍCIAS, ARTIGOS E HISTÓRIAS INSPIRADORAS",
    block: "Bloquear",
    report: "Relatar",
    share: "Compartilhar",
    comment: "Comentar",
    submit: "Enviar",
    like: "Curtir",
    likes: "curtidas",
    Title: "Título",
    Content: "Conteúdo",
    URL: "URL",
    Reporter: "Repórter",
    Source: "Fonte",
    enter_a_valid_value: "Por favor, insira um valor válido",
    create_an_account_to_create_post:
      "Por favor, crie uma conta para criar uma postagem",
    post_added_successfully: "Postagem adicionada com sucesso",
    there_was_an_error: "Houve um erro",
    failed_to_translate: "Falha ao traduzir",
    show_more: "Mostrar mais",
    show_less: "Mostrar menos",
    successful: "Bem-sucedido!",
    unlike: "Descurtir",
    no_goodnews_available: "Nenhuma boa notícia disponível online no momento",
    feedback: "Feedback",
    welcome_good_news_msg: `Vamos abraçar o calor das notícias positivas, pois não são apenas
    momento fugaz de bem-estar; é um catalisador para um mais
    mundo compassivo, conectado e esperançoso.`,
    welcome_good_news: "Bem-vindo ao GoodNews",
    real_estate: "Imobiliária",
    energy: "Energia",
    climate: "Clima",
    retail: "Varejo",
    small_business: "Pequenos negócios",
    healthcare: "Assistência médica",
    aerospace: "Aeroespacial",
    defense: "Defesa",
    crypto: "Criptografia",
    startup: "Iniciantes",
  },
  it: {
    latest_news: "Ultime Notizie",
    translate: "Traduci",
    contact_us: "Contattaci",
    logout: "Disconnettersi",
    delete_account: "Elimina account",
    pick_an_image: "Scegli un'immagine",
    upload_image: "Carica immagine",
    newspapers: "Giornali",
    have_questions_or_comments: "Hai domande/commenti?",
    settings: "Impostazioni",
    fetching_articles:
      "Recupero degli articoli... Attendere 30 secondi mentre creiamo un feed personalizzato di articoli per te. Tocca i grafici per visualizzare l'impatto sull'economia delle notizie una volta caricato.",
    select_app_language: "Seleziona la lingua dell'app",
    type_language_here: "Digita qui la lingua...",
    search: "Ricerca",
    free_trial_expired:
      "Il tuo periodo di prova gratuito è scaduto. Si prega di acquistare un abbonamento dalle impostazioni",
    prompt_to_search: "Ancora nessuna notizia {{news_category}}, cercala!",
    community_posts: "Post della comunità",
    economy: "economia",
    general: "Generale",
    world: "Mondo",
    nation: "Nazione",
    business: "Affari",
    technology: "Tecnologia",
    entertainment: "Intrattenimento",
    sports: "Sport",
    health: "Salute",
    science: "Scienza",
    image_upload_prompt: "Scegli un'immagine dalla galleria o dalla fotocamera",
    camera: "FOTOCAMERA",
    gallery: "GALLERIA",
    undefined: "Ricerche recenti",
    cancel: "Annulla",
    alert: "Allarme",
    view: "Visualizza",
    Economy: "Economia",
    Search: "Ricerca",
    "Add Post": "Aggiungi post",
    Settings: "Impostazioni",
    "Select GoodNews Preferences": "Seleziona le preferenze di GoodNews",
    search_here: "Cerca notizie economiche qui",
    goodnews_preferences_prompt: "Seleziona i tuoi interessi.",
    "Read and share uplifting and economy news":
      "Leggi e condividi notizie edificanti ed economiche",
    Email: "E-mail",
    Password: "Parola d'ordine",
    login: "Accesso",
    sign_in_as_guest: "Accedi come ospite",
    dont_have_an_account: "Non hai un account ?",
    sign_up: "Iscriviti",
    by_signing_in: "Accedendo, accetti i nostri termini",
    interested_in_becoming_a_goodnews_reporter:
      "Interessato a diventare un reporter di GoodNews ?",
    goodnews_terms_information: "Informazioni sui termini di GoodNews",
    username: "Nome utente",
    confirm_password: "Conferma password",
    register: "Registrati",
    terms: "Condizioni",
    term1:
      "Gli articoli di notizie pubblicati dagli utenti dovrebbero avere il minimo possibile di errori grammaticali.",
    term2:
      "Gli utenti dovrebbero pubblicare solo articoli di notizie, come quelli pubblicati da fornitori di notizie come la BBC, che hanno una fonte, un titolo e un contenuto.",
    term3:
      "GoodNews App ha il diritto di moderare le notizie dei giornalisti se contengono discorsi di odio, contenuti sensibili o non correlati.",
    term4:
      "Il tuo post di notizie potrebbe non essere mostrato automaticamente, l'ordine degli articoli è casuale.",
    term5: "L'accuratezza della positività delle notizie varia.",
    term6:
      "Il sito web del post dovrebbe iniziare con 'http://www...' affinché il collegamento ipertestuale funzioni.",
    term7:
      "Gli utenti accettano i termini (EULA) in cui non vi è tolleranza per contenuti o utenti offensivi. Goodnews agirà su segnalazioni di contenuti offensivi entro 24 ore rimuovendo il contenuto e cacciando l'utente che ha fornito il contenuto offensivo.",
    term8:
      "Solo gli utenti che hanno effettuato l'accesso e creato account possono pubblicare.",
    term9:
      "Il feed economico e la ricerca richiedono del tempo per caricare. Si prega di testare nel proprio periodo di prova gratuito prima di acquistare.",
    term10:
      "Alcuni articoli non hanno cambiamenti e verranno mostrati nei risultati dei grafici.",
    big_wins: "GRANDI VITTORIE",
    all_news_articles: "TUTTE LE NOTIZIE, GLI ARTICOLI E LE STORIE ISPIRANTI",
    block: "Blocca",
    report: "Segnala",
    share: "Condividere",
    comment: "Commenta",
    submit: "Invia",
    like: "Mi piace",
    likes: "mi piace",
    Title: "Titolo",
    Content: "Contenuto",
    URL: "URL",
    Reporter: "Giornalista",
    Source: "Fonte",
    enter_a_valid_value: "Si prega di inserire un valore valido",
    create_an_account_to_create_post:
      "Si prega di creare un account per creare un post",
    post_added_successfully: "Post aggiunto con successo",
    there_was_an_error: "Si è verificato un errore",
    failed_to_translate: "Traduzione fallita",
    show_more: "Mostra di più",
    show_less: "Mostra di meno",
    successful: "Riuscito!",
    unlike: "Non mi piace più",
    no_goodnews_available:
      "Nessuna buona notizia disponibile online in questo momento",
    feedback: "Feedback",
    welcome_good_news_msg: `Abbracciamo il calore delle notizie positive, perché non è solo una
    fugace momento di benessere; è un catalizzatore per qualcosa di più
    mondo compassionevole, connesso e pieno di speranza.`,
    welcome_good_news: "Benvenuti a GoodNews",
    real_estate: "Immobiliare",
    energy: "Energia",
    climate: "Clima",
    retail: "Vedere al dettaglio",
    small_business: "Piccola impresa",
    healthcare: "Assistenza sanitaria",
    aerospace: "Aerospaziale",
    defense: "Difesa",
    crypto: "Criptovaluta",
    startup: "Startup",
  },
  sv: {
    latest_news: "Senaste Nyheterna",
    translate: "Översätt",
    contact_us: "Kontakta oss",
    logout: "Logga ut",
    delete_account: "Ta bort konto",
    pick_an_image: "Välj en bild",
    upload_image: "Ladda upp bild",
    newspapers: "Tidningar",
    have_questions_or_comments: "Har du frågor/kommentarer?",
    settings: "Inställningar",
    fetching_articles:
      "Hämtar artiklar... Vänta 30 sekunder medan vi skapar en anpassad flöde av artiklar för dig. Tryck på graferna för att se påverkan på ekonomin för nyheterna när de väl har laddats.",
    select_app_language: "Välj appens språk",
    type_language_here: "Skriv språk här...",
    search: "Sök",
    free_trial_expired:
      "Din gratis provperiod har gått ut. Köp en prenumeration från inställningarna",
    prompt_to_search: "Inga {{news_category}} nyheter ännu, sök det!",
    community_posts: "Samhällsinlägg",
    economy: "ekonomi",
    general: "Allmänhet",
    world: "Värld",
    nation: "Nation",
    business: "Företag",
    technology: "Teknologi",
    entertainment: "Underhållning",
    sports: "Sport",
    health: "Hälsa",
    science: "Vetenskap",
    image_upload_prompt: "Välj bild från galleri eller kamera",
    camera: "KAMERA",
    gallery: "GALLERI",
    undefined: "Senaste sökningar",
    cancel: "Avbryt",
    alert: "Varning",
    view: "Visa",
    Economy: "Ekonomi",
    Search: "Sök",
    "Add Post": "Lägg till inlägg",
    Settings: "Inställningar",
    "Select GoodNews Preferences": "Välj GoodNews-preferenser",
    search_here: "Sök ekonominyheter här",
    goodnews_preferences_prompt: "Välj dina intressen.",
    "Read and share uplifting and economy news":
      "Läs och dela uppbyggande och ekonomiska nyheter",
    Email: "E-post",
    Password: "Lösenord",
    login: "Logga in",
    sign_in_as_guest: "Logga in som gäst",
    dont_have_an_account: "Harinte ett konto ?",
    sign_up: "Anmäl dig",
    by_signing_in: "Genom att logga in godkänner du våra villkor",
    interested_in_becoming_a_goodnews_reporter:
      "Intresserad av att bli en GoodNews-reporter ?",
    goodnews_terms_information: "GoodNews-villkorsinformation",
    username: "Användarnamn",
    confirm_password: "Bekräfta lösenord",
    register: "Registrera",
    terms: "Villkor",
    term1:
      "Nyhetsartiklar publicerade av användare bör ha så lite grammatikfel som möjligt.",
    term2:
      "Användare bör endast publicera nyhetsartiklar, som de som publiceras av nyhetsleverantörer som BBC, som har en källa, en titel och innehåll.",
    term3:
      "GoodNews-appen har rätt att moderera journalisters nyheter om de innehåller hatretorik, känsligt eller orelaterat innehåll.",
    term4:
      "Ditt nyhetsinlägg kanske inte visas automatiskt, ordningen på artiklarna är slumpmässig.",
    term5: "Nyhetspositivitetens noggrannhet varierar.",
    term6:
      "Postens webbplats ska börja med 'http://www...' för att hyperlänken ska fungera.",
    term7:
      "Användare godkänner villkoren (EULA) där det inte finns något tolerans för stötande innehåll eller missbrukande användare. Goodnews kommer att agera på rapporter om stötande innehåll inom 24 timmar genom att ta bort innehållet och kasta ut användaren som tillhandahöll det stötande innehållet.",
    term8: "Endast inloggade användare som har skapat konton kan posta.",
    term9:
      "Ekonomiflödet och sökningen tar lite tid att ladda. Testa det i din kostnadsfria testperiod innan du köper.",
    term10:
      "Vissa artiklar har inga skift och kommer att visas i grafresultaten.",
    big_wins: "STORA VINSTER",
    all_news_articles: "ALLA NYHETER, ARTIKLAR OCH INSPIRERANDE HISTORIER",
    block: "Blockera",
    report: "Rapportera",
    share: "Dela",
    comment: "Kommentar",
    submit: "Skicka in",
    like: "Gilla",
    likes: "gillar",
    Title: "Titel",
    Content: "Innehåll",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Källa",
    enter_a_valid_value: "Ange ett giltigt värde",
    create_an_account_to_create_post:
      "Skapa ett konto för att skapa ett inlägg",
    post_added_successfully: "Inlägg tillagt framgångsrikt",
    there_was_an_error: "Det uppstod ett fel",
    failed_to_translate: "Misslyckades med att översätta",
    show_more: "Visa mer",
    show_less: "Visa mindre",
    successful: "Lyckad!",
    unlike: "Ogilla",
    no_goodnews_available: "Inga goda nyheter tillgängliga online just nu",
    feedback: "Feedback",
    welcome_good_news_msg: `Låt oss omfamna värmen från positiva nyheter, för det är inte bara en
    flyktigt må-bra-ögonblick; det är en katalysator för ett mer
    medkännande, uppkopplad och hoppfull värld.`,
    welcome_good_news: "Välkommen till GoodNews",
    real_estate: "Fastighet",
    energy: "Energi",
    climate: "Klimat",
    retail: "Detaljhandeln",
    small_business: "Litet företag",
    healthcare: "Sjukvård",
    aerospace: "Flyg och rymd",
    defense: "Försvar",
    crypto: "Krypto",
    startup: "Nystartade företag",
  },
  ru: {
    latest_news: "Последние Новости",
    translate: "Перевести",
    contact_us: "Свяжитесь с нами",
    logout: "Выйти",
    delete_account: "Удалить аккаунт",
    pick_an_image: "Выберите изображение",
    upload_image: "Загрузить изображение",
    newspapers: "Газеты",
    have_questions_or_comments: "Есть вопросы/комментарии?",
    settings: "Настройки",
    fetching_articles:
      "Получение статей... Пожалуйста, подождите 30 секунд, пока мы создаем настроенную ленту статей для вас. Нажмите на графики, чтобы увидеть влияние новостей на экономику после загрузки.",
    select_app_language: "Выберите язык приложения",
    type_language_here: "Введите язык здесь...",
    search: "Поиск",
    free_trial_expired:
      "Ваша бесплатная пробная версия истекла. Пожалуйста, купите подписку в настройках",
    prompt_to_search: "Пока нет новостей {{news_category}}, найдите их!",
    community_posts: "Сообщественные посты",
    economy: "экономика",
    general: "Главное",
    world: "Мир",
    nation: "Нация",
    business: "Бизнес",
    technology: "Технология",
    entertainment: "Развлечения",
    sports: "Спорт",
    health: "Здоровье",
    science: "Наука",
    image_upload_prompt: "Выберите изображение из галереи или камеры",
    camera: "КАМЕРА",
    gallery: "ГАЛЕРЕЯ",
    undefined: "Недавние поисковые запросы",
    cancel: "Отмена",
    alert: "Предупреждение",
    view: "Посмотреть",
    Economy: "Экономика",
    Search: "Поиск",
    "Add Post": "Добавить сообщение",
    Settings: "Настройки",
    "Select GoodNews Preferences": "Выберите настройки GoodNews",
    search_here: "Новости экономики ищите здесь",
    goodnews_preferences_prompt: "Выберите ваши интересы.",
    "Read and share uplifting and economy news":
      "Читайте и делитесь вдохновляющими и экономическими новостями",
    Email: "Электронная почта",
    Password: "пароль",
    login: "Войти",
    sign_in_as_guest: "Войти как гость",
    dont_have_an_account: "Нет аккаунта ?",
    sign_up: "Подписаться",
    by_signing_in: "Войдя, вы соглашаетесь с нашими условиями",
    interested_in_becoming_a_goodnews_reporter:
      "Интересуетесь стать репортером GoodNews ?",
    goodnews_terms_information: "Информация о условиях GoodNews",
    username: "имя пользователя",
    confirm_password: "Подтвердите пароль",
    register: "регистр",
    terms: "Условия",
    term1:
      "Новостные статьи, опубликованные пользователями, должны иметь минимальное количество грамматических ошибок.",
    term2:
      "Пользователи должны публиковать только новостные статьи, например, те, которые публикуют новостные поставщики, такие как BBC, которые имеют источник, название и содержание.",
    term3:
      "Приложение GoodNews имеет право модерировать новости журналистов, если они содержат ненавистные высказывания, чувствительный или несвязанный контент.",
    term4:
      "Ваша новостная публикация может не отображаться автоматически, порядок статей случайный.",
    term5: "Точность позитивности новостей различается.",
    term6:
      "Веб-сайт сообщения должен начинаться с «http://www…» для работы гиперссылки.",
    term7:
      "Пользователи соглашаются с условиями (EULA), где нет терпимости к оскорбительному содержанию или злоупотребляющим пользователям. Goodnews будет действовать по отчетам о оскорбительном содержании в течение 24 часов, удаляя содержимое и выгоняя пользователя, который предоставил оскорбительное содержание.",
    term8:
      "Посты могут публиковать только зарегистрированные пользователи, которые вошли в систему и создали учетные записи.",
    term9:
      "Экономическая лента и поиск требуют некоторого времени для загрузки. Пожалуйста, протестируйте это в свой бесплатный пробный период, прежде чем покупать.",
    term10:
      "Некоторые статьи не имеют сдвигов и будут отображаться в результатах графиков.",
    big_wins: "БОЛЬШИЕ ПОБЕДЫ",
    all_news_articles: "ВСЕ НОВОСТИ, СТАТЬИ И ВДОХНОВЛЯЮЩИЕ ИСТОРИИ",
    block: "Блокировать",
    report: "Доклад",
    share: "Поделиться",
    comment: "Комментарий",
    submit: "Подтвердить",
    like: "Нравится",
    likes: "нравится",
    Title: "заглавие",
    Content: "содержание",
    URL: "URL",
    Reporter: "Репортер",
    Source: "Источник",
    enter_a_valid_value: "Пожалуйста, введите допустимое значение",
    create_an_account_to_create_post:
      "Пожалуйста, создайте учетную запись, чтобы создать сообщение",
    post_added_successfully: "Сообщение успешно добавлено",
    there_was_an_error: "Произошла ошибка",
    failed_to_translate: "Не удалось перевести",
    show_more: "Показать больше",
    show_less: "Показать меньше",
    successful: "Успешно!",
    unlike: "Не нравится",
    no_goodnews_available: "В настоящее время в сети нет хороших новостей",
    feedback: "Feedback",
    welcome_good_news_msg: `Давайте примем тепло позитивных новостей, ведь это не просто
    мимолетный момент хорошего настроения; это катализатор для большего
    сострадательный, связанный и обнадеживающий мир.`,
    welcome_good_news: "Добро пожаловать в GoodNews",
    real_estate: "Недвижимость",
    energy: "Энергия",
    climate: "Климат",
    retail: "Розничная торговля",
    small_business: "Малый бизнес",
    healthcare: "Здравоохранение",
    aerospace: "Аэрокосмическая промышленность",
    defense: "Защита",
    crypto: "Крипто",
    startup: "Стартапы",
  },
  hi: {
    latest_news: "नवीनतम समाचार",
    translate: "अनुवाद करें",
    contact_us: "हमसे संपर्क करें",
    logout: "लॉग आउट",
    delete_account: "खाता हटाएं",
    pick_an_image: "एक छवि चुनें",
    upload_image: "छवि अपलोड करें",
    newspapers: "अखबार",
    have_questions_or_comments: "क्या सवाल / टिप्पणियाँ हैं?",
    settings: "सेटिंग्स",
    fetching_articles:
      "लेख ला रहा है... कृपया 30 सेकंड तक प्रतीक्षा करें क्योंकि हम आपके लिए अनुकूलित फ़ीड बना रहे हैं। एक बार लोड होने पर वित्तीय समाचारों के प्रभाव को देखने के लिए ग्राफ़ पर टैप करें।",
    select_app_language: "ऐप भाषा चुनें",
    type_language_here: "यहाँ भाषा टाइप करें...",
    search: "खोज",
    free_trial_expired:
      "आपका मुफ्त परीक्षण समाप्त हो गया है। कृपया सेटिंग्स से सब्सक्रिप्शन खरीदें",
    prompt_to_search: "अब तक कोई {{news_category}} समाचार नहीं हैं, इसे खोजें!",
    community_posts: "समुदाय पोस्ट",
    economy: "अर्थव्यवस्था",
    general: "सामान्य",
    world: "विश्व",
    nation: "राष्ट्र",
    business: "व्यापार",
    technology: "प्रौद्योगिकी",
    entertainment: "मनोरंजन",
    sports: "खेल",
    health: "स्वास्थ्य",
    science: "विज्ञान",
    image_upload_prompt: "गैलरी या कैमरे से छवि चुनें",
    camera: "कैमरा",
    gallery: "गैलरी",
    undefined: "हाल के खोज",
    cancel: "रद्द करें",
    alert: "चेतावनी",
    view: "देखें",
    Economy: "अर्थव्यवस्था",
    Search: "खोज",
    "Add Post": "पोस्ट जोड़ें",
    Settings: "सेटिंग्स",
    "Select GoodNews Preferences": "गुडन्यूज पसंद चुनें",
    search_here: "यहां अर्थव्यवस्था समाचार खोजें",
    goodnews_preferences_prompt: "अपने इंटरेस्ट का चयन करें।",
    "Read and share uplifting and economy news":
      "प्रेरणादायक और अर्थव्यवस्था समाचार पढ़ें और साझा करें",
    Email: "ईमेल",
    Password: "पासवर्ड",
    login: "लॉग इन करें",
    sign_in_as_guest: "मेहमान के रूप में साइन इन करें",
    dont_have_an_account: "क्या आपका खाता नहीं है ?",
    sign_up: "साइन अप करें",
    by_signing_in: "साइन इन करके, आप हमारी शर्तों से सहमत होते हैं",
    interested_in_becoming_a_goodnews_reporter:
      "GoodNews रिपोर्टर बनने में रुचि है ?",
    goodnews_terms_information: "GoodNews शर्तों की जानकारी",
    username: "उपयोगकर्ता नाम",
    confirm_password: "पासवर्ड की पुष्टि करें",
    register: "रजिस्टर",
    terms: "शर्तें",
    term1:
      "उपयोगकर्ताओं द्वारा प्रकाशित समाचार लेखों में संभावना से कम व्याकरण त्रुटियाँ होनी चाहिए।",
    term2:
      "उपयोगकर्ताओं को केवल उन खबरों के लेख ही प्रकाशित करने चाहिए, जैसे जिन्हें बीबीसी जैसे समाचार प्रदाताओं द्वारा प्रकाशित किया गया है जिनमें स्रोत, शीर्षक और सामग्री हो।",
    term3:
      "यदि वे नफरत भाषण, संवेदनशील या असंबंधित सामग्री हो, तो GoodNews ऐप को पत्रकारों की समाचार को नियंत्रित करने का अधिकार है।",
    term4:
      "आपकी न्यूज़ पोस्ट स्वचालित रूप से नहीं दिखाई जा सकती है, लेखों का क्रम यादृच्छिक है।",
    term5: "समाचार की सकारात्मकता की सटीकता भिन्न होती है।",
    term6:
      "हाइपरलिंक काम करने के लिए पोस्ट की वेबसाइट को 'http://www...' से शुरू होना चाहिए।",
    term7:
      "उपयोगकर्ता असंगत सामग्री या अपशब्दपूर्ण उपयोगकर्ताओं के लिए कोई सहनशीलता नहीं है, यूजर लाइसेंस समझौते (EULA) पर सहमत होते हैं। Goodnews अपरिहार्य सामग्री की रिपोर्ट के अंतर्गत 24 घंटों के भीतर सक्रिय होगा, सामग्री को हटा कर जो कारण सामग्री को प्रदान किया, उस उपयोगकर्ता को निकाल देगा।",
    term8:
      "केवल उन उपयोगकर्ताओं द्वारा पोस्ट कर सकते हैं जो लॉगिन कर चुके हैं और खाते बना चुके हैं।",
    term9:
      "अर्थव्यवस्था फीड और खोज को लोड होने में कुछ समय लगता है। कृपया खरीदने से पहले अपने नि: शुल्क परीक्षण में टेस्ट करें।",
    term10:
      "कुछ लेखों में कोई परिवर्तन नहीं होता और ग्राफिक परिणाम में दिखाई देगा।",
    big_wins: "बड़ी जीतें",
    all_news_articles: "सभी समाचार, लेख और प्रेरणादायक कहानियाँ",
    block: "ब्लॉक",
    report: "रिपोर्ट",
    share: "शेयर",
    comment: "टिप्पणी",
    submit: "प्रस्तुत करें",
    like: "पसंद",
    likes: "पसंद है",
    Title: "शीर्षक",
    Content: "सामग्री",
    URL: "यूआरएल",
    Reporter: "रिपोर्टर",
    Source: "स्रोत",
    enter_a_valid_value: "कृपया एक मान्य मान दर्ज करें",
    create_an_account_to_create_post: "कृपया एक पद बनाने के लिए खाता बनाएं",
    post_added_successfully: "पोस्ट सफलतापूर्वक जोड़ी गई",
    there_was_an_error: "एक त्रुटि हुई",
    failed_to_translate: "अनुवाद विफल",
    show_more: "अधिक दिखाएं",
    show_less: "कम दिखाएं",
    successful: "सफल!",
    unlike: "पसंद नहीं",
    no_goodnews_available: "अभी कोई अच्छी ख़बर ऑनलाइन उपलब्ध नहीं है",
    feedback: "Feedback",
    welcome_good_news_msg: `आइए सकारात्मक खबरों की गर्माहट को अपनाएं, क्योंकि यह सिर्फ एक खबर नहीं है
    क्षणभंगुर अहसास-अच्छा पल; यह और अधिक के लिए उत्प्रेरक है
    दयालु, जुड़ा हुआ और आशावान विश्व।`,
    welcome_good_news: "गुडन्यूज़ में आपका स्वागत है",
    real_estate: "रियल एस्टेट",
    energy: "ऊर्जा",
    climate: "जलवायु",
    retail: "खुदरा",
    small_business: "छोटा व्यवसाय",
    healthcare: "स्वास्थ्य देखभाल",
    aerospace: "एयरोस्पेस",
    defense: "रक्षा",
    crypto: "क्रिप्टो",
    startup: "स्टार्टअप",
  },
  ja: {
    latest_news: "最新ニュース",
    translate: "翻訳",
    contact_us: "お問い合わせ",
    logout: "ログアウト",
    delete_account: "アカウントを削除する",
    pick_an_image: "画像を選択",
    upload_image: "画像をアップロード",
    newspapers: "新聞紙",
    have_questions_or_comments: "質問/コメントはありますか？",
    settings: "設定",
    fetching_articles:
      "記事を取得しています... 記事のカスタマイズされたフィードを作成している間、30秒お待ちください。 読み込み後、ニュースが経済に与える影響を表示するには、グラフをタップしてください。",
    select_app_language: "アプリの言語を選択",
    type_language_here: "ここに言語を入力してください...",
    search: "検索",
    free_trial_expired:
      "無料トライアルが期限切れです。設定からサブスクリプションを購入してください",
    prompt_to_search:
      "{{news_category}}のニュースはまだありません、検索してください！",
    community_posts: "コミュニティ投稿",
    economy: "経済",
    general: "一般的な",
    world: "世界",
    nation: "国家",
    business: "ビジネス",
    technology: "テクノロジー",
    entertainment: "エンターテイメント",
    sports: "スポーツ",
    health: "健康",
    science: "科学",
    image_upload_prompt: "ギャラリーまたはカメラから画像を選択",
    camera: "カメラ",
    gallery: "ギャラリー",
    undefined: "最近の検索",
    cancel: "キャンセル",
    alert: "警報",
    view: "表示",
    Economy: "経済",
    Search: "検索",
    "Add Post": "投稿を追加",
    Settings: "設定",
    "Select GoodNews Preferences": "GoodNewsの設定を選択",
    search_here: "経済ニュースの検索はこちらから",
    goodnews_preferences_prompt: "あなたの興味を選択してください。",
    "Read and share uplifting and economy news":
      "元気づけられる経済ニュースを読んで共有する",
    Email: "Eメール",
    Password: "パスワード",
    login: "ログイン",
    sign_in_as_guest: "ゲストとしてサインイン",
    dont_have_an_account: "アカウントがありませんか？",
    sign_up: "サインアップ",
    by_signing_in:
      "サインインすることにより、当社の利用規約に同意したものとみなされます",
    interested_in_becoming_a_goodnews_reporter:
      "GoodNewsの記者になりたいですか？",
    goodnews_terms_information: "GoodNews利用規約情報",
    username: "ユーザー名",
    confirm_password: "パスワードを確認",
    register: "登録",
    terms: "利用規約",
    term1:
      "ユーザーが公開したニュース記事には、可能な限り少ない文法エラーが含まれるべきです。",
    term2:
      "ユーザーは、BBCなどのニュースプロバイダーが提供するソース、タイトル、コンテンツを持つニュース記事のみを投稿する必要があります。",
    term3:
      "GoodNewsアプリには、ヘイトスピーチ、感じの悪い、または関係のないコンテンツが含まれる場合、ジャーナリストのニュースをモデレートする権利があります。",
    term4:
      "あなたのニュース投稿は自動的に表示されない場合があります。記事の順序はランダムです。",
    term5: "ニュースのポジティブ度の正確さは異なります。",
    term6:
      "ハイパーリンクが機能するには、投稿のウェブサイトは 'http://www...' で始まる必要があります。",
    term7:
      "ユーザーは、EULA で不適切なコンテンツや乱用ユーザーに対する寛容はありません。 Goodnewsは24時間以内に不適切なコンテンツの報告に対処し、コンテンツを削除して不快なコンテンツを提供したユーザーを追放します。",
    term8: "ログインしてアカウントを作成したユーザーのみが投稿できます。",
    term9:
      "経済フィードと検索には少し時間がかかります。 購入前に無料トライアルでテストしてください。",
    term10:
      "いくつかの記事にはシフトがありませんが、グラフ結果に表示されます。",
    big_wins: "ビッグウィン",
    all_news_articles: "すべてのニュース、記事、および感動的な物語",
    block: "ブロック",
    report: "報告する",
    share: "共有",
    comment: "コメント",
    submit: "提出する",
    like: "好き",
    likes: "好き",
    Title: "題名",
    Content: "内容",
    URL: "URL",
    Reporter: "記者",
    Source: "ソース",
    enter_a_valid_value: "有効な値を入力してください",
    create_an_account_to_create_post:
      "投稿を作成するにはアカウントを作成してください",
    post_added_successfully: "投稿が正常に追加されました",
    there_was_an_error: "エラーが発生しました",
    failed_to_translate: "翻訳に失敗しました",
    show_more: "もっと表示",
    show_less: "表示を減らす",
    successful: "成功しました！",
    unlike: "いいねを取り消す",
    no_goodnews_available: "現在オンラインで入手できる良いニュースはありません",
    feedback: "Feedback",
    welcome_good_news_msg: `ポジティブなニュースの温かさを受け入れましょう。
    つかの間の心地よい瞬間。 それはさらなる発展のための触媒です
    思いやりがあり、つながりがあり、希望に満ちた世界。`,
    welcome_good_news: "グッドニュースへようこそ",
    real_estate: "不動産",
    energy: "エネルギー",
    climate: "気候",
    retail: "小売り",
    small_business: "小規模なビジネス",
    healthcare: "健康管理",
    aerospace: "航空宇宙",
    defense: "防衛",
    crypto: "暗号",
    startup: "スタートアップ",
  },
  ko: {
    latest_news: "최신 뉴스",
    translate: "번역",
    contact_us: "문의하기",
    logout: "로그아웃",
    delete_account: "계정 삭제",
    pick_an_image: "이미지 선택",
    upload_image: "이미지 업로드",
    newspapers: "신문",
    have_questions_or_comments: "질문/의견이 있으세요?",
    settings: "설정",
    fetching_articles:
      "기사 가져오는 중... 사용자 지정된 기사 피드를 만들고 있으니 30초 동안 기다려 주십시오. 로드된 후에 뉴스의 경제적 영향을 확인하려면 그래프를 탭하십시오.",
    select_app_language: "앱 언어 선택",
    type_language_here: "여기에 언어를 입력하세요...",
    search: "검색",
    free_trial_expired:
      "무료 평가판이 만료되었습니다. 설정에서 구독을 구매하십시오",
    prompt_to_search: "{{news_category}} 뉴스가 아직 없습니다. 검색해보세요!",
    community_posts: "커뮤니티 게시물",
    economy: "경제",
    general: "일반",
    world: "세계",
    nation: "국가",
    business: "사업",
    technology: "기술",
    entertainment: "엔터테인먼트",
    sports: "스포츠",
    health: "건강",
    science: "과학",
    image_upload_prompt: "갤러리 또는 카메라에서 이미지 선택",
    camera: "카메라",
    gallery: "갤러리",
    undefined: "최근 검색어",
    cancel: "취소",
    alert: "경고",
    view: "보기",
    Economy: "경제",
    Search: "검색",
    "Add Post": "게시물 추가",
    Settings: "설정",
    "Select GoodNews Preferences": "GoodNews 환경 설정 선택",
    search_here: "여기서 경제 뉴스를 검색해 보세요",
    goodnews_preferences_prompt: "관심사를 선택하십시오.",
    "Read and share uplifting and economy news":
      "고무적이고 경제적인 뉴스를 읽고 공유하세요",
    Email: "이메일",
    Password: "암호",
    login: "로그인",
    sign_in_as_guest: "손님으로 로그인",
    dont_have_an_account: "계정이 없으신가요 ?",
    sign_up: "가입하기",
    by_signing_in: "로그인하면 당사의 약관에 동의하는 것입니다",
    interested_in_becoming_a_goodnews_reporter:
      "GoodNews 기자가 되고 싶으세요 ?",
    goodnews_terms_information: "GoodNews 약관 정보",
    username: "사용자 이름",
    confirm_password: "암호 확인",
    register: "레지스터",
    terms: "약관",
    term1:
      "사용자가 게시한 뉴스 기사는 가능한 한 적은 양의 문법 오류를 가져야합니다.",
    term2:
      "사용자는 소스, 제목 및 내용을 갖춘 BBC와 같은 뉴스 제공 업체가 게시한 것과 같은 뉴스 기사 만 게시해야합니다.",
    term3:
      "GoodNews 앱은 기자의 뉴스를 심사할 권리가 있습니다. 그들이 혐오 발언, 민감한 또는 관련이 없는 내용을 포함하는 경우입니다.",
    term4:
      "귀하의 뉴스 게시물은 자동으로 표시되지 않을 수 있으며, 기사의 순서는 무작위입니다.",
    term5: "뉴스 긍정성의 정확성은 다릅니다.",
    term6:
      "하이퍼링크가 작동하려면 게시물의 웹 사이트는 'http://www...'로 시작해야합니다.",
    term7:
      "사용자는 사용자 라이센스 동의서 (EULA)에서 부적절한 콘텐츠나 남용 사용자에 대한 허용이 없음을 동의합니다. Goodnews는 24 시간 이내에 부적절한 콘텐츠 보고서에 대응하여 콘텐츠를 제거하고 해당 콘텐츠를 제공한 사용자를 추방합니다.",
    term8: "로그인하고 계정을 만든 사용자만이 게시 할 수 있습니다.",
    term9:
      "경제 피드 및 검색은로드하는 데 시간이 걸립니다. 구매하기 전에 무료 평가판에서 테스트하십시오.",
    term10: "일부 기사에는 변화가 없으며 그래프 결과에 표시됩니다.",
    big_wins: "대성공",
    all_news_articles: "모든 뉴스, 기사 및 영감을 주는 이야기",
    block: "차단",
    report: "보고서",
    share: "공유",
    comment: "댓글",
    submit: "제출",
    like: "좋아요",
    likes: "좋아요",
    Title: "표제",
    Content: "내용",
    URL: "URL",
    Reporter: "기자",
    Source: "출처",
    enter_a_valid_value: "유효한 값을 입력하세요",
    create_an_account_to_create_post: "게시물을 만들려면 계정을 만들어 주세요",
    post_added_successfully: "게시물이 성공적으로 추가되었습니다",
    there_was_an_error: "오류가 발생했습니다",
    failed_to_translate: "번역에 실패했습니다",
    show_more: "더 보기",
    show_less: "간략히",
    successful: "성공했습니다!",
    unlike: "좋아요 취소",
    no_goodnews_available: "지금은 좋은 소식이 온라인에 없습니다.",
    feedback: "Feedback",
    welcome_good_news_msg: `긍정적인 소식의 따뜻함을 받아들이자.
    잠시 기분이 좋아지는 순간; 그것은 더 많은 것을 위한 촉매제이다
    자비롭고, 연결되고, 희망적인 세상.`,
    welcome_good_news: "굿뉴스에 오신 것을 환영합니다",
    real_estate: "부동산",
    energy: "에너지",
    climate: "기후",
    retail: "소매",
    small_business: "중소기업",
    healthcare: "보건 의료",
    aerospace: "항공우주",
    defense: "방어",
    crypto: "암호화폐",
    startup: "스타트업",
  },
  nl: {
    latest_news: "Laatste Nieuws",
    translate: "Vertalen",
    contact_us: "Neem contact met ons op",
    logout: "Uitloggen",
    delete_account: "Account verwijderen",
    pick_an_image: "Kies een afbeelding",
    upload_image: "Afbeelding uploaden",
    newspapers: "Kranten",
    have_questions_or_comments: "Heb je vragen/opmerkingen?",
    settings: "Instellingen",
    fetching_articles:
      "Artikelen ophalen... Wacht 30 seconden terwijl we een aangepaste feed van artikelen voor u maken. Tik op de grafieken om de impact op de economie van het nieuws te bekijken zodra deze is geladen.",
    select_app_language: "Selecteer app-taal",
    type_language_here: "Typ hier taal...",
    search: "Zoeken",
    free_trial_expired:
      "Uw gratis proefperiode is verlopen. Koop een abonnement in de instellingen",
    prompt_to_search: "Nog geen {{news_category}} nieuws, zoek het op!",
    community_posts: "Gemeenschapsberichten",
    economy: "economie",
    general: "Algemeen",
    world: "Wereld",
    nation: "Natie",
    business: "Bedrijf",
    technology: "Technologie",
    entertainment: "Amusement",
    sports: "Sport",
    health: "Gezondheid",
    science: "Wetenschap",
    image_upload_prompt: "Kies een afbeelding uit de galerij of de camera",
    camera: "CAMERA",
    gallery: "GALERIJ",
    undefined: "Recente zoekopdrachten",
    cancel: "Annuleren",
    alert: "Alarm",
    view: "Bekijken",
    Economy: "Economie",
    Search: "Zoeken",
    "Add Post": "Bericht toevoegen",
    Settings: "Instellingen",
    "Select GoodNews Preferences": "Selecteer GoodNews-voorkeuren",
    search_here: "Zoek hier naar economisch nieuws",
    goodnews_preferences_prompt: "Selecteer uw interesses.",
    "Read and share uplifting and economy news":
      "Lees en deel opbeurend en economisch nieuws",
    Email: "E-mail",
    Password: "Wachtwoord",
    login: "Log in",
    sign_in_as_guest: "Inloggen als gast",
    dont_have_an_account: "Heb je geen account ?",
    sign_up: "Aanmelden",
    by_signing_in: "Door in te loggen, gaat u akkoord met onze voorwaarden",
    interested_in_becoming_a_goodnews_reporter:
      "Geïnteresseerd om een GoodNews-verslaggever te worden ?",
    goodnews_terms_information: "Informatie over de voorwaarden van GoodNews",
    username: "Gebruikersnaam",
    confirm_password: "Bevestig wachtwoord",
    register: "Registreren",
    terms: "Voorwaarden",
    term1:
      "Nieuwsartikelen die door gebruikers zijn gepubliceerd, moeten zo min mogelijk grammaticale fouten bevatten.",
    term2:
      "Gebruikers moeten alleen nieuwsartikelen publiceren, zoals die gepubliceerd zijn door nieuwsaanbieders zoals de BBC, die een bron, titel en inhoud hebben.",
    term3:
      "GoodNews App heeft het recht om het nieuws van journalisten te modereren als ze haatspraak, gevoelige of niet-gerelateerde inhoud bevatten.",
    term4:
      "Uw nieuwsbericht wordt mogelijk niet automatisch weergegeven, de volgorde van artikelen is willekeurig.",
    term5: "De nauwkeurigheid van de positiviteit van nieuws varieert.",
    term6:
      "De website van de post moet beginnen met 'http://www...' voor de hyperlink om te werken.",
    term7:
      "Gebruikers stemmen in met voorwaarden (EULA) waar geen tolerantie is voor onaanvaardbare inhoud of misbruikende gebruikers. Goodnews zal binnen 24 uur reageren op meldingen van onaanvaardbare inhoud door de inhoud te verwijderen en de gebruiker die de aanstootgevende inhoud heeft geleverd uit te zetten.",
    term8:
      "Alleen ingelogde gebruikers die accounts hebben aangemaakt, kunnen posten.",
    term9:
      "Het Economy Feed en de zoekfunctie hebben wat tijd nodig om te laden. Test alstublieft tijdens uw gratis proefperiode voordat u koopt.",
    term10:
      "Sommige artikelen hebben geen verschuivingen en worden weergegeven in grafiekresultaten.",
    big_wins: "GROTE WINSTEN",
    all_news_articles: "ALLE NIEUWS, ARTIKELEN EN INSPIRERENDE VERHALEN",
    block: "Blokkeer",
    report: "Rapport",
    share: "Delen",
    comment: "Commentaar",
    submit: "Indienen",
    like: "Vind ik leuk",
    likes: "vind-ik-leuks",
    Title: "Titel",
    Content: "Inhoud",
    URL: "URL",
    Reporter: "Verslaggever",
    Source: "Bron",
    enter_a_valid_value: "Voer een geldige waarde in",
    create_an_account_to_create_post:
      "Maak een account aan om een bericht te maken",
    post_added_successfully: "Bericht succesvol toegevoegd",
    there_was_an_error: "Er is een fout opgetreden",
    failed_to_translate: "Vertalen mislukt",
    show_more: "Meer tonen",
    show_less: "Minder tonen",
    successful: "Succesvol!",
    unlike: "Niet leuk vinden",
    no_goodnews_available: "Ingen gode nyheder tilgængelige online lige nu",
    feedback: "Feedback",
    welcome_good_news_msg: `Laten we de warmte van positief nieuws omarmen, want het is niet zomaar een nieuwsbericht
    vluchtig feelgood-moment; het is een katalysator voor meer
    meelevende, verbonden en hoopvolle wereld.`,
    welcome_good_news: "Welkom bij GoedNieuws",
    real_estate: "Vastgoed",
    energy: "Energie",
    climate: "Klimaat",
    retail: "Detailhandel",
    small_business: "Klein bedrijf",
    healthcare: "Gezondheidszorg",
    aerospace: "Lucht- en ruimtevaart",
    defense: "Verdediging",
    crypto: "Crypto",
    startup: "Startups",
  },
  tr: {
    latest_news: "Son Haberler",
    translate: "Çevir",
    contact_us: "Bize Ulaşın",
    logout: "Çıkış Yap",
    delete_account: "Hesabı Sil",
    pick_an_image: "Bir resim seçin",
    upload_image: "Resim yükle",
    newspapers: "Gazeteler",
    have_questions_or_comments: "Sorularınız/yorumlarınız var mı?",
    settings: "Ayarlar",
    fetching_articles:
      "Makaleler alınıyor... Sizin için özel olarak makale beslemesi oluştururken lütfen 30 saniye bekleyin. Yüklenince, haberlerin ekonomi üzerindeki etkisini görmek için grafiklere dokunun.",
    select_app_language: "Uygulama Dili Seçin",
    type_language_here: "Buraya dil yazın...",
    search: "Arama",
    free_trial_expired:
      "Ücretsiz Deneme Süreniz sona erdi. Lütfen abonelik satın alın",
    prompt_to_search: "{{news_category}} haberleri henüz yok, arayın!",
    community_posts: "Topluluk gönderileri",
    economy: "ekonomi",
    general: "Genel",
    world: "Dünya",
    nation: "Ulusal",
    business: "İş",
    technology: "Teknoloji",
    entertainment: "Eğlence",
    sports: "Spor",
    health: "Sağlık",
    science: "Bilim",
    image_upload_prompt: "Galeriden veya kameradan resim seçin",
    camera: "KAMERA",
    gallery: "GALERİ",
    undefined: "Son Aramalar",
    cancel: "İptal",
    alert: "Uyarı",
    view: "Görünüm",
    Economy: "Ekonomi",
    Search: "Arama",
    "Add Post": "Gönderi ekle",
    Settings: "Ayarlar",
    "Select GoodNews Preferences": "GoodNews Tercihlerini Seçin",
    search_here: "Ekonomi haberlerini burada arayın",
    goodnews_preferences_prompt: "İlgi alanlarınızı seçin.",
    "Read and share uplifting and economy news":
      "Yükseltilmiş ve ekonomi haberlerini okuyun ve paylaşın",
    Email: "E-posta",
    Password: "Parola",
    login: "Oturum aç",
    sign_in_as_guest: "Misafir olarak oturum aç",
    dont_have_an_account: "Bir hesabınız yok mu ?",
    sign_up: "Kaydol",
    by_signing_in: "Oturum açarak, şartlarımızı kabul etmiş olursunuz",
    interested_in_becoming_a_goodnews_reporter:
      "GoodNews muhabiri olmaya ilgili misiniz ?",
    goodnews_terms_information: "GoodNews Şartlar Bilgisi",
    username: "Kullanıcı adı",
    confirm_password: "Şifreyi Onayla",
    register: "Kayıt ol",
    terms: "Şartlar",
    term1:
      "Kullanıcılar tarafından yayınlanan haber makalelerinde mümkün olan en az sayıda dilbilgisi hatası bulunmalıdır.",
    term2:
      "Kullanıcılar, bir kaynak, başlık ve içeriğe sahip olan BBC gibi haber sağlayıcılarının yayınladığı gibi, yalnızca haber makaleleri yayınlamalıdır.",
    term3:
      "GoodNews App, haberlerde nefret söylemi, hassas veya ilgisiz içerik bulunduruyorsa gazetecilerin haberlerini düzenleme hakkına sahiptir.",
    term4:
      "Haber gönderiniz otomatik olarak gösterilmeyebilir, makalelerin sıralaması rastgeledir.",
    term5: "Haberlerin pozitifliği değişir.",
    term6:
      "Bağlantının çalışması için gönderinin web sitesinin ‘http://www…’ ile başlaması gerekir.",
    term7:
      "Kullanıcılar, uygunsuz içerik veya kötüye kullanım yapan kullanıcılar için hoşgörünün olmadığı koşulları (EULA) kabul ederler. Goodnews, istenmeyen içerik raporlarına 24 saat içinde yanıt verecek, içeriği kaldırarak ve rahatsız edici içeriği sağlayan kullanıcıyı çıkararak işlem yapacaktır.",
    term8:
      "Yalnızca oturum açmış ve hesap oluşturmuş kullanıcılar gönderi yapabilir.",
    term9:
      "Ekonomi Beslemesi ve Arama'nın yüklenmesi biraz zaman alır. Lütfen satın almadan önce ücretsiz denemenizi yapın.",
    term10:
      "Bazı makalelerde kaydırma yoktur ve grafik sonuçlarında gösterilir.",
    big_wins: "BÜYÜK KAZANÇLAR",
    all_news_articles: "TÜM HABERLER, MAKALELER VE İLHAM VERİCİ HİKAYELER",
    block: "Engelle",
    report: "Rapor et",
    share: "Paylaş",
    comment: "Yorum Yap",
    submit: "Gönder",
    like: "Beğen",
    likes: "beğeniler",
    Title: "Başlık",
    Content: "İçerik",
    URL: "URL",
    Reporter: "Muhabir",
    Source: "Kaynak",
    enter_a_valid_value: "Lütfen geçerli bir değer girin",
    create_an_account_to_create_post:
      "Bir gönderi oluşturmak için lütfen bir hesap oluşturun",
    post_added_successfully: "Gönderi başarıyla eklendi",
    there_was_an_error: "Bir hata oluştu",
    failed_to_translate: "Çeviri başarısız oldu",
    show_more: "Daha fazla göster",
    show_less: "Daha az göster",
    successful: "Başarılı!",
    unlike: "Beğenmekten Vazgeç",
    no_goodnews_available: "Şu anda çevrimiçi olarak İyi Haber yok",
    feedback: "Feedback",
    welcome_good_news_msg: `Olumlu haberlerin sıcaklığını kucaklayalım, çünkü bu sadece bir haber değil.
    kısacık iyi hissetme anı; daha fazlası için bir katalizördür
    şefkatli, bağlantılı ve umutlu bir dünya.`,
    welcome_good_news: `GoodNews'e hoş geldiniz`,
    real_estate: "Emlak",
    energy: "Enerji",
    climate: "İklim",
    retail: "Perakende",
    small_business: "Küçük iş",
    healthcare: "Sağlık hizmeti",
    aerospace: "Havacılık",
    defense: "Savunma",
    crypto: "Kripto",
    startup: "Startup'lar",
  },
  fi: {
    latest_news: "Viimeisimmät Uutiset",
    translate: "Käännä",
    contact_us: "Ota yhteyttä",
    logout: "Kirjaudu ulos",
    delete_account: "Poista tili",
    pick_an_image: "Valitse kuva",
    upload_image: "Lataa kuva",
    newspapers: "Sanomalehdet",
    have_questions_or_comments: "Onko sinulla kysymyksiä tai kommentteja?",
    settings: "Asetukset",
    fetching_articles:
      "Haetaan artikkeleita... Odota 30 sekuntia, sillä luomme räätälöityä uutisvirtaa sinulle. Napauta graafeja nähdäksesi uutisten talousvaikutuksen latautumisen jälkeen.",
    select_app_language: "Valitse sovelluksen kieli",
    type_language_here: "Kirjoita kieli tähän...",
    search: "Haku",
    free_trial_expired: "Ilmaiskokeesi on päättynyt. Osta tilaus asetuksista.",
    prompt_to_search: "Ei {{news_category}} uutisia vielä, etsi ne!",
    community_posts: "Yhteisöjulkaisut",
    economy: "Talous",
    general: "Yleinen",
    world: "Maailma",
    nation: "Kansakunta",
    business: "Liiketoiminta",
    technology: "Teknologia",
    entertainment: "Viihde",
    sports: "Urheilu",
    health: "Terveys",
    science: "Tiede",
    image_upload_prompt: "Valitse kuva galleriasta tai kamerasta",
    camera: "KAMERA",
    gallery: "GALLERIA",
    undefined: "Viimeisimmät haut",
    cancel: "Peruuta",
    alert: "Hälytys",
    view: "Näytä",
    Economy: "Talous",
    Search: "Haku",
    "Add Post": "Lisää julkaisu",
    Settings: "Asetukset",
    "Select GoodNews Preferences": "Valitse GoodNews-asetukset",
    search_here: "Hae talousuutisia täältä",
    goodnews_preferences_prompt: "Valitse kiinnostuksenkohteesi.",
    "Read and share uplifting and economy news":
      "Lue ja jaa piristäviä ja talouteen liittyviä uutisia",
    Email: "Sähköposti",
    Password: "Salasana",
    login: "Kirjaudu sisään",
    sign_in_as_guest: "Kirjaudu vieraana",
    dont_have_an_account: "Eikö sinulla ole tiliä?",
    sign_up: "Rekisteröidy",
    by_signing_in: "Kirjautumalla sisään hyväksyt käyttöehdot",
    interested_in_becoming_a_goodnews_reporter:
      "Kiinnostunut GoodNewsin toimittajaksi tulemisesta?",
    goodnews_terms_information: "GoodNewsin käyttöehdot",
    username: "Käyttäjänimi",
    confirm_password: "Vahvista salasana",
    register: "Rekisteröidy",
    terms: "Käyttöehdot",
    term1:
      "Käyttäjien julkaisemat uutisartikkelit tulisi olla mahdollisimman virheettömiä kieliopillisesti.",
    term2:
      "Käyttäjien tulisi julkaista vain uutisartikkeleita, kuten niitä, jotka on julkaistu uutispalveluntarjoajilta, kuten BBC:ltä, joilla on lähde, otsikko ja sisältö.",
    term3:
      "GoodNews-sovelluksella on oikeus valvoa toimittajien uutisia, jos ne sisältävät vihapuhetta, herkkää tai epäolennaista sisältöä.",
    term4:
      "Uutisesi ei välttämättä näy automaattisesti, artikkeleiden järjestys on satunnaistettu.",
    term5: "Uutisten positiivisuuden tarkkuus vaihtelee.",
    term6:
      "Sivuston, josta postitus alkaa 'http://www…' hyperlinkin toimimiseksi.",
    term7:
      "Käyttäjät hyväksyvät käyttöehdot (EULA), joissa ei ole nollatoleranssia loukkaavalle sisällölle tai väärinkäyttäjille. Goodnews toimii loukkaavan sisällön raporttien mukaan 24 tunnin kuluessa poistamalla sisällön ja poistamalla käyttäjän, joka toimitti loukkaavan sisällön.",
    term8:
      "Vain sisäänkirjautuneet käyttäjät, jotka ovat luoneet tilit, voivat julkaista.",
    term9:
      "Talousvirran ja haun latautumiseen kuluu jonkin verran aikaa. Testaa ilmaiskokeessasi ennen ostamista.",
    term10:
      "Joillakin artikkeleilla ei ole muutoksia, ja ne näkyvät grafiikkatuloksissa.",
    big_wins: "SUURET VOITOT",
    all_news_articles: "KAIKKI UUTISET, ARTIKKELIT JA INNOITTAVAT TARINAT",
    block: "Estä",
    report: "Raportti",
    share: "Jaa",
    comment: "Kommentti",
    submit: "Lähetä",
    like: "Tykkää",
    likes: "tykkää",
    Title: "Otsikko",
    Content: "Sisältö",
    URL: "URL",
    Reporter: "Toimittaja",
    Source: "Lähde",
    enter_a_valid_value: "Syötä kelvollinen arvo",
    create_an_account_to_create_post: "Luo tili voidaksesi luoda julkaisun",
    post_added_successfully: "Julkaisu lisättiin onnistuneesti",
    there_was_an_error: "Tapahtui virhe",
    failed_to_translate: "Käännös epäonnistui",
    show_more: "Näytä lisää",
    show_less: "Näytä vähemmän",
    successful: "Onnistunut!",
    unlike: "Ei tykkää",
    no_goodnews_available: "Hyviä uutisia ei ole saatavilla verkossa juuri nyt",
    feedback: "Feedback",
    welcome_good_news_msg: `Otetaan vastaan positiivisten uutisten lämpö, sillä se ei ole vain a
    ohikiitävä hyvänolon hetki; se on katalysaattori enemmän
    myötätuntoinen, yhteydessä oleva ja toiveikas maailma.`,
    welcome_good_news: "Tervetuloa GoodNewsiin",
    real_estate: "Kiinteistöt",
    energy: "Energiaa",
    climate: "Ilmasto",
    retail: "Jälleenmyynti",
    small_business: "Pieni yritys",
    healthcare: "Terveydenhuolto",
    aerospace: "Ilmailu",
    defense: "Puolustus",
    crypto: "Krypto",
    startup: "Startupit",
  },
  po: {
    latest_news: "Najnowsze Wiadomości",
    translate: "Tłumaczenie",
    contact_us: "Skontaktuj się z nami",
    logout: "Wyloguj",
    delete_account: "Usuń konto",
    pick_an_image: "Wybierz obraz",
    upload_image: "Prześlij obraz",
    newspapers: "Gazety",
    have_questions_or_comments: "Masz pytania lub komentarze?",
    settings: "Ustawienia",
    fetching_articles:
      "Pobieranie artykułów... Proszę czekać 30 sekund, ponieważ tworzymy dostosowany przekaz artykułów dla Ciebie. Kliknij na wykresy, aby zobaczyć wpływ na gospodarkę wiadomości po załadowaniu.",
    select_app_language: "Wybierz język aplikacji",
    type_language_here: "Wpisz język tutaj...",
    search: "Szukaj",
    free_trial_expired:
      "Twoje bezpłatne konto testowe wygasło. Proszę kupić subskrypcję w ustawieniach",
    prompt_to_search: "Brak {{news_category}} wiadomości, wyszukaj je!",
    community_posts: "Posty społeczności",
    economy: "Gospodarka",
    general: "Ogólne",
    world: "Świat",
    nation: "Kraj",
    business: "Biznes",
    technology: "Technologia",
    entertainment: "Rozrywka",
    sports: "Sport",
    health: "Zdrowie",
    science: "Nauka",
    image_upload_prompt: "Wybierz obraz z galerii lub aparatu",
    camera: "APARAT",
    gallery: "GALERIA",
    undefined: "Ostatnie wyszukiwania",
    cancel: "Anuluj",
    alert: "Alert",
    view: "Wyświetl",
    Economy: "Gospodarka",
    Search: "Szukaj",
    "Add Post": "Dodaj post",
    Settings: "Ustawienia",
    "Select GoodNews Preferences": "Wybierz preferencje GoodNews",
    search_here: "Wyszukaj tutaj wiadomości gospodarcze",
    goodnews_preferences_prompt: "Wybierz swoje zainteresowania.",
    "Read and share uplifting and economy news":
      "Czytaj i udostępniaj podnoszące na duchu i ekonomiczne wiadomości",
    Email: "Email",
    Password: "Hasło",
    login: "Zaloguj się",
    sign_in_as_guest: "Zaloguj się jako gość",
    dont_have_an_account: "Nie masz konta?",
    sign_up: "Zarejestruj się",
    by_signing_in: "Logując się, zgadzasz się na nasze warunki",
    interested_in_becoming_a_goodnews_reporter:
      "Interesuje cię zostanie reporterem GoodNews?",
    goodnews_terms_information: "Informacje o warunkach GoodNews",
    username: "Nazwa użytkownika",
    confirm_password: "Potwierdź hasło",
    register: "Zarejestruj się",
    terms: "Warunki",
    term1:
      "Artykuły opublikowane przez użytkowników powinny mieć minimalną ilość błędów gramatycznych.",
    term2:
      "Użytkownicy powinni publikować tylko artykuły informacyjne, takie jak te publikowane przez dostawców informacji, takie jak BBC, które mają źródło, tytuł i treść.",
    term3:
      "Aplikacja GoodNews ma prawo moderować wiadomości dziennikarzy, jeśli zawierają mowę nienawiści, wrażliwą lub niezwiązane z treścią.",
    term4:
      "Twój post może nie być wyświetlany automatycznie, kolejność artykułów jest losowa.",
    term5: "Dokładność pozytywności wiadomości jest różna.",
    term6:
      "Adres strony internetowej postu powinien zaczynać się od „http://www...” aby hiperłącze działało.",
    term7:
      "Użytkownicy akceptują warunki (EULA), w których nie ma tolerancji dla treści obraźliwych lub użytkowników stosujących nadużycia. Goodnews zareaguje na zgłoszenia obraźliwej treści w ciągu 24 godzin, usuwając treść i wyrzucając użytkownika, który dostarczył treść.",
    term8:
      "Tylko zalogowani użytkownicy, którzy założyli konta, mogą publikować.",
    term9:
      "Wprowadzenie strumienia gospodarki i wyszukiwania trwa trochę czasu. Przed zakupem przetestuj wersję próbną.",
    term10:
      "Niektóre artykuły nie zawierają zmian i będą pokazywać się w wynikach graficznych.",
    big_wins: "DUŻE ZWYCIĘSTWA",
    all_news_articles: "WSZYSTKIE WIADOMOŚCI, ARTYKUŁY I INSPIRUJĄCE HISTORIE",
    block: "Zablokuj",
    report: "Zgłoś",
    share: "Udostępnij",
    comment: "Komentarz",
    submit: "Prześlij",
    like: "Lubić",
    likes: "polubienia",
    Title: "Tytuł",
    Content: "Zawartość",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Źródło",
    enter_a_valid_value: "Wprowadź prawidłową wartość",
    create_an_account_to_create_post: "Utwórz konto, aby utworzyć post",
    post_added_successfully: "Post dodany pomyślnie",
    there_was_an_error: "Wystąpił błąd",
    failed_to_translate: "Nie udało się przetłumaczyć",
    show_more: "Pokaż więcej",
    show_less: "Pokaż mniej",
    successful: "Sukces!",
    unlike: "Nie lubić",
    no_goodnews_available: "Brak dobrych wiadomości dostępnych obecnie online",
    feedback: "Feedback",
    welcome_good_news_msg: `Korzystajmy z ciepła pozytywnych wiadomości, bo to nie tylko
    ulotna chwila dobrego samopoczucia; jest katalizatorem czegoś więcej
    współczujący, połączony i pełen nadziei świat.`,
    welcome_good_news: "Witamy w GoodNews",
    real_estate: "Nieruchomość",
    energy: "Energia",
    climate: "Klimat",
    retail: "Sprzedaż detaliczna",
    small_business: "Mały biznes",
    healthcare: "Opieka zdrowotna",
    aerospace: "Lotnictwo",
    defense: "Obrona",
    crypto: "Krypto",
    startup: "Startupy",
  },
  da: {
    latest_news: "Seneste Nyheder",
    translate: "Oversæt",
    contact_us: "Kontakt os",
    logout: "Log ud",
    delete_account: "Slet konto",
    pick_an_image: "Vælg et billede",
    upload_image: "Upload billede",
    newspapers: "Aviser",
    have_questions_or_comments: "Har du spørgsmål eller kommentarer?",
    settings: "Indstillinger",
    fetching_articles:
      "Henter artikler... Vent venligst 30 sekunder, mens vi opretter tilpasset feed af artikler til dig. Tryk på grafer for at se virkningen på økonomien af nyheder, når de er indlæst.",
    select_app_language: "Vælg app-sprog",
    type_language_here: "Skriv sprog her...",
    search: "Søg",
    free_trial_expired:
      "Din gratis prøveperiode er udløbet. Køb venligst et abonnement fra indstillingerne",
    prompt_to_search: "Ingen {{news_category}} nyheder endnu, søg efter dem!",
    community_posts: "Fællesskabsindlæg",
    economy: "Økonomi",
    general: "Generel",
    world: "Verden",
    nation: "Nation",
    business: "Forretning",
    technology: "Teknologi",
    entertainment: "Underholdning",
    sports: "Sport",
    health: "Sundhed",
    science: "Videnskab",
    image_upload_prompt: "Vælg billede fra galleri eller kamera",
    camera: "KAMERA",
    gallery: "GALLERI",
    undefined: "Seneste søgninger",
    cancel: "Annuller",
    alert: "Advarsel",
    view: "Se",
    Economy: "Økonomi",
    Search: "Søg",
    "Add Post": "Tilføj indlæg",
    Settings: "Indstillinger",
    "Select GoodNews Preferences": "Vælg GoodNews Præferencer",
    search_here: "Søg økonominyheder her",
    goodnews_preferences_prompt: "Vælg dine interesser.",
    "Read and share uplifting and economy news":
      "Læs og del opløftende og økonomiske nyheder",
    Email: "Email",
    Password: "Kodeord",
    login: "Log ind",
    sign_in_as_guest: "Log ind som gæst",
    dont_have_an_account: "Har du ikke en konto?",
    sign_up: "Tilmeld dig",
    by_signing_in: "Ved at logge ind accepterer du vores betingelser",
    interested_in_becoming_a_goodnews_reporter:
      "Interesseret i at blive GoodNews-reporter?",
    goodnews_terms_information: "GoodNews Betingelser Information",
    username: "Brugernavn",
    confirm_password: "Bekræft kodeord",
    register: "Registrer",
    terms: "Betingelser",
    term1:
      "Nyhedsartikler offentliggjort af brugere bør have det mindste antal grammatikfejl muligt.",
    term2:
      "Brugere bør kun offentliggøre nyhedsartikler, såsom dem offentliggjort af nyhedsudbydere som BBC, der har en kilde, titel og indhold.",
    term3:
      "GoodNews App har ret til at moderere nyheder fra journalister, hvis de indeholder hadefuld tale, følsomt eller irrelevante indhold.",
    term4:
      "Dit nyhedsindlæg vises muligvis ikke automatisk, rækkefølgen af artikler er tilfældig.",
    term5: "Nøjagtigheden af nyhedspositivitet varierer.",
    term6:
      "Webstedet for posten skal begynde med ‘http://www…’ for at hyperlinks fungerer.",
    term7:
      "Brugere accepterer betingelserne (EULA), hvor der ikke er tolerance for upassende indhold eller misbrugende brugere. Goodnews vil handle på indberetninger af upassende indhold inden for 24 timer ved at fjerne indholdet og udelukke brugeren, der leverede det krænkende indhold.",
    term8: "Kun logget ind brugere, der har oprettet konti, kan offentliggøre.",
    term9:
      "Økonomi Feed og Søg tager lidt tid at indlæse. Test venligst i din gratis prøveperiode, før du køber.",
    term10: "Nogle artikler har ikke skift og vises i grafresultaterne.",
    big_wins: "STORE SEJRE",
    all_news_articles: "ALLE NYHEDER, ARTIKLER OG INSPIRERENDE HISTORIER",
    block: "Bloker",
    report: "Rapport",
    share: "Del",
    comment: "Kommentar",
    submit: "Indsend",
    like: "Synes godt om",
    likes: "synes godt om",
    Title: "Titel",
    Content: "Indhold",
    URL: "URL",
    Reporter: "Reporter",
    Source: "Kilde",
    enter_a_valid_value: "Angiv en gyldig værdi",
    create_an_account_to_create_post: "Opret en konto for at oprette et indlæg",
    post_added_successfully: "Indlæg tilføjet succesfuldt",
    there_was_an_error: "Der opstod en fejl",
    failed_to_translate: "Kunne ikke oversætte",
    show_more: "Vis mere",
    show_less: "Vis mindre",
    successful: "Succesfuldt!",
    unlike: "Synes ikke godt om",
    no_goodnews_available: "Ingen gode nyheder tilgængelige online lige nu",
    feedback: "Feedback",
    welcome_good_news_msg: `Lad os omfavne varmen fra positive nyheder, for det er ikke kun en
    flygtigt feel-good øjeblik; det er en katalysator for et mere
    medfølende, forbundet og håbefuld verden.`,
    welcome_good_news: "Velkommen til GoodNews",
    real_estate: "Ejendom",
    energy: "Energi",
    climate: "Klima",
    retail: "Detailhandel",
    small_business: "Lille virksomhed",
    healthcare: "Sundhedspleje",
    aerospace: "Rumfart",
    defense: "Forsvar",
    crypto: "Krypto",
    startup: "Startups",
  },
};

export const tabs = {
  economy: "Economy",
  good_news: "GoodNews",
  search: "Search",
  add_post: "Add Post",
  settings: "Settings",
};

export default translations;
