import { Outlet } from "react-router-dom";
import GoodNewsSelect from "basicomponents/GoodNewsSelect";

function GoodNewsOutlet() {
  return (
    <div>
      <div className="h-[50px] z-[2] fixed top-[80px] bg-white w-full sm:px-6 lg:px-8">
        <div className="max-w-screen-xl mx-auto flex justify-between items-center w-full py-1">
          <GoodNewsSelect className={"ml-auto mr-2 md:mr-0"} />
        </div>
      </div>
      {/* <Tabs tabs={tabs} activeTab={activeTab} onTabChange={onTabChange} /> */}
      <Outlet />
    </div>
  );
}

export default GoodNewsOutlet;
