import { useAuth } from "hooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import appRoutes from "utils/routing";

const UnProtectedOnlyPage = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate(appRoutes.communityposts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <div>{children}</div>;
};

export default UnProtectedOnlyPage;
